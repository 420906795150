<div class="headers">
  <h3 class="header-name">FHD Input Form</h3>
  <giq-bread-crumbs [crumbs]="crumbs"></giq-bread-crumbs>
</div>

<div class="main-container">
  <ngx-spinner [fullScreen]="false"></ngx-spinner>
  <div class="container">
    <div class="input-form-header-container">
      <div class="left-side-content input-control">
        <mat-form-field appearance="outline" class="filter-input">
          <mat-label>Week</mat-label>
          <mat-select [formControl]="weekFilter">
            <mat-option *ngFor="let week of weekFilterList" [value]="week">{{
              week
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="reportee-detail" *ngIf="userName !== loggedInUserName">
          <span class="reportee-title">User Name: </span
          >{{ selectedReportee?.displayName }}
          <button
            (click)="onChangeUser()"
            class="reportee-close"
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div>You have {{ getTotal2OutOf3() }} 2 out of 3 days this week</div>
      <div>
        <button
          mat-button
          *ngIf="reportees.length && userName === loggedInUserName"
          (click)="onViewReportees()"
        >
          View Team
        </button>
        <button mat-button (click)="onSubmit()">Save</button>
      </div>
    </div>
    <div class="input-form-container">
      <table class="input-form-table">
        <thead>
          <tr>
            <th class="blank-header"></th>
            <th colspan="2" *ngFor="let day of dayList">{{ day }}</th>
            <th colspan="2">Total</th>
          </tr>
          <tr class="secondary-header">
            <th class="blank-header"></th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Planned</th>
            <th>Actual</th>
          </tr>
        </thead>
        <tbody *ngIf="isReadyToLoad">
          <tr *ngFor="let taskDetail of taskList; let taskIndex = index">
            <th>{{ taskDetail.label }}</th>
            <ng-container *ngFor="let day of dayList; let i = index">
              <ng-container
                *ngIf="
                  taskDetail.key == 'start_time' ||
                    taskDetail.key == 'stop_time';
                  else showOtherColumn
                "
              >
                <td class="planned-column">
                  <select
                    [tabindex]="1 + taskIndex + 2 * i * taskList.length"
                    [ngClass]="{
                      'invalid-input':
                        dayTaskDetails[i].hours.planned === '00.00' ||
                        dayTaskDetails[i].hours.planned?.includes('-')
                    }"
                    (change)="
                      updateHours(i, 'planned'); updateMeetExpectation(i)
                    "
                    [(ngModel)]="dayTaskDetails[i][taskDetail.key].planned"
                  >
                    <option *ngFor="let week of timeSlots" [value]="week">
                      {{ week }}
                    </option>
                  </select>
                </td>
                <td class="actual-column">
                  <select
                    [tabindex]="1 + taskIndex + taskList.length * (2 * i + 1)"
                    [ngClass]="{
                      'invalid-input':
                        dayTaskDetails[i].hours.actual === '00.00' ||
                        dayTaskDetails[i].hours.actual?.includes('-')
                    }"
                    (change)="
                      updateHours(i, 'actual'); updateMeetExpectation(i)
                    "
                    [(ngModel)]="dayTaskDetails[i][taskDetail.key].actual"
                  >
                    <option *ngFor="let week of timeSlots" [value]="week">
                      {{ week }}
                    </option>
                  </select>
                </td>
              </ng-container>
              <ng-template #showOtherColumn>
                <ng-container [ngSwitch]="taskDetail.key">
                  <ng-container *ngSwitchCase="'hours'">
                    <td class="planned-column">
                      <span
                        *ngIf="
                          dayTaskDetails[i][taskDetail.key].planned !=
                            '00.00' &&
                          !dayTaskDetails[i][taskDetail.key].planned?.includes(
                            '-'
                          )
                        "
                        >{{ dayTaskDetails[i][taskDetail.key].planned }}</span
                      >
                    </td>
                    <td class="actual-column">
                      <span
                        *ngIf="
                          dayTaskDetails[i][taskDetail.key].actual != '00.00' &&
                          !dayTaskDetails[i][taskDetail.key].actual?.includes(
                            '-'
                          )
                        "
                        >{{ dayTaskDetails[i][taskDetail.key].actual }}</span
                      >
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchCase="'two_out_of_three'">
                    <td class="planned-column">
                      <span
                        [ngClass]="
                          dayTaskDetails[i].two_out_of_three.planned
                            ? 'meets-success'
                            : 'meets-failure'
                        "
                      >
                        {{
                          dayTaskDetails[i].two_out_of_three.planned
                            ? "Yes"
                            : "No"
                        }}
                      </span>
                    </td>
                    <td class="actual-column">
                      <span
                        [ngClass]="
                          dayTaskDetails[i].two_out_of_three.actual
                            ? 'meets-success'
                            : 'meets-failure'
                        "
                      >
                        {{
                          dayTaskDetails[i].two_out_of_three.actual
                            ? "Yes"
                            : "No"
                        }}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchCase="'total_gap'">
                    <td class="planned-column">
                      <span>
                        {{ dayTaskDetails[i].total_gap.planned }}
                      </span>
                    </td>
                    <td class="actual-column">
                      <span>
                        {{ dayTaskDetails[i].total_gap.actual }}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchCase="'gap'">
                    <td class="planned-column">
                      <input
                        [tabindex]="1 + taskIndex + 2 * i * taskList.length"
                        type="number"
                        min="1"
                        class="stats-input"
                        (change)="
                          updateMeetExpectation(i);
                          updateGAPTotal(taskDetail.key, i, 'planned')
                        "
                        [(ngModel)]="dayTaskDetails[i][taskDetail.key].planned"
                      />
                    </td>
                    <td class="actual-column">
                      <input
                        [tabindex]="
                          1 + taskIndex + taskList.length * (2 * i + 1)
                        "
                        type="number"
                        min="1"
                        class="stats-input"
                        (change)="
                          updateMeetExpectation(i);
                          updateGAPTotal(taskDetail.key, i, 'actual')
                        "
                        [(ngModel)]="dayTaskDetails[i][taskDetail.key].actual"
                      />
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchCase="'rgap'">
                    <td class="planned-column">
                      <input
                        [tabindex]="1 + taskIndex + 2 * i * taskList.length"
                        type="number"
                        min="1"
                        class="stats-input"
                        (change)="
                          updateMeetExpectation(i);
                          updateGAPTotal(taskDetail.key, i, 'planned')
                        "
                        [(ngModel)]="dayTaskDetails[i][taskDetail.key].planned"
                      />
                    </td>
                    <td class="actual-column">
                      <input
                        [tabindex]="
                          1 + taskIndex + taskList.length * (2 * i + 1)
                        "
                        type="number"
                        min="1"
                        class="stats-input"
                        (change)="
                          updateMeetExpectation(i);
                          updateGAPTotal(taskDetail.key, i, 'actual')
                        "
                        [(ngModel)]="dayTaskDetails[i][taskDetail.key].actual"
                      />
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <td class="planned-column">
                      <input
                        [tabindex]="1 + taskIndex + 2 * i * taskList.length"
                        type="number"
                        min="1"
                        class="stats-input"
                        giqWholeNumberOnly
                        (change)="
                          updateMeetExpectation(i);
                          updateGAPTotal(taskDetail.key, i, 'planned')
                        "
                        [(ngModel)]="dayTaskDetails[i][taskDetail.key].planned"
                      />
                    </td>
                    <td class="actual-column">
                      <input
                        [tabindex]="
                          1 + taskIndex + taskList.length * (2 * i + 1)
                        "
                        type="number"
                        min="1"
                        class="stats-input"
                        giqWholeNumberOnly
                        (change)="
                          updateMeetExpectation(i);
                          updateGAPTotal(taskDetail.key, i, 'actual')
                        "
                        [(ngModel)]="dayTaskDetails[i][taskDetail.key].actual"
                      />
                    </td>
                  </ng-container>
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-container
              *ngIf="
                taskDetail.key != 'start_time' && taskDetail.key != 'stop_time'
              "
            >
              <td class="total-column">
                {{ getTotal(taskDetail.key, "planned") }}
              </td>
              <td class="total-column">
                {{ getTotal(taskDetail.key, "actual") }}
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #viewReporteesDialog>
  <div class="reportee-dialog">
    <h1 mat-dialog-title>Team Stats Entry Status</h1>
    <div class="dialog-body-container" mat-dialog-content>
      <div class="dialog-left-side-content input-control">
        <mat-form-field appearance="outline" class="filter-input">
          <mat-label>Filter</mat-label>
          <input matInput [formControl]="searchControl" autocomplete="off" />
        </mat-form-field>
        <span
          class="reportee-item"
          *ngFor="let reportee of reportees"
          [ngClass]="
            reportee.isTimesheetSubmitted ? 'meets-success' : 'meets-failure'
          "
          (click)="onReporteeSelect(reportee)"
          >{{ reportee.displayName }}</span
        >
      </div>
      <div class="dialog-right-side-content" *ngIf="showRightSideContent">
        <button mat-raised-button [disabled]="selectedReportee?.userName === userInfoUserName" (click)="onChangeUser(selectedReportee)">
          Login as {{ selectedReportee?.displayName }}
        </button>

        <section class="optional-container">
          <h4>Optional Categories:</h4>
          <p>
            <mat-checkbox
              [checked]="isAllCategoriesChecked"
              (change)="selectAllCategories($event)"
              >Select All</mat-checkbox
            >
          </p>
          <p *ngFor="let taskItem of defaultOptionalTaskResponse">
            <mat-checkbox
              [checked]="
                isAllCategoriesChecked || reporteesOptionalTasks[taskItem.key]
              "
              (change)="onOptionalCategoryChange($event, taskItem)"
              >{{ taskItem.label }}</mat-checkbox
            >
          </p>
        </section>
      </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button mat-button (click)="onCloseDialog()">Cancel</button>
      <button
        mat-button
        (click)="saveOptionalTasks()"
        *ngIf="showRightSideContent"
      >
        Save
      </button>
    </div>
  </div>
</ng-template>
