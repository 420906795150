import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'giq-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  public home = '/' + environment.defaultRoute;
}
