import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

    transform(value: string, limit: number, canTruncate: boolean = true): string {
        if (canTruncate) {
            limit = isNaN(limit) ? 35 : limit;
            return value.length > limit ? value.substring(0, limit) + '...' : value;
        }
        else {
            return value;
        }
    }
}