<div class="user_container" (click)="openProfile()">
  <ng-container *ngIf="userInfo$ | async as userInfo; else loadingUser">
    <span class="name">Hello, {{ userInfo.name }}</span>
    <span class="user_image">{{ initials }}</span>
  </ng-container>
</div>

<ng-template #loadingUser>
  Loading... <span class="name"></span>
  <span class="user_image"
    ><giq-loading class="loading-icon"></giq-loading
  ></span>
</ng-template>
