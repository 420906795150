import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  TopMenuConfig,
  TopMenuItem,
  TopMenuService,
} from 'src/app/core/top-menu';
import { NotificationsService } from './notifications.service';
import { INotification } from './notifications.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'giq-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [TopMenuService, NotificationsService],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private itemsEmitter: BehaviorSubject<TopMenuItem[] | undefined> =
    new BehaviorSubject<TopMenuItem[] | undefined>(undefined);

  private menuConfig: TopMenuConfig = {
    title: 'Alerts',
    items: this.itemsEmitter.asObservable(),
    panelClass: 'notifications-panel',
    showMoreFunction: () => alert('Showing more'),
    showMore: false,
    limitItems: 100,
    noElements: 'No alerts',
    onMenuClose: () => {
      this.onMenuClose();
    },
    showRead: true
  };

  notificationCount: number = 0;
  notifications: any[] = [];
  subs: Subscription[] = [];
  loading: boolean = false;
  clickedIds: string[] = [];

  constructor(
    private topMenuService: TopMenuService,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
    private notificationsService: NotificationsService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnDestroy(): void {
    this.itemsEmitter.complete();
  }

  ngOnInit(): void {
    this.loading = true;
    const sub = this.notificationsService
      .getNotifications()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res) => {
        this.notificationCount = res.filter(
          (notification) => !notification.read
        ).length;
        this.notifications = res;
      });
    this.subs.push(sub);

    this.topMenuService.configureService(this.viewContainerRef);
  }

  public openNotifications() {
    this.loading = true;

    const sub = this.notificationsService
      .getNotifications()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res) => {
        const items = res.map((notification) => {
          let svgIcon = 'assignment';
          if (
            notification.alertType === 'IMPORTANT' ||
            notification.alertType === 'WARNING'
          ) {
            svgIcon = 'megaphone';
          }
          return {
            ...notification,
            svgIcon,
            content: notification.message,
            title: notification.title,
            onClick: (notification: INotification) => {
              if (!this.clickedIds.includes(notification.id) && !notification.read) {
                this.clickedIds.push(notification.id);
              }
            },
          };
        });
        this.itemsEmitter.next(items);
        let overlay = this.topMenuService.openMenu(
          this.menuConfig,
          this.elementRef
        );
      });
    this.subs.push(sub);
  }

  public onMenuClose() {
    if (!this.clickedIds.length) {
      return;
    }

    this.loading = true;
    const sub = this.notificationsService
      .markAsRead(this.clickedIds)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res) => {
        if (this.notificationCount > 0) {
          this.notificationCount = this.notificationCount - this.clickedIds.length;
        }
        this.clickedIds = [];
      });
    this.subs.push(sub);
  }
}
