export interface VerticalBarMetadata {
    subtitle: string;
    data: VerticalBarHighlightElement[];
    timeScale: TimeScale;
    division:string;
  }
  
  export interface VerticalBarHighlightElement {
    date: string;
    amount: number;
  }
  

export enum TimeScale {
    Yearly = 'Yearly',
    Quarterly = 'Quarterly',
    Monthly = 'Monthly',
    Weekly = 'Weekly',
    Text = 'Text'
  }
  