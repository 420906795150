import { OverlayRef } from '@angular/cdk/overlay';
import { ElementRef, TemplateRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

export interface TopMenuItem {
  content: string;
  onClick?: Function;
  svgIcon?: string;
  numberIcon?: string;
  importance?: 'info' | 'warning' | 'important' | undefined;
  icon?: string;
  title?: string;
  read?: boolean;
}

export interface TopMenuConfig {
  title?: string;
  items: TopMenuItem[] | Observable<TopMenuItem[] | undefined>;
  panelClass: string;
  onMenuClose?: Function;
  useTemplate?: TemplateRef<unknown>;
  parentElementRef?: ElementRef;
  limitItems?: number;
  showMore?: boolean;
  showMoreFunction?: Function;
  itemClass?: string;
  closeOnItemClick?: boolean;
  noElements?: string;
  showRead?: boolean;
}

export class TopMenuOverlay {
  constructor(
    private overlaySubscription: Subscription,
    private overlayRef: OverlayRef | null
  ) {}

  public hide() {
    this.overlaySubscription.unsubscribe();
    this.overlayRef?.dispose();
    this.overlayRef = null;
  }
}
