<div class="to-do-wrapper">
  <mat-icon
    class="material-icons-outlined"
    giq-interaction
    [matBadgeHidden]="todosCount > 0 ? false : true"
    [matBadge]="todosCount"
    matBadgeOverlap="true"
    svgIcon="todo"
    (click)="openTodos()"
  ></mat-icon>

  <div class="spinnerContainer" *ngIf="loading">
    <div id="loading"></div>
  </div>
</div>
