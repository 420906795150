import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pascalCase',
})
export class PascalCasePipe implements PipeTransform {
  private toPascalCase = (str: any) =>
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b|_)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x: string) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
      .join(' ');
  transform(value: string | undefined, ...args: unknown[]): string | undefined {
    if (!value) {
      return value;
    }
    return this.toPascalCase(value);
  }
}
