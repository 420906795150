import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ReportsDetailsService } from '../reports-details.service';

@Directive({ selector: '[giqShowReportDetails]' })
export class ShowReportDetailsDirective {
    @Input() giqShowReportDetails?: string;

    constructor(private elementRef: ElementRef, private reportDetailsService: ReportsDetailsService) { }

    @HostListener('click')
    public show(): void {
        this.reportDetailsService.showReportDetailsFor(this.giqShowReportDetails, this.elementRef);
    }
}