import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ElementRef,
  Injectable,
  InjectionToken,
  Injector,
  StaticProvider,
  ViewContainerRef,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { TopMenuConfig, TopMenuOverlay } from './top-menu.models';
import { TopOverlayComponent } from './top-overlay/top-overlay.component';

export const TOP_MENU_CONFIG: InjectionToken<TopMenuConfig> =
  new InjectionToken<TopMenuConfig>('TOP_MENU_CONFIG');
export const TOP_MENU_OVERLAY_REF: InjectionToken<OverlayRef> =
  new InjectionToken<OverlayRef>('TOP_MENU_OVERLAY_REF');

@Injectable()
export class TopMenuService {
  private viewContainerRef: ViewContainerRef | undefined;

  private defaultConfig: Partial<TopMenuConfig> = {
    showMore: true,
    limitItems: 5,
    useTemplate: undefined,
  };

  constructor(private injector: Injector, private overlay: Overlay) {}

  public configureService(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  public openMenu(
    configuration: TopMenuConfig,
    elementRef?: ElementRef
  ): TopMenuOverlay | null {
    const config = this.setDefaults(configuration, elementRef);
    if (config == null || config.parentElementRef == null) {
      console.error('The service has not been configured correctly');
      return null;
    }

    const overlayConfiguration = this.getOverlayConfiguration(config);
    let overlayRef: OverlayRef | null =
      this.overlay.create(overlayConfiguration);
    const overlayInjector = this.appendParamsToInjector(config, overlayRef);
    overlayRef.attach(
      new ComponentPortal(
        TopOverlayComponent,
        this.viewContainerRef,
        overlayInjector
      )
    );
    const overlaySubscription = overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe({
        next: () => {
          if (typeof config.onMenuClose === 'function') {
            config.onMenuClose();
          }
          overlaySubscription.unsubscribe();
          overlayRef?.dispose();
          overlayRef = null;
        },
      });
    let result: TopMenuOverlay = new TopMenuOverlay(
      overlaySubscription,
      overlayRef
    );
    return result;
  }

  private appendParamsToInjector(
    config: TopMenuConfig,
    overlayRef: OverlayRef
  ): Injector {
    const options: { providers: StaticProvider[]; parent?: Injector } = {
      parent: this.injector,
      providers: [
        { provide: TOP_MENU_CONFIG, useValue: config },
        { provide: TOP_MENU_OVERLAY_REF, useValue: overlayRef },
      ],
    };
    const portal = Injector.create(options);
    return portal;
  }

  private getOverlayConfiguration(config: TopMenuConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(config.parentElementRef?.nativeElement)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);
    const overlayConf: OverlayConfig = {
      hasBackdrop: true,
      disposeOnNavigation: true,
      backdropClass: 'top-menu-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy,
    };

    return overlayConf;
  }

  private setDefaults(
    configuration: TopMenuConfig,
    elementRef?: ElementRef
  ): TopMenuConfig {
    const result = {
      ...this.defaultConfig,
      ...configuration,
    };

    result.parentElementRef = elementRef ?? undefined;

    return result;
  }
}
