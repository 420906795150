import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { escapeHtml } from './escape-html/escape-html';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, fragment?: string): string | SafeHtml {
    if (!fragment) {
      return value;
    } else {
      value = escapeHtml(value);
      const result = this.findAndHighlight(value, fragment);
      return result;
    }
  }

  private findAndHighlight(value: string, fragment: string): SafeHtml {
    const lowerFragment = fragment.toLowerCase();
    const index = value.toLocaleLowerCase().indexOf(lowerFragment);
    if (index >= 0) {
      const totalLenght = fragment.length;
      const newValue =
        value.substring(0, index) +
        '<i class="highlight">' +
        value.substring(index, index + totalLenght) +
        '</i>' +
        value.substring(index + totalLenght, value.length);
      return this.sanitizer.bypassSecurityTrustHtml(newValue);
    } else {
      return value;
    }
  }
}
