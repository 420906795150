export interface LineHighlightMetadata {
    data: LineHighlightElement[];
    colors: string[];
    timeScale: TimeScale;
    division: string;
    display:Display;
    dotsColors: string[];
    showAmount: string;
    showDots: string;
  }
  
  export interface LineHighlightElement {
    key: string;
    date: Date;
    yValue: number;
 }

  export enum TimeScale {
    Monthly = 'Monthly',
    Yearly = 'Yearly',
    Weekly = 'Weekly',
    YrQtr = 'YrQtr',
    MonthNumbers = 'MonthNumbers'
  }

  export enum Display {
    Amount = 'Amount',
    MaxAmount = 'MaxAmount',
    Percentage = 'Percentage',
    MaxPercentage = 'MaxPercentage',
    None='None'
  }
  
