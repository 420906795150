import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/core/alerts/alerts.service';
import { ReportProblemService } from '../report-problem.service';
import { ProblemData } from './problem-data';

@Component({
  templateUrl: 'problem-window.component.html',
  styleUrls: ['problem-window.component.scss'],
})
export class ProblemWindowComponent implements OnInit {
  public comments: FormControl = new FormControl('', Validators.required);
  public saving: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ProblemWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProblemData,
    private problemsService: ReportProblemService,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  public save(): void {
    this.saving = true;
    this.problemsService
      .sendReport({
        comments: this.comments.value,
        description: this.data.description,
        id: this.data.id,
        type: this.data.type,
      })
      .subscribe({
        next: (_) => {
          this.saving = false;
          this.alertService.success('Report has been sent');
          this.dialogRef.close();
        },
        error: (_) => {
          this.saving = false;
          this.alertService.error(
            'Error saving the report. Please try again later.'
          );
        },
      });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
