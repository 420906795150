import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as orgChart from 'd3-org-chart';
import { IFHDHierarchyItem } from '../model/fhd-hierarchy';

import * as d3 from 'd3';
import { AlertService } from 'src/app/core/alerts/alerts.service';
import { FhdHierarchyService } from '../service/fhd-hierarchy.service';
import { AgentSelectorComponent } from '../agent-selector/agent-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { uuid } from 'src/app/core/uuid/uuid';

@Component({
  selector: 'giq-org-chart',
  templateUrl: 'org-chart.component.html',
  styleUrls: ['org-chart.component.scss'],
})
export class OrgChartComponent {
  @ViewChild('chartContainer') chartContainer: ElementRef | null = null;
  @Input() data: IFHDHierarchyItem[] | null = null;
  @Input() agency: IFHDHierarchyItem | null = null;
  @Input() agencyUsers: IFHDHierarchyItem[] | null = null;
  @Input() canModify: boolean = false;
  @Output() onModifying: EventEmitter<IFHDHierarchyItem[] | null> =
    new EventEmitter();

  public chart!: orgChart.OrgChart<IFHDHierarchyItem>;
  public searchValue: string | null = null;
  public position: 'left' | 'bottom' | 'right' | 'top' = 'top';

  constructor(
    private alertService: AlertService,
    private service: FhdHierarchyService,
    private dialog: MatDialog
  ) {}

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new orgChart.OrgChart<IFHDHierarchyItem>();
    }
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['agency'] && this.agency != null) {
    }
    if (changes['data']) {
      this.updateChart();
    }
  }

  public onSearch(e: any) {
    e.preventDefault();
    this.chart?.clearHighlighting();
    if (this.searchValue != null && this.searchValue != '') {
      let chartData = this.chart?.data();
      let elements: any[] = [];
      chartData?.forEach((x: any) => {
        x._expanded = false;
        if (
          x.agentNumber == this.searchValue ||
          x.agentName.toLowerCase().includes(this.searchValue!)
        ) {
          elements.push(x);
          x._expanded = true;
          x._highlighted = true;
        }
      });
      if (elements == null || elements.length === 0) {
        this.alertService.warning('Agent not found in the hierarchy');
      } else if (elements.length === 1) {
        this.chart?.setUpToTheRootHighlighted(elements[0].id).render().fit();
      } else {
        this.chart.data(chartData).render().fit();
      }
    } else {
      this.alertService.warning('Please enter a valid agent number');
    }
  }

  private updateChart() {
    this.searchValue = null;
    if (!this.data) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.chart
      .container(this.chartContainer?.nativeElement)
      .nodeId(
        (dataItem: IFHDHierarchyItem | d3.HierarchyNode<IFHDHierarchyItem>) =>
          dataItem.id
      )
      .parentNodeId((dataItem: any) => dataItem.parentId)
      .nodeWidth(() => 200)
      .nodeHeight((node: any) => {
        if (node.data.agentName.length > 24) {
          return 100;
        } else {
          return 80;
        }
      })
      .childrenMargin(() => 50)
      .compactMarginBetween(() => 35)
      .compactMarginPair(() => 30)
      .compact(false)
      .neighbourMargin(() => 20)
      .nodeContent((node: any) => {
        const color = node.data.position === 'agent' ? 'red' : 'green';
        const border = node.data.position === 'agent' ? 'dashed' : 'solid';
        return `
              <div style="font-family: 'Inter', sans-serif;background-color:#FFFFFF;  margin-left:-1px;border-radius:10px;border: 1px ${border} ${color};height: 100%;">
                <div style="display:flex;justify-content:flex-end;margin-top:5px;margin-right:8px">#${
                  node.data.agentNumber
                }</div>
                <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:10px">  ${
                  node.data.agentName
                } </div>
                <div style="color:black;margin-left:20px;margin-top:3px;font-size:10px;"> ${
                  node.data.position
                } </div>
                <div style="display:flex;gap:1rem;justify-content:flex-end;margin-right: 8px;${
                  this.canModify ? '' : 'display:none;'
                }"> 
                <span class="add-child" style="cursor:pointer">Add</span> 
                ${
                  node.data.position == 'agency'
                    ? ''
                    : '<span class="delete" style="cursor:pointer">Delete</span>'
                }
                </div>
              </div>`;
      })
      .nodeUpdate((nodeObj: any, i: any, n: any) => {
        if (this.canModify) {
          d3.select(n[i])
            .select('.add-child')
            .on('click', () =>
              this.addChild(nodeObj, nodeObj?.parent?.data, nodeObj.depth)
            );

          d3.select(n[i])
            .select('.delete')
            .on('click', () =>
              this.deleteNode(
                nodeObj.data,
                nodeObj?.parent,
                nodeObj._hierarchyHeight
              )
            );
        }
      })
      .data(this.data);
    const chartContainer: any = d3?.select('#chartContainer')?.node();
    this.chart.svgHeight(chartContainer.getBoundingClientRect().height);
    this.chart.svgWidth(100);
    this.chart.render();
  }

  public addChild(
    current: { data: IFHDHierarchyItem; [key: string]: any },
    parent: IFHDHierarchyItem | null,
    depth: number
  ) {
    // get agents that are not in the hierarchy
    const assignedAgents: string[] | undefined = this.chart
      .data()
      ?.map((x: IFHDHierarchyItem) => x.agentNumber);
    const unnasignedAgents = this.agencyUsers?.filter(
      (x: IFHDHierarchyItem) => !assignedAgents?.includes(x.agentNumber)
    );

    const dialogRef = this.dialog.open(AgentSelectorComponent, {
      disableClose: true,
      data: {
        title: 'Add agent to ' + current.data.agentName,
        agents: unnasignedAgents,
      },
    });

    dialogRef.afterClosed().subscribe((agents: IFHDHierarchyItem[]) => {
      if (agents != null && agents.length > 0) {
        if (depth != 0) {
          current.data.position = 'ab' + depth;
          current.data._modified = true;
        }
        let addedNodes = false;
        const middle = Math.ceil(agents.length / 2);
        agents.forEach((element, index) => {
          const item: IFHDHierarchyItem = {
            ...element,
            agentName: element.agentName,
            parent: current.data.agentNumber,
            _justAdded: true,
            _modified: true,
            id: uuid(),
            agencyId: current.data.agencyId,
            agencyOwnerName: current.data.agencyOwnerName,
            agencyOwnerAgentNumber: current.data.agencyOwnerAgentNumber,
            parentId: current.data.id,
            position: 'agent',
          };
          addedNodes = true;
          const node = { ...item, _centered: middle === index + 1 };
          this.chart?.addNode(node);
        });
        if (addedNodes) {
          setTimeout(() => {
            this.onModifying.emit(this.chart.data());
          }, 200);
        }
      }
    });
  }

  public deleteNode(
    current: IFHDHierarchyItem,
    parent: { data: IFHDHierarchyItem; [key: string]: any } | null,
    hierarchyHeight?: number
  ) {
    let message =
      'Are you sure you want to delete ' +
      current.agentName +
      ' from the Hierarchy?';
    if (hierarchyHeight != null && hierarchyHeight > 0) {
      message += ' \n\r This will also delete the children under this node';
    }
    this.alertService
      .confirmDialog('Delete hierarchy node', message)
      .subscribe((result: boolean) => {
        if (result === true) {
          this.chart?.removeNode(current.id);
          if (
            parent != null &&
            parent['children'].length === 1 &&
            parent.data.position !== 'agency'
          ) {
            parent.data.position = 'agent';
            this.chart?.render();
          }

          if (current._justAdded !== true) {
          }
          setTimeout(() => {
            this.onModifying.emit(this.chart.data());
          }, 200);
        }
      });
  }

  public changeLayout() {
    this.chart.layout(this.position).render().fit();
  }
}
