import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  ADMIN,
  AGENT_AUTOMATION,
  AGENT_AUTOMATION_MODIFY,
  AGENT_AUTOMATION_SAVE,
  BANK_COMPLIANCE,
  CHATBOT_CUSTOMESERVICE,
  CHATBOT_DOCUMENT,
  CHATS,
  CLAIM_AUDIT_LOGS,
  CUSTOMER_CARE,
  EXTRACTS,
  PRODUCTION_TASK,
  PRODUCTION_TASK_CREATE,
  PRODUCTION_TASK_MODIFY,
  PRODUCTION_TASK_SUPERVISOR,
  RECORD_CATALOG,
  SALESBOARD,
  UNDERWRITING,
  WORKSITE_TRACKER,
  WORKSITE_TRACKER_VOID,
} from 'src/app/constants/permissions';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../state/user/user-info';
import {
  loggingOut,
  setUserAuthenticated,
  setUserInfo,
} from '../state/user/user.store';
import { IAuthService } from './iauth.service';

@Injectable()
export class LocalAuthService implements IAuthService {
  constructor(private router: Router) {}
  isTokenExpired(): boolean {
    return false;
  }

  setOAuthConfiguration(company: string): void {}

  login(state: string): void {
    alert('loging in');
    this.router.navigateByUrl('/login/callback');
  }

  checkSession(): boolean {
    setUserAuthenticated(true);
    return true;
  }

  getToken(): Observable<{ state: string | undefined; validToken: boolean }> {
    return of({
      state: environment.defaultRoute,
      validToken: true,
    });
  }

  public refresh(): void {
    console.log('refreshing' + new Date());
  }

  getUserInfo(): Observable<UserInfo> {
    const info: UserInfo = {
      name: 'Benjamin',
      lastName: 'Sandoval',
      email: 'devtest@test.com',
      userName: 'devtest',
      frontActions: [
        CUSTOMER_CARE,
        AGENT_AUTOMATION,
        AGENT_AUTOMATION_SAVE,
        AGENT_AUTOMATION_MODIFY,
        WORKSITE_TRACKER,
        EXTRACTS,
        RECORD_CATALOG,
        WORKSITE_TRACKER_VOID,
        BANK_COMPLIANCE,
        CLAIM_AUDIT_LOGS,
        PRODUCTION_TASK_MODIFY,
        PRODUCTION_TASK,
        PRODUCTION_TASK_CREATE,
        PRODUCTION_TASK_SUPERVISOR,
        SALESBOARD,
        UNDERWRITING,
        CHATS,
        CHATBOT_CUSTOMESERVICE,
        ADMIN,
        CHATBOT_DOCUMENT,
        'chatbot_customerservice_GLOBE',
        'chatbot_customerservice_AIL',
        'chatbot_customerservice_LND',
        'chatbot_customerservice_UA',
        'chatbot_customerservice_MILITARY',
      ],
    };
    setUserInfo(info);
    return of<UserInfo>(info);
  }

  public getSchema(): string {
    return 'Bearer';
  }

  getAccessToken(): string {
    return 'eyJhbGciOiJSUzI1NiIsIng1dCI6IkxqN3pIYTAxSnYwZ2VxR3dEYUd4TkdzVmJfOCIsImtpZCI6IkxqN3pIYTAxSnYwZ2VxR3dEYUd4TkdzVmJfOCIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMzQyNzhiNTIxZTQ0NDM3YWY4Y2E2NWRhMTQxMDVhNyIsImNsaWVudF9pZCI6Ijk0NTM2NmMyMDJkMTQ5MmJiMTM4ODY1OGFlMjk0ZTZiIiwic2NvcGUiOlsiYWRkcmVzcyIsImVtYWlsIiwib2F1dGgiLCJvZmZsaW5lX2FjY2VzcyIsIm9wZW5pZCIsInBob25lIiwicHJvZmlsZSJdLCJzdWIiOiJieHNhbmRvdmFsIiwiYXV0aF90aW1lIjoxNzI4NTk3MTIzLCJpZHAiOiJTZWN1cmVBdXRoNzUiLCJpYXQiOjE3Mjg1OTcxMjgsImFtciI6WyJwYXNzd29yZCJdLCJuYmYiOjE3Mjg1OTcxMjgsImV4cCI6MTcyODYwMDcyOCwiaXNzIjoiaHR0cHM6Ly9kZXZzc28uZ2xvYmVsaWZlaW5jLmNvbS9TZWN1cmVBdXRoNzUiLCJhdWQiOiJodHRwczovL2RldnNzby5nbG9iZWxpZmVpbmMuY29tL1NlY3VyZUF1dGg3NSJ9.ZW1ZhGkWSczlAxNs9bEqFmb7zSnovPenk8KxVbXs6E3m5xKkU2uyiq1206osK-zKt10fw0fKf2y5qbMLT1-UxWb5OiMtzwQRxhyyKM5k2nor7MLuV1gsMp9jhPWIvIZ6AwPyt-WR8ts6wb7c2SVcPJSVacYDXfn-4JYtMeE_BCxKuVi_aHeUbs1F3MPIGvcsurfO4kxUfTvl-nBOGo1w2cQtMZoK0SW98IXI8TPS1vXemB2MVcdfr7JzZS4MQaxs0gQKR924vmFdLJ5RAUBGGHtzjjPeli4Cl8IJ4dytviRIXWiyzQRTi62jaMaJmhz_WYLhT-POZfvhtqCQNPqKVg';
  }

  logout(): void {
    loggingOut();
    setUserAuthenticated(false);
    this.router.navigateByUrl('/logout');
  }
}
