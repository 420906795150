<div class="highlight" *ngIf="config">
  <div class="chart">
    <div class="indicators">
      <span class="amount">
        <span>{{ selectedElement?.amount }}</span>
      </span>
      <span class="concept">{{ selectedElement?.concept }}</span>
    </div>
    <div class="country-map" [id]="id"></div>
  </div>
</div>