<div
  class="stock-container"
  [matTooltip]="stock.updated"
  matTooltipPosition="below"
  matTooltipClass="blue-tooltip"
>
  <span class="price">GL ${{ stock.value }}</span>
  <span class="variation" [class.negative]="negative"
    >{{ stock.change }} {{ stock.percentage }}</span
  >
</div>
