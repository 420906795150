import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { BehaviorSubject, forkJoin, timer } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { setUserAuthenticated } from 'src/app/core/state/user/user.store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'giq-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  public error$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public ok$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorMessage = '';

  constructor(private authService: AuthService, private router: Router) {
    const company = localStorage.getItem('Comp');
    if (company != null) {
      this.authService.setOAuthConfiguration(company);
    }
  }

  ngOnInit(): void {
    forkJoin([timer(7000), this.authService.getToken()]).subscribe({
      next: ([_, x]) => {
        setUserAuthenticated(x.validToken);
        if (x.validToken == true) {
          this.router.navigate(
            [decodeURIComponent(x.state ?? environment.defaultRoute)],
            {
              replaceUrl: true,
            }
          );
        } else {
          this.error$.next(true);
          this.errorMessage = 'Authentication error. Invalid token.';
        }
      },
      error: (err) => {
        this.error$.next(true);
        this.errorMessage = 'Error authenticating the user';
      },
    });
  }

  options: AnimationOptions = {
    path: '/assets/intro.json',
  };
}
