import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'giq-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {
  public showHighlights = !environment.fuera;
}
