<div class="highlight" *ngIf="config">
  <div class="headers">
    <div class="header1">{{ metadata.label }}</div>
    <div class="header2">Guidance</div>
  </div>
  <div class="chart">
    <div class="element" *ngFor="let item of metadata.elements">
      <div class="concept">{{ item.description }}</div>
      <div class="chartAmount" [id]="item.id"></div>
      <div class="amount">{{ item.amount }}</div>
    </div>
  </div>
</div>