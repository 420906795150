<ng-container *ngIf="categories$ | async as categories; else loadingOrError">
  <h3>My Workspace</h3>

  <ng-container *ngIf="(noReports$ | async) === false">
    <div class="workspace-content">
      <div
        *ngFor="let category of categories"
        class="category"
        [style.--color]="category.color"
      >
        <giq-workspace-category
          [title]="category.name"
          [categoryId]="category.id"
        ></giq-workspace-category>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(noReports$ | async) === true">
    <div class="noreports-content">
      <img class="noreports-icon" src="assets/GlobeIQ_Icon.svg" />
      <span class="noreports-text">
        You dont have access to any reports yet.<br />
        <a [routerLink]="['/support']">Go to support.</a>
      </span>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingOrError>
  <div class="loading-content" [class.error]="error$ | async">
    <ng-container *ngIf="error$ | async; else loading">
      <span>{{ errorMessage }}</span>
    </ng-container>
    <ng-template #loading>
      <giq-loading class="loading-icon"></giq-loading>
      <span class="loading-text">Leading with Insights</span>
    </ng-template>
  </div>
</ng-template>
