export const FIELDS = [
    {
        key: 'currentAgency',
        name: 'Current Agency',
        description: 'Main agency number the adjustment will be applied to',
        inputType: 'number'
    },
    {
        key: 'previousAgency',
        name: 'Previous Agency',
        description: 'If applied - Agency whose production will be combined with the "current agency',
        inputType: 'number'
    },
    {
        key: 'agencyOwnerNumber',
        name: 'AO/Partnership Number',
        description: 'Agency Owner Number',
        inputType: 'number'
    },
    {
        key: 'startDate',
        name: 'Start Date',
        description: 'Adjustment start date',
        inputType: 'date'
    },
    {
        key: 'endDate',
        name: 'End Date',
        description: 'Adjustment end date',
        inputType: 'date'
    },
    {
        key: 'weeklyNetAdjustment',
        name: 'Weekly Net Submit Adjustment Amount',
        description: 'If applied - Production adjustment amount',
        inputType: 'text'
    },
    {
        key: 'weeklyNetFYAAdjustment',
        name: 'Weekly Net Submit FYA Adjustment Amount',
        description: 'If applied - Production adjustment amount',
        inputType: 'text'
    },
    {
        key: 'numberofWeeks',
        name: 'Number of Weeks',
        description: 'Number of reporting weeks',
        inputType: 'number'
    },
    {
        key: 'priorToAssignDate',
        name: 'Keep Production Prior to Assign Date',
        description: 'Yes - the Current agency will keep all production from the Previous agency',
        inputType: 'text'
    },
    {
        key: 'addOrReplace',
        name: 'Add or Replace',
        description: 'Add or Replace',
        inputType: 'dropdown',
        dropDownValues: [{ key: 'add', value: 'Add' }, { key: 'replace', value: 'Replace' }]
    }
]