import { Component, Input } from '@angular/core';
import { Crumb } from './crumb';

@Component({
    selector: 'giq-bread-crumbs',
    templateUrl: 'bread-crumbs.component.html',
    styleUrls: ['bread-crumbs.component.scss']
})

export class BreadCrumbsComponent {
    @Input() crumbs: Crumb[] = [];
    constructor() { }
}