<div class="chart card">
  <div class="chart-wrapper">
    <div class="chart-container" [id]="id">
      <div class="loading" *ngIf="loadingChart">
        <ng-container *ngIf="errorMessageChart == null">
          <giq-small-load></giq-small-load>
          <div>Loading data. . .</div>
        </ng-container>
        <ng-container *ngIf="errorMessageChart != null">
          <mat-icon>error</mat-icon>
          <div>{{ errorMessageChart }}</div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="labels" *ngIf="hideIndicators">
    <div
      class="label"
      [style.--indicator]="color(key)"
      *ngFor="let key of keys"
      [matTooltip]="names[key]"
      (mouseenter)="highlight(key)"
      (mouseleave)="unhighlight()"
    ></div>
  </div>
  <div class="labels" *ngIf="!hideIndicators">
    <div
      class="label"
      [style.--indicator]="color(key)"
      *ngFor="let key of keys"
      (mouseenter)="highlight(key)"
      (mouseleave)="unhighlight()"
    >
      {{ names[key] }}
    </div>
  </div>
</div>
