<giq-bread-crumbs [crumbs]="crumbs"></giq-bread-crumbs>

<ng-container *ngIf="filteredHighlights$ | async as filteredHighlights">
  <ng-container *ngIf="containsElements">
    <div class="title">
      <h3>Customize Highlights <span class="beta"></span></h3>
      <mat-icon class="filter-icon" matTooltip="Filter" (click)="filter(filterDomain)">filter_alt</mat-icon>
      <span *ngIf="!saving" class="button-link" (click)="saveChanges()">Save</span>
      <giq-small-load *ngIf="saving"></giq-small-load>
    </div>
    <div class="max-cards-info">(Max of 15 cards can be selected)</div>

    <div class="highlights-content" *ngIf="filteredHighlights.length > 0; else noHighlights" cdkDropListGroup>
      <div
        cdkDropList
        class="drop-element"
        *ngFor="let high of filteredHighlights; let i = index"
        [cdkDropListData]="i"
      >
        <div
          class="drag-item"
          cdkDrag
          [cdkDragData]="i" 
          (cdkDragEntered)="dragEntered($event)"
        >
          <mat-slide-toggle
            class="slide"
            color="accent"
            [(ngModel)]="high.active"
            (change)="slideChange($event, high)"
            [disabled]="isToggleDisabled(high)"
          ></mat-slide-toggle>
          <div class="highlight" [class.inactive]="!high.active">
            <giq-highlight class="chart" [config]="high"></giq-highlight>
          </div>
          <div class="description">
            <span>{{ high.name }}</span> {{ high.description }}
            <ng-container *ngIf="high.analyst">
              <br /><br />
              <span>Analyst: </span> {{ high.analyst }}
            </ng-container>
            <ng-container *ngIf="high.refreshFrequency && high.refreshFrequency !== 'UNKNOWN'">
              <br /><br />
              <span>Refresh Frequency: </span> {{ high.refreshFrequency }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noHighlights>
      <div class="no-highlights-message">
        No highlights available for the selected domain.
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #filterDomain>
  <mat-dialog-content>
    <div class="filter-container">
      <h3 class="filter-label">Filters</h3>

      <div class="dropdown-container">
        <label class="dropdown-label" for="division">Domain:</label>
        <mat-form-field appearance="outline" class="input">
          <mat-select placeholder="Select Domain" id="division" (selectionChange)="onCategorySelected($event.value)" panelClass="custom-dropdown">
            <mat-option *ngFor="let cat of categories$ | async" [value]="cat.id">
              {{ cat.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="button-container">
    <button mat-flat-button type="button" (click)="applyFilter()">Submit</button>
    <button mat-flat-button type="button" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</ng-template>
