import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { Highlight } from 'src/app/models/reports';
import { SectorElement, SectorMetadata } from './sector-highlight.metadata';

@Component({
  selector: 'giq-sector-highlight',
  templateUrl: './sector-highlight.component.html',
  styleUrls: ['./sector-highlight.component.scss'],
})
export class SectorHighlightComponent implements AfterViewInit, OnChanges {
  @Input() config: Highlight | undefined;
  @ViewChild('sector', { static: false }) chartContainer!: ElementRef;
  @Input() metadata: SectorMetadata = {
    title: '',
    subtitle: '',
    data: [],
    tabletitle: '',
    showValue: true,
    division: '',
  };
  public id: string = 'sector-' + Math.ceil(Math.random() * 100000);
  public svgCreatedFlag: boolean = false;
  public metadataSets: SectorMetadata[] = [];
  public selectedIndex: number = 0;

  ngAfterViewInit(): void {
    this.clearSvg();
    setTimeout(() => this.createSvg(), 0);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => this.getSubtitles();
      const elements = this.config?.metadata['data'];
      if (elements) {
        this.metadataSets = Object.values(elements).map((item: any) => ({
          title: this.config?.metadata['title'] as string,
          subtitle: this.config?.metadata['subtitle'] as string,
          tabletitle: this.config?.metadata['tabletitle'] as string,
          data: Object.values(item).filter((item: any) => typeof item === 'object'
          ) as SectorElement[],
          division: item.division || '',
          showValue: item.showValue || true,
        }));
      }
      this.updateChartData(this.selectedIndex);
      setTimeout(() => this.createSvg(), 0);
    }
  }
  getSubtitles() {
    return this.metadataSets
      .map((item) => item.division)
      .filter((division) => division);
  }

  updateChartData(index: number): void {
    this.selectedIndex = index;
    this.metadata = this.metadataSets[index];
    this.clearSvg();
    setTimeout(() => this.createSvg(), 0);
  }

  private clearSvg(): void {
    if (this.chartContainer && this.chartContainer.nativeElement) {
      d3.select(this.chartContainer.nativeElement).selectAll('*').remove();
      d3.select(this.chartContainer.nativeElement).selectAll('svg').remove();
      this.svgCreatedFlag = false;
    }
  }

  public formatValue(value: any): string {
    const numericValue = +value;
    let formattedValue;
    if (numericValue > 1000000) {
      const format = Math.floor(numericValue / 1e5)/10;
      return format + 'M';
    } else if (numericValue > 1000) {
      return (Math.floor(numericValue / 100) /10)  + 'K';
    } else {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  public roundPercentage(value: number) {
    var factor = Math.pow(10, 1);
    var absValue = Math.abs(value);
    var roundedValue = Math.ceil(absValue * factor) / factor;
    return Number.isInteger(roundedValue)
      ? roundedValue
      : roundedValue.toFixed(1);
  }

  public formatPercentageChange(value: number, isPositive: boolean): string {
    const roundedValue = Math.abs(value).toFixed(1);
    return (isPositive ? '+' : '-') + roundedValue + '%';
  }

  private createSvg(): void {
    if (!this.svgCreatedFlag) {
      const data = this.metadata.data.sort(
        (a: SectorElement, b: SectorElement) => b.donutValue - a.donutValue
      );

      const element = this.chartContainer.nativeElement;
      const margin = { top: 0, right: 5, bottom: 5, left: 5 };
      const width = 153;
      const height = 153;
      const radius = Math.min(width, height) / 2 - 20;

      const svg = d3
        .select(element)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr(
          'transform',
          `translate(${(width + margin.left - 30) / 2}, ${
            (height + margin.top - 20) / 2
          })`
        )
        .attr('preserveAspectRatio', 'none')
        .attr('viewBox', `0 0 ${width} ${height}`);

      const color = d3
        .scaleOrdinal<string>()
        .domain(data.map((d) => d.label))
        .range(data.map((d) => d.color));

      const pie = d3
        .pie<SectorElement>()
        .value((d) => d.donutValue)
        .sort(null);

      const arc = d3
        .arc<d3.PieArcDatum<SectorElement>>()
        .innerRadius(0)
        .outerRadius(radius);

      const tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('position', 'absolute')
        .style('z-index', '10')
        .style('visibility', 'hidden')
        .style('background-color', 'white')
        .style('border', '1px solid #ccc')
        .style('padding', '5px')
        .style('border-radius', '4px')
        .style('font-size', '12px');

      svg
        .selectAll('path')
        .data(pie(data))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d) => color(d.data.label))
        .attr('stroke', (d) => color(d.data.label))
        .style('stroke-width', '2px')
        .on('mouseover', (event, d) => {
          tooltip
            .html(
              `
            <strong>${d.data.label}</strong><br>
            ${this.formatValue(d.data.donutLabel)} (${Math.round(
                d.data.percentage
              )}%)
          `
            )
            .style('visibility', 'visible');
        })
        .on('mousemove', (event) => {
          tooltip
            .style('top', event.pageY - 10 + 'px')
            .style('left', event.pageX + 10 + 'px');
        })
        .on('mouseout', () => {
          tooltip.style('visibility', 'hidden');
        });

      svg
        .append('circle')
        .attr('r', radius * 0.49)
        .attr('fill', 'white')
        .attr('opacity', 0.6);

      svg
        .selectAll('text')
        .data(pie(data))
        .enter()
        .append('text')
        .attr('transform', (d) => {
          const pos = arc.centroid(d);
          const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 0.75 * Math.cos(midAngle - Math.PI / 2);
          pos[1] = radius * 0.75 * Math.sin(midAngle - Math.PI / 2);
          return `translate(${pos})`;
        })
        .attr('dy', '.35em')
        .style('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .text((d) => {
          const [x, y] = arc.centroid(d);
          const radiusCheck = radius * 0.6;
          const sliceAngle = d.endAngle - d.startAngle;
          const angleThreshold = 0.2;

          return Math.sqrt(x * x + y * y) < radiusCheck &&
            sliceAngle > angleThreshold
            ? this.metadata.showValue
              ? `${this.formatValue(d.data.donutLabel)}`
              : `${Math.round(d.data.percentage)}%`
            : '';
        })
        .style('font-size', '8px')
        .style('fill', '#fff');
    }
    this.svgCreatedFlag = true;
  }
}
