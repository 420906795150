import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GiqCommonModule } from '../common/common-module';
import { AreaChartComponent } from './area/area-chart.component';
import { ComplexDonutComponent } from './complex-donut/complex-donut-chart.component';

@NgModule({
  imports: [CommonModule, GiqCommonModule],
  exports: [AreaChartComponent, ComplexDonutComponent],
  declarations: [AreaChartComponent, ComplexDonutComponent],
  providers: [],
})
export class ChartsModule {}
