import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map, switchMap } from 'rxjs';
import { hasPermission$ } from '../state/user/user.store';
import { AuthService } from './auth.service';

export const appGuard = (routeSnapshot: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const permission = routeSnapshot.data['appPermission'];
  if (permission != null) {
    return authService.getUserInfo().pipe(
      switchMap(() => hasPermission$(permission)),
      map<boolean | undefined, UrlTree | boolean | undefined>(
        (has: boolean | undefined) => {
          if (has) {
            return true;
          }
          return router.createUrlTree(['unauthorized']);
        }
      )
    );
  }
  console.error('data not configured for app guard');

  return router.createUrlTree(['unauthorized']);
};
