import { Directive, HostListener } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';

@Directive({ selector: '[giq-interaction]' })
export class InteractionDirective {
  constructor(private authService: AuthService) {}

  @HostListener('click', ['$event']) onClick($event: any) {
    this.authService.refresh();
  }
}
