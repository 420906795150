import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Highlight } from 'src/app/models/reports';
import { SplitTextMetadata, SplitTextElement } from './split-text.metadata';
import * as d3 from 'd3';

@Component({
  selector: 'giq-split-text-highlight',
  templateUrl: 'split-text-highlight.component.html',
  styleUrls: ['split-text-highlight.component.scss'],
})
export class SplitTextHighlightComponent implements OnInit, OnChanges {
  @Input() config: Highlight | undefined;

  public metadataSets: SplitTextMetadata[] = [];
  public selectedIndex: number = 0;
  public metadata: SplitTextMetadata = {
    background: '44, 151, 74',
    fontColor: 'black',
    elements: [],
    subtitle:'',
    division: ''
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config != null) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => {
        return this.getSubtitles();
      };
      const elements = this.config?.metadata['elements'];
      if (elements) {
        this.metadataSets = Object.values(elements).map((set: any) => ({
          background: this.config?.metadata['background'] as string,
          fontColor: 'black',
          elements: Object.values(set).filter((item: any) => typeof item === 'object') as SplitTextElement[],
          subtitle: set.subtitle || '',
          division: set.division || ''
        }));
      }
      this.metadata = this.metadataSets[this.selectedIndex];
    }
  }

  getSubtitles() {
    const elements = this.config?.metadata['elements'];
    if (Array.isArray(elements) == false) {
      return [];
    } 
    else {
      return Object.values(elements as SplitTextMetadata[])?.map((item: SplitTextMetadata) => {
          return item.division
             });
    }
  }

  ngOnInit() { }

  updateChartData(index: number): void {
    this.selectedIndex = index;
    this.metadata = this.metadataSets[index];
  }

  formatData(value: any, format: 'number' | null): string {
    if (typeof value !== 'string') return value.toString();
    const length = value.length;
    const numericValue = +value;

    if (format === 'number') {
      if (numericValue > 1000000) {
        const formatValue = Math.floor(numericValue / 1e5)/10;
        return formatValue + 'M';
      } else if (numericValue > 10000) {
        return (Math.floor(numericValue / 100) /10)  + 'K';
      } else {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      return value;
    }
  }

  getTruncateLimit(item: any, index: number): number {
    const defaultLimit = 35;
    const reductionFactor = 20;
    const calculatedLimit = defaultLimit - ((reductionFactor * index) / (index || 1));

    if (!item.information) {
      return defaultLimit + 15;
    }

    return calculatedLimit;
  }
}
