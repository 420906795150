import { Component } from '@angular/core';

import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'giq-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  options: AnimationOptions = {
    path: '/assets/teaser.json',
  };
}
