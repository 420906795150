import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFHDHierarchyItem } from '../model/fhd-hierarchy';
import { BehaviorSubject, debounceTime, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'giq-fhd-agent-selector',
  templateUrl: 'agent-selector.component.html',
  styleUrls: ['agent-selector.component.scss'],
})
export class AgentSelectorComponent implements OnInit, OnDestroy {
  public selectedAgents: IFHDHierarchyItem[] = [];
  public possibleAgents: IFHDHierarchyItem[] = [];
  public viewingAgents: BehaviorSubject<IFHDHierarchyItem[]> =
    new BehaviorSubject<IFHDHierarchyItem[]>([]);
  public search: FormControl = new FormControl();

  private subscriptions = new Subscription();
  private sort: ((a: IFHDHierarchyItem, b: IFHDHierarchyItem) => number) | null;

  public somethingChanged = false;

  constructor(
    private dialogRef: MatDialogRef<AgentSelectorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      agents: IFHDHierarchyItem[];
      sort: ((a: IFHDHierarchyItem, b: IFHDHierarchyItem) => number) | null;
      allowEmpty: boolean;
    }
  ) {
    data.agents.forEach((element) => {
      element._selected = false;
      if (element._defaultSelected == true) {
        this.selectedAgents.push(element);
        element._defaultSelected = false;
        element._selected = true;
      }
    });
    if (data.sort != null) {
      this.possibleAgents = data.agents.sort(data.sort);
    } else {
      this.possibleAgents = data.agents;
    }
    this.viewingAgents.next(this.possibleAgents);
    this.sort = data.sort;
  }

  ngOnInit() {
    this.subscriptions.add(
      this.search.valueChanges
        .pipe(debounceTime(200))
        .subscribe((value: string) => {
          var lowercaseValue = value.toLowerCase();
          let vAgents = this.possibleAgents.filter(
            (agent: IFHDHierarchyItem) => {
              return (
                agent.agentName.toLowerCase().includes(lowercaseValue) ||
                agent.agentNumber.toLowerCase().includes(lowercaseValue)
              );
            }
          );
          if (this.sort != null) {
            vAgents = vAgents.sort(this.sort);
          }
          this.viewingAgents.next(vAgents);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public agentSelected(agents: any) {
    console.log(agents);
  }

  public submit(): void {
    if (
      (!!this.data.allowEmpty === false && this.selectedAgents.length === 0) ||
      (this.data.allowEmpty === true && this.somethingChanged === false)
    ) {
      alert('Now allowed');
    } else {
      this.dialogRef.close(this.selectedAgents);
    }
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public selectionChanged(event: MatSelectionListChange) {
    this.somethingChanged = true;
    event.options.forEach((option) => {
      if (option.selected) {
        option.value._selected = true;
        this.selectedAgents.push(option.value);
      } else {
        this.selectedAgents = this.selectedAgents.filter(
          (agent) => agent.agentNumber !== option.value.agentNumber
        );
      }
    });
  }
}
