<ng-container *ngIf="ok$ | async as ok; else loadingOrError">

</ng-container>


<ng-template #loadingOrError>
    <div class="main-container">
        <ng-container *ngIf="error$ | async; else loading">
            <img src="assets/sad-face.svg">
            <div class="message">{{errorMessage}}</div>
            <div class="styled-button" routerLink="[/login]">Try again</div>
        </ng-container>
        <ng-template #loading>
            <main class="animation" lottie [options]="options"></main>
        </ng-template>
    </div>
</ng-template>