import { Component } from '@angular/core';
import { Crumb } from '../common/bread-crumbs/crumb';
import { FIELDS } from './constants/fields';
import { FormControl, FormGroup } from '@angular/forms';
import { IGrowthAdjustments } from './model/growth-adjustments-input-form';
import { GrowthAdjustmentsInputFormService } from './service/growth-adjustments-input-form.service';
import { catchError, finalize, throwError } from 'rxjs';
import { AlertService } from '../core/alerts/alerts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from '@angular/common';

@Component({
  selector: 'giq-growth-adjustments-input-form',
  templateUrl: './growth-adjustments-input-form.component.html',
  styleUrls: ['./growth-adjustments-input-form.component.scss']
})
export class GrowthAdjustmentsInputFormComponent {
  public crumbs: Crumb[] = [
    { name: 'Data Exchange', url: '/exchange' },
    { name: 'Growth Adjustments', url: undefined },
  ];

  public fields = FIELDS;
  fieldsFormGroups = new FormGroup({});

  constructor(private growthAdjustmentsInputFormService: GrowthAdjustmentsInputFormService, private alertService: AlertService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit() {
    this.fields.forEach(field => {
      this.fieldsFormGroups.addControl(field.key, new FormControl())
    });
  }

  onSubmit() {
    const payload: IGrowthAdjustments = this.fieldsFormGroups.value as IGrowthAdjustments;
    const isValid = (Object.keys(payload) as Array<keyof IGrowthAdjustments>).some(key => payload[key]);
    if (isValid) {
      payload.startDate = payload.startDate ? formatDate(payload.startDate, 'yyyy-MM-dd', 'en') : payload.startDate;
      payload.endDate = payload.endDate ? formatDate(payload.endDate, 'yyyy-MM-dd', 'en') : payload.endDate;
      this.spinner.show();
      this.growthAdjustmentsInputFormService.addGrowthAdjustments(payload).pipe(
        catchError((err: any) => {
          if (err.errorMessage) {
            this.alertService.warning(err.errorMessage);
          } else {
            this.alertService.warning(
              'Something went wrong. Please try again!'
            );
          }
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
        .subscribe(() => {
          this.onClear();
          this.alertService.success('Saved');
        });
    }
  }

  onClear() {
    this.fieldsFormGroups.reset();
  }
}
