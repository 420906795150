import { Component, ElementRef, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import {
  TopMenuConfig,
  TopMenuItem,
  TopMenuService,
} from 'src/app/core/top-menu';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'giq-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  providers: [TopMenuService],
})
export class UserInfoComponent implements OnInit {
  private menuConfig: TopMenuConfig = {
    items: [],
    panelClass: 'notifications-panel',
    showMore: false,
    closeOnItemClick: true,
  };

  public userInfo$: Observable<any>;
  public initials = 'IQ';

  constructor(
    private topMenuService: TopMenuService,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
    private authService: AuthService,
    private router: Router
  ) {
    this.userInfo$ = this.authService.getUserInfo().pipe(
      tap((info) => {
        this.initials = info.name[0] + info.lastName[0];
      })
    );
    let itemList: TopMenuItem[] = [];
    if (!environment.fuera) {
      itemList.push({
        icon: 'add_chart',
        content: 'Configure Highlights',
        onClick: () => this.router.navigate(['/highlights']),
      });
    }
    itemList.push({
      icon: 'logout',
      content: 'Logout',
      onClick: () => this.authService.logout(),
    });
    this.menuConfig.items = itemList;
  }

  ngOnInit(): void {
    this.topMenuService.configureService(this.viewContainerRef);
  }

  public openProfile() {
    let overlay = this.topMenuService.openMenu(
      this.menuConfig,
      this.elementRef
    );
  }
}
