import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Crumb } from 'src/app/common/bread-crumbs/crumb';
import { AlertService } from 'src/app/core/alerts/alerts.service';
import { GIQError } from 'src/app/core/errors/giq-error';
import { highlightsRetrieved } from 'src/app/core/state/reports/reports.store';
import { Highlight } from 'src/app/models/reports';
import { EditHighlightsService } from './highlights.service';
import { Categories } from 'src/app/admin/models/categories';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from 'src/app/core/reports.service';

@Component({
  selector: 'giq-edit-highlights',
  templateUrl: './edit-highlights.component.html',
  styleUrls: ['./edit-highlights.component.scss'],
  providers: [EditHighlightsService],
})
export class EditHighlightsComponent implements OnInit {
  public crumbs: Crumb[] = [{ name: 'Back', url: '/home' }];
  public containsElements = false;
  public highlights$: Observable<Highlight[]> =
    this.highlightsService.getAllUserHighlights();
  public elements: Highlight[] = [];
  private dialogRef: MatDialogRef<any> | undefined;

  public saving: boolean = false;

  constructor(
    private highlightsService: EditHighlightsService,
    private alertService: AlertService,
    private router: Router,
    private reportsService: ReportsService,
    private dialog: MatDialog
  ) {}

  public categories$!: Observable<Categories[]>;
  public filteredHighlights$!: Observable<Highlight[]>;
  public selectedCategoryId: string | null = null;

  ngOnInit() {
    this.highlights$.subscribe((x: Highlight[]) => {
      this.containsElements = x.length > 0;
      this.elements = x
        .map((x) => {
          x.order = x.order ?? 150;
          return x;
        })
        .sort((a, b) => {
          if (a.active === true && b.active === false) {
            return -1;
          } else if (a.active === false && b.active === true) {
            return 1;
          } else {
            // If both are active/inactive, sort by order
            return a.order! - b.order!;
          }
        });
  
      this.filteredHighlights$ = this.highlights$.pipe(
        map((highs: Highlight[]) => {
          return highs
            .map((x) => {
              x.order = x.order ?? 150;
              return x;
            })
            .sort((a, b) => {
              if (a.active === true && b.active === false) {
                return -1;
              } else if (a.active === false && b.active === true) {
                return 1;
              } else {
                return a.order! - b.order!;
              }
            });
        })
      );
  
      this.checkAndShowWarnings();
    });
  
    this.categories$ = this.reportsService.GetWorkspaceCategories().pipe(
      map((categories: Categories[]) => {
        const allOption: Categories = { id: 'all', name: 'All' };
        return [allOption, ...categories.filter(category => category.id !== 'favs')];
      })
    );
  }
  
  private checkAndShowWarnings() {
    const activeCount = this.elements.filter((el) => el.active).length;
    if (activeCount > 15) {
      this.elements
        .filter((el) => el.active)
        .slice(15)
        .forEach((el) => (el.active = false));
      this.alertService.warning('Only 15 cards can be selected');
    } else if (activeCount === 15) {
      this.alertService.warning(
        'You have reached the maximum limit of 15 cards'
      );
    }
  }

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;
    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    phContainer.removeChild(phElement!);
    phContainer.parentElement!.insertBefore(
      phElement!,
      dropIndex > dragIndex ? phContainer.nextSibling : phContainer
    );
  
    moveItemInArray(this.elements, dragIndex, dropIndex);
  
    this.updateFilteredHighlights();
  }
  
  private updateFilteredHighlights(): void {
    
    if (this.selectedCategoryId === 'all' || this.selectedCategoryId === null) {
      this.filteredHighlights$ = new Observable(observer => {
        observer.next([...this.elements]); 
        observer.complete();
      });
    } else {
      
      this.filteredHighlights$ = new Observable(observer => {
        const filtered = this.elements.filter(
          (highlight) => highlight.reportCategoryId === this.selectedCategoryId
        );
        observer.next(filtered);
        observer.complete();
      });
    }
  }
  
  public saveChanges() {
    this.saving = true;
  
    const preference = this.elements.map((x) => ({
      id: x.id,
      active: x.active ?? false,
    }));
  
    this.highlightsService.saveUserPreference(preference).subscribe({
      next: (_) => {
        this.alertService.success('Changes saved successfully');
        highlightsRetrieved(false);
        this.router.navigate(['/home']);
      },
      error: (error: GIQError) => {
        this.alertService.error(error.errorMessage);
        this.saving = false;
      },
    });
  }
  
  public slideChange(event: any, element: Highlight): void {
    const activeCount = this.elements.filter((el) => el.active).length;
  
    if (event.checked) {
      if (activeCount < 15) {
        element.active = true;
      } else {
        this.alertService.warning('You can only select up to 15 cards');
        event.source.checked = false;
      }
    } else {
      element.active = false;
    }
  
    const matchingElement = this.elements.find(el => el.id === element.id);
    if (matchingElement) {
      matchingElement.active = element.active;
    }
  
    this.checkAndShowWarnings();    
  }

  public isToggleDisabled(element: Highlight): boolean {
    const activeCount = this.elements.filter((el) => el.active).length;
    return !element.active && activeCount >= 15;
  }

  public filter(template: TemplateRef<any>): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = {
      right: '0.5rem',
      top: '4rem'
    };
   dialogConfig.width = '30vw';

    this.dialogRef = this.dialog.open(template, dialogConfig);
  }

  public onCategorySelected(categoryId: string | null): void {
    this.selectedCategoryId = categoryId === null ? null : categoryId;
  }
  
  public applyFilter(): void {
    if (this.selectedCategoryId === 'all') {
      this.filteredHighlights$ = this.highlights$.pipe(
        map((highs: Highlight[]) => {
          return highs
            .sort((a, b) => a.order! - b.order!)
            .sort((a, b) => {
              if (a.active === true && b.active === false) {
                return -1;
              } else if (a.active === false && b.active === true) {
                return 1;
              } else {
                return 0;
              }
            });
        })
      );
    } else if (this.selectedCategoryId) {
      this.filteredHighlights$ = this.highlights$.pipe(
        map((highs: Highlight[]) => {
          return highs
            .filter((high: Highlight) => high.reportCategoryId === this.selectedCategoryId)
            .sort((a, b) => a.order! - b.order!)
            .sort((a, b) => {
              if (a.active === true && b.active === false) {
                return -1;
              } else if (a.active === false && b.active === true) {
                return 1;
              } else {
                return 0;
              }
            });
        })
      );
    }
  
    this.dialogRef?.close();
  }
}
