import { Component } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AGENT_AUTOMATION,
  BANK_COMPLIANCE,
  CLAIM_AUDIT_LOGS,
  CUSTOMER_CARE,
  PRODUCTION_TASK,
  UNDERWRITING,
  WORKSITE_TRACKER,
} from '../constants/permissions';
import { hasPermission$ } from '../core/state/user/user.store';

@Component({
  selector: 'giq-data-exchange',
  templateUrl: './data-exchange.component.html',
  styleUrls: ['./data-exchange.component.scss'],
})
export class DataExchangeComponent {
  public psFormAccess$: Observable<boolean | undefined> = this.showApp(
    CUSTOMER_CARE,
    false
  );
  public automationFormAccess$: Observable<boolean | undefined> = this.showApp(
    AGENT_AUTOMATION,
    true
  );
  public worksiteAccess$: Observable<boolean | undefined> = this.showApp(
    WORKSITE_TRACKER,
    false
  );
  public bankCompliance$: Observable<boolean | undefined> = this.showApp(
    BANK_COMPLIANCE,
    false
  );
  public claimAuditLogsAccess$: Observable<boolean | undefined> = this.showApp(
    CLAIM_AUDIT_LOGS,
    false
  );
  public productionTask$: Observable<boolean | undefined> = this.showApp(
    PRODUCTION_TASK,
    false
  );
  public underwriting$: Observable<boolean | undefined> = this.showApp(
    UNDERWRITING,
    true
  );

  private showApp(
    permission: string,
    allowOutside: boolean
  ): Observable<boolean> {
    return hasPermission$(permission).pipe(
      map((hasPermission: boolean | undefined) => {
        if (!hasPermission) {
          return false;
        }

        if (!environment.fuera) {
          return true;
        }

        if (allowOutside) {
          return true;
        }
        return false;
      })
    );
  }

  // combines every role obserbable and checks for any access
  public hasAnyAccess$: Observable<boolean | undefined> = combineLatest(
    [
      this.psFormAccess$,
      this.automationFormAccess$,
      this.worksiteAccess$,
      this.bankCompliance$,
      this.claimAuditLogsAccess$,
      this.productionTask$,
      this.underwriting$,
    ],
    (...res) => res.includes(true)
  );
}
