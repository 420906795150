import { catchError, Observable, throwError } from "rxjs";
import { ErrorType } from "./error-type";
import { GIQError } from './giq-error';

export function withErrorMapping<T>(source$: Observable<T>): Observable<T> {
    return source$.pipe(
        catchError<any, Observable<GIQError>>(er => {
            console.error(er);
            const mappedError = mapError(er);
            return throwError(() => mappedError);
        })
    )
}

function mapError(error: any): GIQError {
    let result: GIQError;
    switch (error.status) {
        case 0:
            result = {
                code: ErrorType.ConnectionError,
                errorMessage: 'Remote server not responding',
                details: error.message
            };
            break;
        case 400:
            result = {
                code: ErrorType.BadRequest,
                errorMessage: error.error.message,
                details: error.error
            };
            break;
        case 404:
            result = {
                code: ErrorType.NotFound,
                errorMessage: "Resource not found",
                details: error.error.message
            };
            break;
        case 403:
            result = {
                code: ErrorType.Unauthorized,
                errorMessage: "You dont have permissions to access this resource",
                details: error.error.message
            };
            break;
        case 500:
            result = {
                code: ErrorType.ServerError,
                errorMessage: "Remote server error",
                details: error.error.message
            };
            break;
        default:
            result = {
                code: ErrorType.UnknownError,
                errorMessage: 'Unknown Error',
                details: error.message
            };
    }

    return result;

}