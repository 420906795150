import { Component } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AGENT_AUTOMATION,
  BANK_COMPLIANCE,
  CLAIM_AUDIT_LOGS,
  CUSTOMER_CARE,
  LEGAL_COMPLAINTS,
  PRODUCTION_TASK,
  UNDERWRITING,
  FHDHIERARCHY,
  FHDINPUTFORM,
  GROWTH_ADJUSTMENTS,
  WORKSITE_TRACKER,
  LEGAL_EXPENSE,
  OUTAGE_TRACKER,
} from '../constants/permissions';
import { hasPermission$ } from '../core/state/user/user.store';

@Component({
  selector: 'giq-data-exchange',
  templateUrl: './data-exchange.component.html',
  styleUrls: ['./data-exchange.component.scss'],
})
export class DataExchangeComponent {
  public psFormAccess$: Observable<boolean | undefined> = this.showApp(
    CUSTOMER_CARE,
    false
  );
  public automationFormAccess$: Observable<boolean | undefined> = this.showApp(
    AGENT_AUTOMATION,
    true,
    false
  );
  public worksiteAccess$: Observable<boolean | undefined> = this.showApp(
    WORKSITE_TRACKER,
    false
  );
  public bankCompliance$: Observable<boolean | undefined> = this.showApp(
    BANK_COMPLIANCE,
    false
  );
  public claimAuditLogsAccess$: Observable<boolean | undefined> = this.showApp(
    CLAIM_AUDIT_LOGS,
    false
  );
  public productionTask$: Observable<boolean | undefined> = this.showApp(
    PRODUCTION_TASK,
    false
  );
  public underwriting$: Observable<boolean | undefined> = this.showApp(
    UNDERWRITING,
    true
  );
  public fhdhierarchy$: Observable<boolean | undefined> = this.showApp(
    FHDHIERARCHY,
    true
  );
  public fhdinputform$: Observable<boolean | undefined> = this.showApp(
    FHDINPUTFORM,
    true
  );
  public growthadjustmentsinputform$: Observable<boolean | undefined> = this.showApp(
    GROWTH_ADJUSTMENTS,
    false
  );
  public legalComplaints$: Observable<boolean | undefined> = this.showApp(
    LEGAL_COMPLAINTS,
    false
  );

  public legalexpense$: Observable<boolean | undefined> = this.showApp(
    LEGAL_EXPENSE,
    false
  );

  public outageTracker$: Observable<boolean | undefined> = this.showApp(
    OUTAGE_TRACKER,
    false
  );

  private showApp(
    permission: string,
    allowOutside: boolean,
    allowInside: boolean = true
  ): Observable<boolean> {
    return hasPermission$(permission).pipe(
      map((hasPermission: boolean | undefined) => {
        if (!hasPermission) {
          return false;
        }

        if (!environment.fuera && allowInside) {
          return true;
        }

        if (environment.fuera && allowOutside) {
          return true;
        }
        return false;
      })
    );
  }

  // combines every role obserbable and checks for any access
  public hasAnyAccess$: Observable<boolean | undefined> = combineLatest(
    [
      this.psFormAccess$,
      this.automationFormAccess$,
      this.worksiteAccess$,
      this.bankCompliance$,
      this.claimAuditLogsAccess$,
      this.productionTask$,
      this.underwriting$,
      this.fhdhierarchy$,
      this.fhdinputform$,
      this.growthadjustmentsinputform$,
      this.legalComplaints$,
      this.legalexpense$,
      this.outageTracker$,
    ],
    (...res) => res.includes(true)
  );
}
