import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  ADMIN,
  AGENT_AUTOMATION,
  AGENT_AUTOMATION_MODIFY,
  AGENT_AUTOMATION_SAVE,
  BANK_COMPLIANCE,
  CHATBOT_CUSTOMESERVICE,
  CHATBOT_DOCUMENT,
  CHATBOT_LND_AGENT,
  CHATS,
  CLAIM_AUDIT_LOGS,
  CUSTOMER_CARE,
  EXTRACTS,
  LEGAL_COMPLAINTS,
  LEGAL_COMPLAINTS_ADMIN,
  LEGAL_EXPENSE,
  LEGAL_EXPENSE_CREATE_CASE,
  LEGAL_EXPENSE_MODIFY_CASE,
  LEGAL_EXPENSE_CREATE_EXPENSE,
  LEGAL_EXPENSE_MODIFY_EXPENSE,
  PRODUCTION_TASK,
  PRODUCTION_TASK_CREATE,
  PRODUCTION_TASK_MODIFY,
  PRODUCTION_TASK_SUPERVISOR,
  RECORD_CATALOG,
  SALESBOARD,
  UNDERWRITING,
  WORKSITE_TRACKER,
  WORKSITE_TRACKER_VOID,
  FHDHIERARCHY,
  OUTAGE_TRACKER,
  FHDINPUTFORM,
  CLAIM_AUDIT_LOGS_MANAGER,
  CUSTOMER_CARE_USER,
  CUSTOMER_CARE_SUPERVISOR,
  CUSTOMER_CARE_ADMIN,
  FHDHIERARCHY_ADMIN,
  PS_ENTRY_FORM_MANUAL,
  PS_ENTRY_FORM_AUTO,
  GROWTH_ADJUSTMENTS,
} from 'src/app/constants/permissions';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../state/user/user-info';
import {
  loggingOut,
  setUserAuthenticated,
  setUserInfo,
} from '../state/user/user.store';
import { IAuthService } from './iauth.service';

@Injectable()
export class LocalAuthService implements IAuthService {
  constructor(private router: Router) {}
  isTokenExpired(): boolean {
    return false;
  }

  setOAuthConfiguration(company: string): void {}

  login(state: string): void {
    alert('loging in');
    this.router.navigateByUrl('/login/callback');
  }

  checkSession(): boolean {
    setUserAuthenticated(true);
    return true;
  }

  getToken(): Observable<{ state: string | undefined; validToken: boolean }> {
    return of({
      state: environment.defaultRoute,
      validToken: true,
    });
  }

  public refresh(): void {
    console.log('refreshing' + new Date());
  }

  getUserInfo(): Observable<UserInfo> {
    const info: UserInfo = {
      name: 'Benjamin',
      lastName: 'Sandoval',
      email: 'devtest@test.com',
      userName: 'devtest',
      frontActions: [
        CUSTOMER_CARE,
        AGENT_AUTOMATION,
        AGENT_AUTOMATION_SAVE,
        AGENT_AUTOMATION_MODIFY,
        WORKSITE_TRACKER,
        EXTRACTS,
        RECORD_CATALOG,
        WORKSITE_TRACKER_VOID,
        BANK_COMPLIANCE,
        CLAIM_AUDIT_LOGS,
        CLAIM_AUDIT_LOGS_MANAGER,
        PRODUCTION_TASK_MODIFY,
        PRODUCTION_TASK,
        PRODUCTION_TASK_CREATE,
        PRODUCTION_TASK_SUPERVISOR,
        SALESBOARD,
        UNDERWRITING,
        CHATS,
        CHATBOT_CUSTOMESERVICE,
        ADMIN,
        LEGAL_EXPENSE,
        LEGAL_EXPENSE_CREATE_CASE,
        LEGAL_EXPENSE_MODIFY_CASE,
        LEGAL_EXPENSE_CREATE_EXPENSE,
        LEGAL_EXPENSE_MODIFY_EXPENSE,
        CHATBOT_DOCUMENT,
        LEGAL_COMPLAINTS,
        LEGAL_COMPLAINTS_ADMIN,
        OUTAGE_TRACKER,
        FHDINPUTFORM,
        'chatbot_customerservice_GLOBE',
        'chatbot_customerservice_AIL',
        'chatbot_customerservice_LND',
        'chatbot_customerservice_UA',
        'chatbot_customerservice_MILITARY',
        CHATBOT_LND_AGENT,
        'chatbot_lndagent_WS',
        'chatbot_lndagent_IND',
        FHDHIERARCHY,
        CUSTOMER_CARE_USER,
        CUSTOMER_CARE_SUPERVISOR,
        CUSTOMER_CARE_ADMIN,
        FHDHIERARCHY_ADMIN,
        PS_ENTRY_FORM_MANUAL,
        PS_ENTRY_FORM_AUTO,
        GROWTH_ADJUSTMENTS,
      ],
    };
    setUserInfo(info);
    return of<UserInfo>(info);
  }

  public getSchema(): string {
    return 'Bearer';
  }

  getAccessToken(): string {
    return '';
  }

  logout(): void {
    loggingOut();
    setUserAuthenticated(false);
    this.router.navigateByUrl('/logout');
  }
}
