<ng-container *ngIf="report$ | async as report">
  <div class="item">
    <label>Report Name</label>
    <span class="text">{{ report.name }}</span>
  </div>

  <div class="item">
    <label>Overview</label>
    <span class="text overview">{{ report.overview }}</span>
  </div>

  <div class="bottom">
    <div class="item">
      <label>Business Owner</label>
      <span class="text">{{ report.analyst }}</span>
    </div>
    <!-- <div class="item">
            <label>Refresh Date</label>
            <span class="text">{{report.refreshDate | date: 'MM/dd/YYYY' }}</span>
        </div> -->
    <div class="item">
      <label>Refresh Frequency</label>
      <span class="text">{{
        report.refreshFrequency == "ON_DEMAND"
          ? "Real-time"
          : (report.refreshFrequency | pascalCase)
      }}</span>
    </div>
  </div>

  <div class="item" *ngIf="report.metrics?.length">
    <label>Metrics</label>
    <span class="text">
      <span class="metricSpan" *ngFor="let metric of report.metrics">{{
        metric
      }}</span>
    </span>
  </div>
</ng-container>
