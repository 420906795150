<div class="headers">
    <h3 class="header-name">Growth Adjustments</h3>
    <giq-bread-crumbs [crumbs]="crumbs"></giq-bread-crumbs>
</div>

<div class="main-container">
    <ngx-spinner [fullScreen]="false"></ngx-spinner>
    <div class="container">
        <table class="input-form-table">
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Description</th>
                    <th></th>
                </tr>
            </thead>
            <tbody [formGroup]="fieldsFormGroups">
                <tr *ngFor="let field of fields">
                    <td>{{field.name}}</td>
                    <td>{{field.description}}</td>
                    <td>
                        <mat-form-field appearance="outline" class="input-control">
                            <ng-container [ngSwitch]="field.inputType">
                                <input *ngSwitchCase="'text'" matInput [formControlName]="field.key" />
                                <input *ngSwitchCase="'number'" type="number" min="0" giqWholeNumberOnly matInput
                                    [formControlName]="field.key" />
                                <mat-select *ngSwitchCase="'dropdown'" [formControlName]="field.key">
                                    <mat-option *ngFor="let item of field.dropDownValues"
                                        [value]="item.key">{{item.value}}</mat-option>
                                </mat-select>
                                <ng-container *ngSwitchCase="'date'">
                                    <input matInput [matDatepicker]="picker" [formControlName]="field.key">
                                    <mat-datepicker-toggle class="date-picker-toggle" matIconSuffix
                                        [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </ng-container>
                            </ng-container>
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="action-container">
            <button mat-button (click)="onClear()">Clear</button>
            <button mat-button (click)="onSubmit()">Save</button>
        </div>
    </div>
</div>