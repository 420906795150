import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({ selector: '[giqColorValidation]' })
export class ColorValidationDirective {
  @Input() giqColorValidation?: AbstractControl;
  @Input() reactOnTouch: boolean = false;
  constructor() {}

  @HostBinding('class')
  get elementClass(): string {
    let theClass = this.giqColorValidation?.errors == null ? 'ok' : 'error';
    if (
      this.reactOnTouch === true &&
      this.giqColorValidation?.dirty === false
    ) {
      theClass = '';
    }
    return theClass;
  }
}
