import { Component, OnDestroy } from '@angular/core';
import { Crumb } from '../common/bread-crumbs/crumb';

import { FhdHierarchyService } from './service/fhd-hierarchy.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { AlertService } from '../core/alerts/alerts.service';
import { catchError, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { IFHDHierarchyItem } from './model/fhd-hierarchy';
import { GIQError } from '../core/errors/giq-error';
import { hasPermission$ } from '../core/state/user/user.store';
import { FHDHIERARCHY_ADMIN } from '../constants/permissions';
import { MatDialog } from '@angular/material/dialog';
import { AgentSelectorComponent } from './agent-selector/agent-selector.component';
import { uuid } from '../core/uuid/uuid';

@Component({
  selector: 'giq-fhd-hierarchy',
  templateUrl: './fhd-hierarchy.component.html',
  styleUrls: ['./fhd-hierarchy.component.scss'],
})
export class FhdHierarchyComponent implements OnDestroy {
  public crumbs: Crumb[] = [
    { name: 'Data Exchange', url: '/exchange' },
    { name: 'FHD Hierarchy', url: undefined },
  ];

  // public data: null | IFHDHierarchyItem[] = null;
  public agencies: IFHDHierarchyItem[] = [];

  public selectedAgency: IFHDHierarchyItem | null = null;
  public showAgencies: boolean = false;
  public modifying: boolean = false;
  public allowAdminConfiguration: boolean = false;
  public canModify: boolean = false;
  public isSuperAdmin: boolean = false;
  public adminList: IFHDHierarchyItem[] = [];
  public agencyUsers: IFHDHierarchyItem[] | null = null;
  public agenciesLoaded: boolean = false;

  private modifiedHierarchy: IFHDHierarchyItem[] | null = null;

  public selectedAgency$: Subject<IFHDHierarchyItem> =
    new Subject<IFHDHierarchyItem>();
  public data$: Observable<IFHDHierarchyItem[]> = this.selectedAgency$.pipe(
    switchMap((agency: IFHDHierarchyItem) => {
      this.spinner.show();
      this.agencyUsers = null;
      this.getAgencyUsers();
      return this.fhdHierarchyService.getFHDHierarchy(agency.agencyId).pipe(
        map((hierarchy: IFHDHierarchyItem[]) => {
          let finalHierarchy: IFHDHierarchyItem[] = [];
          let admList: IFHDHierarchyItem[] = [];
          // put in a separate list when  the item has position is aa
          hierarchy.forEach((x) => {
            if (x.position == 'aa') {
              admList.push(x);
            } else {
              finalHierarchy.push(x);
            }
          });
          this.adminList = admList;
          this.spinner.hide();
          this.modifiedHierarchy = finalHierarchy;
          this.spinner.hide();
          return finalHierarchy;
        })
      );
    }),
    catchError((error: GIQError) => {
      this.spinner.hide();
      this.alertService.error(error.details);
      return of([]);
    })
  );

  constructor(
    private fhdHierarchyService: FhdHierarchyService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.selectedAgency$.complete();
  }

  ngOnInit() {
    this.spinner.show();
    hasPermission$(FHDHIERARCHY_ADMIN)
      .pipe(
        switchMap((hasPermission: boolean | undefined) => {
          if (hasPermission === true) {
            this.isSuperAdmin = true;
            return of({ position: 'super_admin' });
          } else {
            return this.fhdHierarchyService.getUserInfo();
          }
        }),
        switchMap((userPosition: { position: string | null }) => {
          if (
            userPosition.position == 'agency' ||
            userPosition.position == 'super_admin'
          ) {
            this.allowAdminConfiguration = true;
            this.canModify = true;
          }

          if (userPosition.position == 'aa') {
            this.canModify = true;
          }
          return this.fhdHierarchyService.getAgencies();
        })
      )
      .subscribe({
        next: (agencies: IFHDHierarchyItem[]) => {
          this.agencies = agencies;
          this.selectedAgency = this.agencies[0] || null;
          this.showAgencies = this.agencies.length > 1;
          this.agenciesLoaded = true;
          if (this.selectedAgency == null) {
            this.alertService.warning(
              'You dont have access to any agency hierarchy. Ask your Administrator to add you to the hierarchy'
            );
            this.spinner.hide();
          } else {
            this.selectedAgency$.next(this.selectedAgency);
          }
        },
        error: (error: GIQError) => {
          this.alertService.error(error.details);
          this.spinner.hide();
        },
      });
  }

  public agencyChanged(): void {
    if (this.selectedAgency != null) {
      this.selectedAgency$.next(this.selectedAgency);
    }
  }

  public onModifyingHierarchy(hierarchy: IFHDHierarchyItem[] | null): void {
    this.modifying = true;
    this.modifiedHierarchy = hierarchy;
  }

  private getAgencyUsers(): void {
    if (this.selectedAgency?.agencyId != null) {
      this.fhdHierarchyService
        .getAgents(this.selectedAgency.agencyId)
        .subscribe((x) => {
          this.agencyUsers = x;
        });
    } else {
      this.alertService.error('Cannot obtain user list from invalid agency');
    }
  }

  public discard(): void {
    this.agencyChanged();
    this.modifying = false;
  }

  public save(): void {
    if (
      this.modifying === true &&
      this.canModify === true &&
      this.modifiedHierarchy != null &&
      this.selectedAgency?.agentNumber != null
    ) {
      this.modifying = false;
      this.spinner.show();
      this.fhdHierarchyService
        .saveHierarchy(this.selectedAgency.agentNumber, [
          ...this.modifiedHierarchy,
          ...this.adminList,
        ])
        .subscribe({
          next: (x) => {
            this.alertService.success('Hierarchy saved successfully');
            this.spinner.hide();
          },
          error: (err: GIQError) => {
            this.modifying = true;
            this.alertService.error(err.details);
            this.spinner.hide();
          },
        });
    }
  }

  public configureAgencies() {
    this.spinner.show();
    this.fhdHierarchyService
      .getPossibleAgencies()
      .pipe(
        switchMap((possibleAgencies: IFHDHierarchyItem[]) => {
          this.spinner.hide();

          possibleAgencies.forEach((x) => {
            if (this.agencies.find((y) => y.agentNumber === x.agentNumber)) {
              x._defaultSelected = true;
            }
          });

          const dialogRef = this.dialog.open(AgentSelectorComponent, {
            disableClose: true,
            data: {
              title: 'Agencies List',
              agents: possibleAgencies,
              sort: (a: IFHDHierarchyItem, b: IFHDHierarchyItem) => {
                if (a._selected == true && b._selected !== true) {
                  return -1;
                }
                if (a._selected !== true && b._selected == true) {
                  return 1;
                }
                return 0;
              },
            },
          });

          return dialogRef.afterClosed();
        }),
        switchMap((agcs: IFHDHierarchyItem[] | undefined) => {
          if (agcs != null) {
            const agencyNumbers = this.agencies.map((x) => x.agentNumber);
            let agencyPayload: { add: IFHDHierarchyItem[]; remove: string[] } =
              {
                add: [],
                remove: [],
              };
            agcs.forEach((agc) => {
              if (agencyNumbers.indexOf(agc.agentNumber) === -1) {
                agencyPayload.add.push(agc);
              }
            });
            const newAgcNumbers = agcs.map((x) => x.agentNumber);
            agencyNumbers.forEach((agc) => {
              if (newAgcNumbers.indexOf(agc) === -1) {
                agencyPayload.remove.push(agc);
              }
            });
            if (
              agencyPayload.add.length > 0 ||
              agencyPayload.remove.length > 0
            ) {
              if (agencyPayload.remove.length > 0) {
                return this.alertService
                  .confirmDialog(
                    'Agencies modification',
                    'Deleting an agency will deactivate the entire structure. Do you want to proceed?'
                  )
                  .pipe(
                    switchMap((response: boolean) => {
                      if (response === true) {
                        return this.fhdHierarchyService.addInsertAgencies(
                          agencyPayload
                        );
                      } else {
                        return of(false);
                      }
                    })
                  );
              } else {
                return this.fhdHierarchyService.addInsertAgencies(
                  agencyPayload
                );
              }
            }
          }
          return of(false);
        })
      )
      .subscribe({
        next: (result: boolean) => {
          this.spinner.hide();
          if (result === true) {
            this.alertService.success('Agencies updated successfully');
            this.ngOnInit();
          }
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  public configureAdministrators() {
    this.agencyUsers?.forEach((x) => {
      if (this.adminList.find((y) => y.agentNumber === x.agentNumber)) {
        x._agencyAdministrator = true;
        x._defaultSelected = true;
      }
    });

    const dialogRef = this.dialog.open(AgentSelectorComponent, {
      disableClose: true,
      data: {
        title: 'Administration List ',
        agents: this.agencyUsers,
        allowEmpty: true,
        sort: (a: IFHDHierarchyItem, b: IFHDHierarchyItem) => {
          if (a._selected == true && b._selected !== true) {
            return -1;
          }
          if (a._selected !== true && b._selected == true) {
            return 1;
          }
          return 0;
        },
      },
    });

    dialogRef.afterClosed().subscribe((agents: IFHDHierarchyItem[]) => {
      if (agents != null) {
        this.modifying = true;
        let newAdminList: IFHDHierarchyItem[] = [];
        if (agents.length > 0) {
          agents.forEach((element) => {
            let existingAdmin = this.adminList.find(
              (x) => x.agentNumber === element.agentNumber
            );
            if (existingAdmin) {
              newAdminList.push(existingAdmin);
            } else {
              const item: IFHDHierarchyItem = {
                ...element,
                parent: null,
                id: element.id != null ? element.id : uuid(),
                agencyId: this.selectedAgency?.agentNumber!,
                parentId: null,
                position: 'aa',
                _agencyAdministrator: true,
                _modified: true,
              };
              newAdminList.push(item);
            }
          });
        }

        this.adminList = newAdminList;
      }
    });
  }
}
