import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { withErrorMapping } from '../../core/errors/with-error-mapping';
import { IFHDHierarchyItem } from '../model/fhd-hierarchy';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FhdHierarchyService {
  baseURL = `${environment.apiUrl}`;
  constructor(private httpClient: HttpClient) {}

  getAgents(agencyId: string) {
    return this.httpClient
      .get<IFHDHierarchyItem[]>(`${this.baseURL}/fhdhierarchy/users`, {
        params: { agencyId },
      })
      .pipe(withErrorMapping);
  }

  getPossibleAgencies(): Observable<IFHDHierarchyItem[]> {
    return this.httpClient
      .get<IFHDHierarchyItem[]>(
        `${this.baseURL}/fhdhierarchy/possible-agencies`
      )
      .pipe(withErrorMapping);
  }

  saveHierarchy(agencyId: string, fhdHierarchyDetail: IFHDHierarchyItem[]) {
    return this.httpClient
      .post<IFHDHierarchyItem[]>(
        `${this.baseURL}/fhdhierarchy/${agencyId}/update`,
        fhdHierarchyDetail
      )
      .pipe(withErrorMapping);
  }

  getFHDHierarchy(agencyId: string) {
    return this.httpClient
      .get<IFHDHierarchyItem[]>(`${this.baseURL}/fhdhierarchy/hierarchy`, {
        params: { agencyId },
      })
      .pipe(withErrorMapping);
  }

  getAgencies() {
    return this.httpClient
      .get<IFHDHierarchyItem[]>(`${this.baseURL}/fhdhierarchy/agencies`)
      .pipe(withErrorMapping);
  }

  addInsertAgencies(payload: {
    add: IFHDHierarchyItem[];
    remove: string[];
  }): Observable<boolean> {
    return this.httpClient
      .post(`${this.baseURL}/fhdhierarchy/agencies`, payload)
      .pipe(map((x) => true))
      .pipe(withErrorMapping);
  }

  getUserInfo(): Observable<{ position: string | null }> {
    return this.httpClient
      .get<{ position: string | null }>(
        `${this.baseURL}/fhdhierarchy/user-info`
      )
      .pipe(withErrorMapping);
  }
}
