import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationSkipped,
  Router,
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { Crumb } from 'src/app/common/bread-crumbs/crumb';
import { AlertService } from 'src/app/core/alerts/alerts.service';
import { ReportsService } from 'src/app/core/reports.service';
import {
  allCategories$,
  findReportsByString$,
  findReportsByStringAndFilters$,
  reportsOfCategory$,
  reportsRetrieved$,
} from 'src/app/core/state/reports/reports.store';
import { Category, Report } from 'src/app/models/reports';
import { ReportsDetailsService } from '../reports-details.service';

const noSpace = new RegExp('[^s]');

@Component({
  selector: 'giq-report-catalog',
  templateUrl: './report-catalog.component.html',
  styleUrls: ['./report-catalog.component.scss'],
})
export class ReportCatalogComponent implements OnInit, OnDestroy {
  public crumbs: Crumb[] = [
    { name: 'Home', url: '/' },
    { name: 'Report Catalog', url: undefined },
  ];

  public category = new FormControl<string | null>(null, [
    Validators.pattern(noSpace),
  ]);
  public searchString = new FormControl<string | null>(null, [
    Validators.pattern(noSpace),
  ]);
  public dataSource = new FormControl<string | null>(null, [
    Validators.pattern(noSpace),
  ]);
  public records$: BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>(
    []
  );
  public search$: Subject<number> = new Subject<number>();
  public categories$: Observable<Category[]>;
  private subscriptions: Subscription = new Subscription();

  public columnTitles = [
    'reportName',
    'domain',
    'analyst',
    'refreshFrequency',
    'info',
    'favorite',
    'actions',
  ];

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private reportService: ReportsService,
    private router: Router,
    private reportDetailsService: ReportsDetailsService,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef
  ) {
    let categoryId = activatedRoute.snapshot.queryParams['category'] ?? null;

    this.category.setValue(categoryId);
    this.spinner.show();
    this.categories$ = reportsRetrieved$.pipe(
      switchMap((retrieved: boolean) => {
        if (retrieved) {
          return allCategories$;
        } else {
          return reportService.GetWorkspaceCategories();
        }
      }),
      tap((x) => {
        this.spinner.hide();
        this.fetchList();

        let searchString =
          this.activatedRoute.snapshot.queryParams['searchString'] ?? null;
        if (searchString) {
          this.searchByString(searchString);
        }
      })
    );

    this.subscriptions.add(
      this.search$
        .pipe(
          switchMap((x) =>
            reportsOfCategory$(this.category.value, this.dataSource.value)
          )
        )
        .subscribe((x) => {
          if (!this.searchString.value?.trim().length) {
            this.records$.next(x);
          }
        })
    );

    // get structure on navigation end
    this.subscriptions.add(
      router.events.subscribe((event: Event) => {
        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationSkipped
        ) {
          let searchString =
            activatedRoute.snapshot.queryParams['searchString'] ?? null;
          if (searchString) {
            this.searchString.setValue(searchString);
            this.searchByString(searchString);
          }
        }
      })
    );
  }

  ngOnInit() {
    this.reportDetailsService.initDetailsServiceWithParams(
      this.injector,
      this.viewContainerRef
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  disableSearch() {
    return (
      this.category.value == null &&
      this.dataSource.value == null &&
      !this.searchString.value
    );
  }

  onFormSubmit(e: FormDataEvent) {
    e.preventDefault();
    this.fetchList();
  }

  fetchList(e?: any) {
    e?.preventDefault();

    if (this.searchString.getRawValue()?.trim().length) {
      this.router.navigate(['reports'], {
        queryParams: {
          searchString: this.searchString.getRawValue()?.trim(),
        },
      });
    } else {
      this.router.navigate(['reports']);
    }
    this.search$.next(1);
  }

  goToReport(element: Report) {
    let route = this.reportService.openReport(element);
    this.router.navigate(route);
  }

  clearFilter() {
    this.category.setValue('');
    this.dataSource.setValue('');
    this.searchString.setValue('');
    this.records$.next([]);
    this.router.navigate(['reports']);
  }

 filterRecords(res:any,searchString:string){
   if(res?.length && searchString){
     res = res?.filter((item:any)=> item.name.toLowerCase().includes(searchString.toLowerCase()))
    this.records$.next(res);
  }else{
      this.records$.next([]);
  }
 }
 
  searchByString(searchString: string) {
    if (this.category?.value) {
      this.subscriptions.add(
        findReportsByStringAndFilters$(searchString, {
          domain: this.category.value,
        }).subscribe((res) => {
          this.filterRecords(res,searchString)
        })
      );
    } else {
      this.subscriptions.add(
        findReportsByString$(searchString).subscribe((res) => {
          this.filterRecords(res,searchString)
        })
      );
    }
  }

  metricsText(metrics: string[]) {
    if (!metrics.length) {
      return '-';
    }
    return metrics.join(', ');
  }
}
