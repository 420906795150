import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AreasChart } from 'src/app/charts/area/area.model';
import { Highlight } from 'src/app/models/reports';

@Component({
  selector: 'giq-area-highlight',
  templateUrl: 'area-highlight.component.html',
  styleUrls: ['area-highlight.component.scss'],
})
export class AreaHighlightComponent implements OnInit, OnChanges {
  @Input() config: Highlight | undefined;
  constructor() {}

  ngOnInit() {}

  public info: AreasChart = {
    colors: [],
    data: [],
    names: {},
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null) {
      const newInfo = { ...this.info, ...this.config?.metadata };
      if (newInfo?.data != null) {
        newInfo.data.forEach((x:any) => (x.date = new Date(x.date)));
      }
      this.info = newInfo;
    }
  }
}
