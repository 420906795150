<div class="top-overlay-container">
  <span *ngIf="config.title != null" class="title">{{ config.title }}</span>
  <ng-container *ngIf="items$ | async as items">
    <ng-container *ngFor="let item of items">
      <div class="alert" (click)="alertClick(item)">
        <ng-container *ngIf="config.showRead">
          <span *ngIf="!item.read" class="dot"> </span>
          <span *ngIf="item.read" class="dot" style="background: none"> </span>
        </ng-container>
        <span class="icon" [ngClass]="item.importance">
          <span *ngIf="item.numberIcon != null" class="number-icon">
            {{ item.numberIcon }}
          </span>
          <mat-icon
            *ngIf="item.svgIcon != null || item.icon != null"
            [svgIcon]="item.svgIcon ?? ''"
          >
            {{ item.icon ?? "" }}
          </mat-icon>
        </span>
        <span class="message">
          <span
            class="message-title"
            [ngClass]="item.importance"
            *ngIf="item.title"
          >
            {{ item.title }}
          </span>
          <span class="content">{{ item.content }}</span>
        </span>
      </div>
    </ng-container>
  </ng-container>
  <div
    class="see-more"
    *ngIf="config.showMore && numberOfItems > 0"
    (click)="seeMore()"
  >
    <span> See more </span>
    <mat-icon>expand_more</mat-icon>
  </div>
  <div class="see-more" *ngIf="numberOfItems <= 0">
    <span> {{ config.noElements }} </span>
  </div>
</div>
