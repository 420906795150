<div class="notifications-wrapper">
  <mat-icon
    [matBadge]="notificationCount"
    [matBadgeHidden]="notificationCount > 0 ? false : true"
    matBadgeOverlap="true"
    giq-interaction
    (click)="openNotifications()"
    svgIcon="notification"
  ></mat-icon>

  <div class="spinnerContainer" *ngIf="loading">
    <div id="loading"></div>
  </div>
</div>
