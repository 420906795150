<mat-dialog-content class="mat-typography content main-container">
  <ngx-spinner [fullScreen]="false"></ngx-spinner>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="reportName">
        <th class="header-column align-left" mat-header-cell *matHeaderCellDef>
          Report Name
        </th>
        <td mat-cell *matCellDef="let element" class="column align-left">
          <span [innerHTML]="element.name | highlight : searchString"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="overview">
        <th class="header-column" mat-header-cell *matHeaderCellDef>
          Overview
        </th>
        <td mat-cell *matCellDef="let element" class="column align-left">
          <span
            [innerHTML]="element.overview | highlight : searchString"
          ></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="metrics">
        <th class="header-column" mat-header-cell *matHeaderCellDef>Metrics</th>
        <td mat-cell *matCellDef="let element" class="column align-left">
          <span
            [innerHTML]="element.metrics.join(', ') | highlight : searchString"
          ></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="header-column" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="column">
          <mat-icon class="material-icons-outlined icon-gray arrow">
            arrow_forward
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnTitles"></tr>
      <tr
        class="row"
        mat-row
        *matRowDef="let row; columns: columnTitles"
        (click)="goToReport(row)"
      ></tr>
    </table>

    <div class="footer-container">
      <span class="text-green" (click)="seeMoreClick()">See More</span>
    </div>
  </div>
</mat-dialog-content>
