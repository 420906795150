<giq-header *ngIf="authenticated$ | async"></giq-header>
<div class="main-section">
  <giq-menu
    class="side-menu"
    [mobile]="false"
    *ngIf="authenticated$ | async"
  ></giq-menu>
  <router-outlet *ngIf="!(loggingOut$ | async)"></router-outlet>
</div>

<div class="loggingout" *ngIf="loggingOut$ | async">
  <giq-loading class="loading"></giq-loading>
  <span>Logging you out. . . Do not close your browser...</span>
  <div class="styled-button" *ngIf="showForce" (click)="forceLogout()">
    Force Logout
  </div>
</div>
