import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportsService } from 'src/app/core/reports.service';
import { Report } from 'src/app/models/reports';

@Component({
  selector: 'giq-favorite-app',
  templateUrl: 'favorite-app.component.html',
  styleUrls: ['favorite-app.component.scss'],
})
export class FavoriteAppComponent implements OnInit {
  @Input() reportId: string = '';

  public report$: Observable<Report | null> | null = null;

  constructor(private reportsService: ReportsService) {}

  ngOnInit() {
    this.report$ = this.reportsService.getReport(this.reportId);
  }

  public setFavorite(report: Report, mouseEvent: MouseEvent): void {
    this.reportsService.SetFavorite(report!.id, !report!.favorite).subscribe();
    report!.favorite = !report!.favorite;
  }
}
