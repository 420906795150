<div class="menu-container">
  <div class="arrow" (click)="changeMenuState()">
    <div class="inner-arrow" [class.menu-open]="menuOpen">
      <mat-icon svgIcon="menu_arrow"> </mat-icon>
    </div>
  </div>
  <div
    class="menu"
    [ngClass]="{ open: menuOpen === true, close: menuOpen === false }"
  >
    <div class="icons">
      <div
        giq-interaction
        class="item"
        *ngFor="let m of menuList"
        [routerLink]="[m.route]"
        routerLinkActive="selected"
      >
        <ng-container *ngIf="m.icon != null">
          <mat-icon
            [matTooltip]="m.name"
            matTooltipPosition="right"
            [matTooltipDisabled]="menuOpen"
            matTooltipClass="blue-tooltip"
            [svgIcon]="m.icon!"
          >
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="m.cssIcon != null">
          <mat-icon
            [matTooltip]="m.name"
            matTooltipPosition="right"
            [matTooltipDisabled]="menuOpen"
            matTooltipClass="blue-tooltip"
          >
            {{ m.cssIcon }}
          </mat-icon>
        </ng-container>
      </div>
    </div>
    <div class="texts">
      <span
        giq-interaction
        *ngFor="let m of menuList"
        class="text"
        [routerLink]="[m.route]"
        routerLinkActive="selected"
        (click)="close()"
      >
        {{ m.name }}
      </span>
    </div>
  </div>
</div>
