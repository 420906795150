import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ReportProblemService {
  constructor(private httpClient: HttpClient) {}

  public sendReport(payload: {
    id: string;
    type: string;
    description: string;
    comments: string;
  }): Observable<any> {
    const url = `${environment.apiUrl}/configs/problem`;
    return this.httpClient.post<any>(url, payload);
  }
}
