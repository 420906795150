export interface LineOverBarsMetadata {
    subtitle: string;
    data: LineOverBarHighlightElement[];
    timeScale: TimeScale;
    division:string;
    colors: string[];
    display:Display;
    lineName: string;
    barName: string;
  }

  
  export interface LineOverBarHighlightElement {
    date: string;
    barValue: number;
    lineValue : number;
  }

  export enum TimeScale {
    Yearly = 'Yearly',
    Quarterly = 'Quarterly',
    Monthly = 'Monthly',
    Weekly = 'Weekly',
    Text = 'Text',
    MonthNumbers = 'MonthNumbers',
    YrQtr = 'YrQtr'
  }

  export enum Display {
    Amount = 'Amount',
    MaxAmount = 'MaxAmount',
    Percentage = 'Percentage',
    MaxPercentage = 'MaxPercentage',
    None='None'
  }