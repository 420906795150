import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ComplexDonutMetadata } from 'src/app/charts/complex-donut/complex-donut-chart.metadata';
import { Highlight } from 'src/app/models/reports';

@Component({
  selector: 'giq-complex-donut-highlight',
  templateUrl: 'complex-donut-highlight.component.html',
  styleUrls: ['complex-donut-highlight.component.scss'],
})
export class ComplexDonutHighlightComponent implements OnChanges {
  @Input() config: Highlight | undefined;
  constructor() {}

  public info: ComplexDonutMetadata = {
    data: [],
    subtitle: '',
    title: '',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null) {
      const newInfo = { ...this.info, ...this.config?.metadata };
      newInfo.title = this.config?.reportName ?? '';
      this.info = newInfo;
    }
  }
}
