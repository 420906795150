import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Highlight } from 'src/app/models/reports';
import {
  BarsHighlightElement,
  BarsHighlightGroup,
  BarsHighlightInternalElement,
  BarsHighlightMetadata,
} from './bars-highlight.metadata';

import * as d3 from 'd3';

@Component({
  selector: 'giq-bars-highlight',
  templateUrl: 'bars-highlight.component.html',
  styleUrls: ['bars-highlight.component.scss'],
})
export class BarsHighlightComponent implements OnChanges, AfterViewInit {
  @Input() config: Highlight | undefined;
  public id: string = 'bars-' + Math.ceil(Math.random() * 100000);
  public selectedType: BarsHighlightGroup = BarsHighlightGroup.Year;
  public BarsHighlightGroup = BarsHighlightGroup;

  private metadata: BarsHighlightMetadata = {
    data: [
      { date: new Date('2022-4-1'), amount: 960976 },
      { date: new Date('2022-4-2'), amount: 12331 },
      { date: new Date('2022-4-3'), amount: 807866 },
      { date: new Date('2022-4-4'), amount: 599957 },
      { date: new Date('2022-4-5'), amount: 571807 },
      { date: new Date('2022-4-6'), amount: 486913 },
      { date: new Date('2022-4-7'), amount: 952526 },
      { date: new Date('2022-4-8'), amount: 229745 },
      { date: new Date('2022-4-9'), amount: 599615 },
      { date: new Date('2022-4-10'), amount: 992153 },
      { date: new Date('2022-4-11'), amount: 190273 },
      { date: new Date('2022-4-12'), amount: 310844 },
      { date: new Date('2022-4-13'), amount: 915822 },
      { date: new Date('2022-4-14'), amount: 707956 },
      { date: new Date('2022-4-15'), amount: 691631 },
      { date: new Date('2022-4-16'), amount: 250989 },
      { date: new Date('2022-4-17'), amount: 231099 },
      { date: new Date('2022-4-18'), amount: 261558 },
      { date: new Date('2022-4-19'), amount: 97965 },
      { date: new Date('2022-4-20'), amount: 854134 },
      { date: new Date('2022-4-21'), amount: 205463 },
      { date: new Date('2022-4-22'), amount: 811216 },
      { date: new Date('2022-4-23'), amount: 741796 },
      { date: new Date('2022-4-24'), amount: 671524 },
      { date: new Date('2022-4-25'), amount: 803081 },
      { date: new Date('2022-4-26'), amount: 966939 },
      { date: new Date('2022-4-27'), amount: 822953 },
      { date: new Date('2022-4-28'), amount: 883475 },
      { date: new Date('2022-4-29'), amount: 300211 },
      { date: new Date('2022-4-30'), amount: 395657 },
      { date: new Date('2022-5-1'), amount: 830530 },
      { date: new Date('2022-5-2'), amount: 877651 },
      { date: new Date('2022-5-3'), amount: 575373 },
      { date: new Date('2022-5-4'), amount: 622969 },
      { date: new Date('2022-5-5'), amount: 233958 },
      { date: new Date('2022-5-6'), amount: 207910 },
      { date: new Date('2022-5-7'), amount: 464174 },
      { date: new Date('2022-5-8'), amount: 737077 },
      { date: new Date('2022-5-9'), amount: 834891 },
      { date: new Date('2022-5-10'), amount: 555215 },
      { date: new Date('2022-5-11'), amount: 509517 },
      { date: new Date('2022-5-12'), amount: 852885 },
      { date: new Date('2022-5-13'), amount: 356442 },
      { date: new Date('2022-5-14'), amount: 409738 },
      { date: new Date('2022-5-15'), amount: 703275 },
      { date: new Date('2022-5-16'), amount: 440130 },
      { date: new Date('2022-5-17'), amount: 103189 },
      { date: new Date('2022-5-18'), amount: 166688 },
      { date: new Date('2022-5-19'), amount: 48320 },
      { date: new Date('2022-5-20'), amount: 60264 },
      { date: new Date('2022-5-21'), amount: 297846 },
      { date: new Date('2022-5-22'), amount: 308851 },
      { date: new Date('2022-5-23'), amount: 24286 },
      { date: new Date('2022-5-24'), amount: 458475 },
      { date: new Date('2022-5-25'), amount: 713857 },
      { date: new Date('2022-5-26'), amount: 203943 },
      { date: new Date('2022-5-27'), amount: 747365 },
      { date: new Date('2022-5-28'), amount: 750149 },
      { date: new Date('2022-5-29'), amount: 869491 },
      { date: new Date('2022-5-30'), amount: 318898 },
      { date: new Date('2022-5-31'), amount: 600872 },
      { date: new Date('2022-6-1'), amount: 878588 },
      { date: new Date('2022-6-2'), amount: 150123 },
      { date: new Date('2022-6-3'), amount: 874249 },
      { date: new Date('2022-6-4'), amount: 358930 },
      { date: new Date('2022-6-5'), amount: 622595 },
      { date: new Date('2022-6-6'), amount: 917453 },
      { date: new Date('2022-6-7'), amount: 16074 },
      { date: new Date('2022-6-8'), amount: 281280 },
      { date: new Date('2022-6-9'), amount: 91898 },
      { date: new Date('2022-6-10'), amount: 191532 },
      { date: new Date('2022-6-11'), amount: 825688 },
      { date: new Date('2022-6-12'), amount: 333504 },
      { date: new Date('2022-6-13'), amount: 477440 },
      { date: new Date('2022-6-14'), amount: 175453 },
      { date: new Date('2022-6-15'), amount: 956120 },
      { date: new Date('2022-6-16'), amount: 966447 },
      { date: new Date('2022-6-17'), amount: 17994 },
      { date: new Date('2022-6-18'), amount: 183615 },
      { date: new Date('2022-6-19'), amount: 980851 },
      { date: new Date('2022-6-20'), amount: 659701 },
      { date: new Date('2022-6-21'), amount: 108097 },
      { date: new Date('2022-6-22'), amount: 586540 },
      { date: new Date('2022-6-23'), amount: 838042 },
      { date: new Date('2022-6-24'), amount: 605291 },
      { date: new Date('2022-6-25'), amount: 528505 },
      { date: new Date('2022-6-26'), amount: 240749 },
      { date: new Date('2022-6-27'), amount: 308222 },
      { date: new Date('2022-6-28'), amount: 864667 },
      { date: new Date('2022-6-29'), amount: 277186 },
      { date: new Date('2022-6-30'), amount: 849782 },
      { date: new Date('2022-7-1'), amount: 785815 },
      { date: new Date('2022-7-2'), amount: 632630 },
      { date: new Date('2022-7-3'), amount: 889967 },
      { date: new Date('2022-7-4'), amount: 598308 },
      { date: new Date('2022-7-5'), amount: 546989 },
      { date: new Date('2022-7-6'), amount: 595505 },
      { date: new Date('2022-7-7'), amount: 590055 },
      { date: new Date('2022-7-8'), amount: 577883 },
      { date: new Date('2022-7-9'), amount: 509311 },
      { date: new Date('2022-7-10'), amount: 31707 },
      { date: new Date('2022-7-11'), amount: 969262 },
      { date: new Date('2022-7-12'), amount: 733494 },
      { date: new Date('2022-7-13'), amount: 675973 },
      { date: new Date('2022-7-14'), amount: 699232 },
      { date: new Date('2022-7-15'), amount: 572761 },
      { date: new Date('2022-7-16'), amount: 728104 },
      { date: new Date('2022-7-17'), amount: 993914 },
      { date: new Date('2022-7-18'), amount: 535605 },
      { date: new Date('2022-7-19'), amount: 100221 },
      { date: new Date('2022-7-20'), amount: 489464 },
      { date: new Date('2022-7-21'), amount: 474733 },
      { date: new Date('2022-7-22'), amount: 28021 },
      { date: new Date('2022-7-23'), amount: 609313 },
      { date: new Date('2022-7-24'), amount: 605139 },
      { date: new Date('2022-7-25'), amount: 249620 },
      { date: new Date('2022-7-26'), amount: 74511 },
      { date: new Date('2022-7-27'), amount: 395473 },
      { date: new Date('2022-7-28'), amount: 1908 },
      { date: new Date('2022-7-29'), amount: 727280 },
      { date: new Date('2022-7-30'), amount: 651991 },
      { date: new Date('2022-7-31'), amount: 794498 },
      { date: new Date('2022-8-1'), amount: 967180 },
      { date: new Date('2022-8-2'), amount: 777595 },
      { date: new Date('2022-8-3'), amount: 41700 },
      { date: new Date('2022-8-4'), amount: 866906 },
      { date: new Date('2022-8-5'), amount: 427687 },
      { date: new Date('2022-8-6'), amount: 617633 },
      { date: new Date('2022-8-7'), amount: 151611 },
      { date: new Date('2022-8-8'), amount: 940655 },
      { date: new Date('2022-8-9'), amount: 758983 },
      { date: new Date('2022-8-10'), amount: 755526 },
      { date: new Date('2022-8-11'), amount: 504664 },
      { date: new Date('2022-8-12'), amount: 378334 },
      { date: new Date('2022-8-13'), amount: 809957 },
      { date: new Date('2022-8-14'), amount: 36320 },
      { date: new Date('2022-8-15'), amount: 63900 },
      { date: new Date('2022-8-16'), amount: 535264 },
      { date: new Date('2022-8-17'), amount: 624836 },
      { date: new Date('2022-8-18'), amount: 867541 },
      { date: new Date('2022-8-19'), amount: 725957 },
      { date: new Date('2022-8-20'), amount: 657128 },
      { date: new Date('2022-8-21'), amount: 741787 },
      { date: new Date('2022-8-22'), amount: 274202 },
      { date: new Date('2022-8-23'), amount: 314206 },
      { date: new Date('2022-8-24'), amount: 788778 },
      { date: new Date('2022-8-25'), amount: 47319 },
      { date: new Date('2022-8-26'), amount: 248604 },
      { date: new Date('2022-8-27'), amount: 450190 },
      { date: new Date('2022-8-28'), amount: 625552 },
      { date: new Date('2022-8-29'), amount: 183343 },
      { date: new Date('2022-8-30'), amount: 270141 },
      { date: new Date('2022-8-31'), amount: 806143 },
      { date: new Date('2022-9-1'), amount: 557291 },
      { date: new Date('2022-9-2'), amount: 716606 },
      { date: new Date('2022-9-3'), amount: 472133 },
      { date: new Date('2022-9-4'), amount: 104326 },
      { date: new Date('2022-9-5'), amount: 653130 },
      { date: new Date('2022-9-6'), amount: 275170 },
      { date: new Date('2022-9-7'), amount: 431624 },
      { date: new Date('2022-9-8'), amount: 667440 },
      { date: new Date('2022-9-9'), amount: 988991 },
      { date: new Date('2022-9-10'), amount: 634807 },
      { date: new Date('2022-9-11'), amount: 539497 },
      { date: new Date('2022-9-12'), amount: 147455 },
      { date: new Date('2022-9-13'), amount: 677157 },
      { date: new Date('2022-9-14'), amount: 138329 },
      { date: new Date('2022-9-15'), amount: 519643 },
      { date: new Date('2022-9-16'), amount: 603319 },
      { date: new Date('2022-9-17'), amount: 174116 },
      { date: new Date('2022-9-18'), amount: 869775 },
      { date: new Date('2022-9-19'), amount: 46046 },
      { date: new Date('2022-9-20'), amount: 77110 },
      { date: new Date('2022-9-21'), amount: 771535 },
      { date: new Date('2022-9-22'), amount: 425983 },
      { date: new Date('2022-9-23'), amount: 378757 },
      { date: new Date('2022-9-24'), amount: 498586 },
      { date: new Date('2022-9-25'), amount: 899442 },
      { date: new Date('2022-9-26'), amount: 112370 },
      { date: new Date('2022-9-27'), amount: 246227 },
      { date: new Date('2022-9-28'), amount: 926218 },
      { date: new Date('2022-9-29'), amount: 62518 },
      { date: new Date('2022-9-30'), amount: 706822 },
      { date: new Date('2022-10-1'), amount: 27167 },
      { date: new Date('2022-10-2'), amount: 344836 },
      { date: new Date('2022-10-3'), amount: 856660 },
      { date: new Date('2022-10-4'), amount: 325042 },
      { date: new Date('2022-10-5'), amount: 233009 },
      { date: new Date('2022-10-6'), amount: 468083 },
      { date: new Date('2022-10-7'), amount: 835132 },
      { date: new Date('2022-10-8'), amount: 871280 },
      { date: new Date('2022-10-9'), amount: 716881 },
      { date: new Date('2022-10-10'), amount: 274277 },
      { date: new Date('2022-10-11'), amount: 592464 },
      { date: new Date('2022-10-12'), amount: 355970 },
      { date: new Date('2022-10-13'), amount: 394900 },
      { date: new Date('2022-10-14'), amount: 518316 },
      { date: new Date('2022-10-15'), amount: 512674 },
      { date: new Date('2022-10-16'), amount: 158884 },
      { date: new Date('2022-10-17'), amount: 595918 },
      { date: new Date('2022-10-18'), amount: 708816 },
      { date: new Date('2022-10-19'), amount: 374882 },
      { date: new Date('2022-10-20'), amount: 702141 },
      { date: new Date('2022-10-21'), amount: 336227 },
      { date: new Date('2022-10-22'), amount: 850126 },
      { date: new Date('2022-10-23'), amount: 601286 },
      { date: new Date('2022-10-24'), amount: 729467 },
      { date: new Date('2022-10-25'), amount: 209499 },
      { date: new Date('2022-10-26'), amount: 646132 },
      { date: new Date('2022-10-27'), amount: 712811 },
      { date: new Date('2022-10-28'), amount: 975960 },
      { date: new Date('2022-10-29'), amount: 915322 },
      { date: new Date('2022-10-30'), amount: 957101 },
      { date: new Date('2022-10-31'), amount: 56569 },
      { date: new Date('2022-11-1'), amount: 622133 },
      { date: new Date('2022-11-2'), amount: 874792 },
      { date: new Date('2022-11-3'), amount: 871101 },
      { date: new Date('2022-11-4'), amount: 546089 },
      { date: new Date('2022-11-5'), amount: 421433 },
      { date: new Date('2022-11-6'), amount: 349543 },
      { date: new Date('2022-11-7'), amount: 701176 },
      { date: new Date('2022-11-8'), amount: 932795 },
      { date: new Date('2022-11-9'), amount: 1620 },
      { date: new Date('2022-11-10'), amount: 58146 },
      { date: new Date('2022-11-11'), amount: 846865 },
      { date: new Date('2022-11-12'), amount: 444771 },
      { date: new Date('2022-11-13'), amount: 257197 },
      { date: new Date('2022-11-14'), amount: 274444 },
      { date: new Date('2022-11-15'), amount: 666595 },
      { date: new Date('2022-11-16'), amount: 482537 },
      { date: new Date('2022-11-17'), amount: 927521 },
      { date: new Date('2022-11-18'), amount: 381865 },
      { date: new Date('2022-11-19'), amount: 859768 },
      { date: new Date('2022-11-20'), amount: 530118 },
      { date: new Date('2022-11-21'), amount: 379596 },
      { date: new Date('2022-11-22'), amount: 689993 },
      { date: new Date('2022-11-23'), amount: 743303 },
      { date: new Date('2022-11-24'), amount: 75925 },
      { date: new Date('2022-11-25'), amount: 581055 },
      { date: new Date('2022-11-26'), amount: 648792 },
      { date: new Date('2022-11-27'), amount: 215114 },
      { date: new Date('2022-11-28'), amount: 492448 },
      { date: new Date('2022-11-29'), amount: 67293 },
      { date: new Date('2022-11-30'), amount: 299330 },
      { date: new Date('2022-12-1'), amount: 120071 },
      { date: new Date('2022-12-2'), amount: 787213 },
      { date: new Date('2022-12-3'), amount: 55853 },
      { date: new Date('2022-12-4'), amount: 963066 },
      { date: new Date('2022-12-5'), amount: 736411 },
      { date: new Date('2022-12-6'), amount: 512427 },
      { date: new Date('2022-12-7'), amount: 107895 },
      { date: new Date('2022-12-8'), amount: 940586 },
      { date: new Date('2022-12-9'), amount: 870178 },
      { date: new Date('2022-12-10'), amount: 452525 },
      { date: new Date('2022-12-11'), amount: 30003 },
      { date: new Date('2022-12-12'), amount: 777012 },
      { date: new Date('2022-12-13'), amount: 662133 },
      { date: new Date('2022-12-14'), amount: 951770 },
      { date: new Date('2022-12-15'), amount: 612340 },
      { date: new Date('2022-12-16'), amount: 198848 },
      { date: new Date('2022-12-17'), amount: 123823 },
      { date: new Date('2022-12-18'), amount: 193614 },
      { date: new Date('2022-12-19'), amount: 632895 },
      { date: new Date('2022-12-20'), amount: 314455 },
      { date: new Date('2022-12-21'), amount: 845188 },
      { date: new Date('2022-12-22'), amount: 768741 },
      { date: new Date('2022-12-23'), amount: 716870 },
      { date: new Date('2022-12-24'), amount: 418425 },
      { date: new Date('2022-12-25'), amount: 66953 },
      { date: new Date('2022-12-26'), amount: 724878 },
      { date: new Date('2022-12-27'), amount: 815132 },
      { date: new Date('2022-12-28'), amount: 652068 },
      { date: new Date('2022-12-29'), amount: 653632 },
      { date: new Date('2022-12-30'), amount: 237643 },
      { date: new Date('2022-12-31'), amount: 886836 },
      { date: new Date('2023-1-1'), amount: 881730 },
      { date: new Date('2023-1-2'), amount: 210070 },
      { date: new Date('2023-1-3'), amount: 956124 },
      { date: new Date('2023-1-4'), amount: 719727 },
      { date: new Date('2023-1-5'), amount: 852132 },
      { date: new Date('2023-1-6'), amount: 770142 },
      { date: new Date('2023-1-7'), amount: 845610 },
      { date: new Date('2023-1-8'), amount: 363948 },
      { date: new Date('2023-1-9'), amount: 100365 },
      { date: new Date('2023-1-10'), amount: 192555 },
      { date: new Date('2023-1-11'), amount: 46915 },
      { date: new Date('2023-1-12'), amount: 756645 },
      { date: new Date('2023-1-13'), amount: 245410 },
      { date: new Date('2023-1-14'), amount: 608442 },
      { date: new Date('2023-1-15'), amount: 481733 },
      { date: new Date('2023-1-16'), amount: 885131 },
      { date: new Date('2023-1-17'), amount: 39997 },
      { date: new Date('2023-1-18'), amount: 201652 },
      { date: new Date('2023-1-19'), amount: 202464 },
      { date: new Date('2023-1-20'), amount: 940794 },
      { date: new Date('2023-1-21'), amount: 478623 },
      { date: new Date('2023-1-22'), amount: 493901 },
      { date: new Date('2023-1-23'), amount: 257012 },
      { date: new Date('2023-1-24'), amount: 562431 },
      { date: new Date('2023-1-25'), amount: 579836 },
      { date: new Date('2023-1-26'), amount: 659778 },
      { date: new Date('2023-1-27'), amount: 428794 },
      { date: new Date('2023-1-28'), amount: 480658 },
      { date: new Date('2023-1-29'), amount: 412902 },
      { date: new Date('2023-1-30'), amount: 821172 },
      { date: new Date('2023-1-31'), amount: 360198 },
      { date: new Date('2023-2-1'), amount: 342137 },
      { date: new Date('2023-2-2'), amount: 972065 },
      { date: new Date('2023-2-3'), amount: 6674 },
      { date: new Date('2023-2-4'), amount: 210390 },
      { date: new Date('2023-2-5'), amount: 477737 },
      { date: new Date('2023-2-6'), amount: 846529 },
      { date: new Date('2023-2-7'), amount: 477563 },
      { date: new Date('2023-2-8'), amount: 43292 },
      { date: new Date('2023-2-9'), amount: 229573 },
      { date: new Date('2023-2-10'), amount: 741167 },
      { date: new Date('2023-2-11'), amount: 545940 },
      { date: new Date('2023-2-12'), amount: 394964 },
      { date: new Date('2023-2-13'), amount: 264975 },
      { date: new Date('2023-2-14'), amount: 817422 },
      { date: new Date('2023-2-15'), amount: 482320 },
      { date: new Date('2023-2-16'), amount: 248296 },
      { date: new Date('2023-2-17'), amount: 8826 },
      { date: new Date('2023-2-18'), amount: 608512 },
      { date: new Date('2023-2-19'), amount: 493104 },
      { date: new Date('2023-2-20'), amount: 507181 },
      { date: new Date('2023-2-21'), amount: 592695 },
      { date: new Date('2023-2-22'), amount: 5485 },
      { date: new Date('2023-2-23'), amount: 111938 },
      { date: new Date('2023-2-24'), amount: 928654 },
      { date: new Date('2023-2-25'), amount: 99258 },
      { date: new Date('2023-2-26'), amount: 988968 },
      { date: new Date('2023-2-27'), amount: 874334 },
      { date: new Date('2023-2-28'), amount: 810830 },
      { date: new Date('2023-3-1'), amount: 631634 },
      { date: new Date('2023-3-2'), amount: 367756 },
      { date: new Date('2023-3-3'), amount: 545360 },
      { date: new Date('2023-3-4'), amount: 160257 },
      { date: new Date('2023-3-5'), amount: 832778 },
      { date: new Date('2023-3-6'), amount: 550867 },
      { date: new Date('2023-3-7'), amount: 682259 },
      { date: new Date('2023-3-8'), amount: 244643 },
      { date: new Date('2023-3-9'), amount: 9747 },
      { date: new Date('2023-3-10'), amount: 883452 },
      { date: new Date('2023-3-11'), amount: 245590 },
      { date: new Date('2023-3-12'), amount: 734144 },
      { date: new Date('2023-3-13'), amount: 430976 },
      { date: new Date('2023-3-14'), amount: 642240 },
      { date: new Date('2023-3-15'), amount: 310971 },
      { date: new Date('2023-3-16'), amount: 767986 },
      { date: new Date('2023-3-17'), amount: 754103 },
      { date: new Date('2023-3-18'), amount: 773299 },
      { date: new Date('2023-3-19'), amount: 758328 },
      { date: new Date('2023-3-20'), amount: 309708 },
      { date: new Date('2023-3-21'), amount: 156619 },
      { date: new Date('2023-3-22'), amount: 360273 },
      { date: new Date('2023-3-23'), amount: 518034 },
      { date: new Date('2023-3-24'), amount: 57737 },
      { date: new Date('2023-3-25'), amount: 10297 },
      { date: new Date('2023-3-26'), amount: 7944 },
      { date: new Date('2023-3-27'), amount: 928525 },
      { date: new Date('2023-3-28'), amount: 364138 },
      { date: new Date('2023-3-29'), amount: 481053 },
      { date: new Date('2023-3-30'), amount: 701672 },
      { date: new Date('2023-3-31'), amount: 803240 },
      { date: new Date('2023-4-1'), amount: 934722 },
      { date: new Date('2023-4-2'), amount: 801729 },
      { date: new Date('2023-4-3'), amount: 328605 },
      { date: new Date('2023-4-4'), amount: 524683 },
      { date: new Date('2023-4-5'), amount: 565563 },
      { date: new Date('2023-4-6'), amount: 657932 },
      { date: new Date('2023-4-7'), amount: 44666 },
      { date: new Date('2023-4-8'), amount: 18973 },
      { date: new Date('2023-4-9'), amount: 153384 },
      { date: new Date('2023-4-10'), amount: 657740 },
      { date: new Date('2023-4-11'), amount: 834039 },
      { date: new Date('2023-4-12'), amount: 237737 },
      { date: new Date('2023-4-13'), amount: 557413 },
      { date: new Date('2023-4-14'), amount: 12339 },
      { date: new Date('2023-4-15'), amount: 454702 },
      { date: new Date('2023-4-16'), amount: 268434 },
      { date: new Date('2023-4-17'), amount: 577096 },
      { date: new Date('2023-4-18'), amount: 745677 },
      { date: new Date('2023-4-19'), amount: 378077 },
      { date: new Date('2023-4-20'), amount: 528232 },
      { date: new Date('2023-4-21'), amount: 185764 },
      { date: new Date('2023-4-22'), amount: 566723 },
      { date: new Date('2023-4-23'), amount: 900036 },
      { date: new Date('2023-4-24'), amount: 927317 },
      { date: new Date('2023-4-25'), amount: 697488 },
      { date: new Date('2023-4-26'), amount: 61196 },
      { date: new Date('2023-4-27'), amount: 692850 },
      { date: new Date('2023-4-28'), amount: 798959 },
      { date: new Date('2023-4-29'), amount: 128099 },
      { date: new Date('2023-4-30'), amount: 270214 },
      { date: new Date('2023-5-1'), amount: 386898 },
      { date: new Date('2023-5-2'), amount: 570755 },
      { date: new Date('2023-5-3'), amount: 919340 },
      { date: new Date('2023-5-4'), amount: 398379 },
      { date: new Date('2023-5-5'), amount: 324838 },
      { date: new Date('2023-5-6'), amount: 234567 },
      { date: new Date('2023-5-7'), amount: 730561 },
      { date: new Date('2023-5-8'), amount: 546123 },
      { date: new Date('2023-5-9'), amount: 555219 },
      { date: new Date('2023-5-10'), amount: 887067 },
      { date: new Date('2023-5-11'), amount: 964704 },
      { date: new Date('2023-5-12'), amount: 309866 },
      { date: new Date('2023-5-13'), amount: 453736 },
      { date: new Date('2023-5-14'), amount: 126798 },
      { date: new Date('2023-5-15'), amount: 732613 },
      { date: new Date('2023-5-16'), amount: 564385 },
      { date: new Date('2023-5-17'), amount: 314383 },
      { date: new Date('2023-5-18'), amount: 183063 },
      { date: new Date('2023-5-19'), amount: 3767 },
      { date: new Date('2023-5-20'), amount: 336528 },
      { date: new Date('2023-5-21'), amount: 702203 },
      { date: new Date('2023-5-22'), amount: 576844 },
      { date: new Date('2023-5-23'), amount: 647305 },
      { date: new Date('2023-5-24'), amount: 90048 },
      { date: new Date('2023-5-25'), amount: 667549 },
      { date: new Date('2023-5-26'), amount: 918665 },
      { date: new Date('2023-5-27'), amount: 461053 },
      { date: new Date('2023-5-28'), amount: 11790 },
      { date: new Date('2023-5-29'), amount: 161363 },
      { date: new Date('2023-5-30'), amount: 994477 },
      { date: new Date('2023-5-31'), amount: 773293 },
      { date: new Date('2023-6-1'), amount: 977794 },
      { date: new Date('2023-6-2'), amount: 311711 },
      { date: new Date('2023-6-3'), amount: 181524 },
      { date: new Date('2023-6-4'), amount: 1514 },
      { date: new Date('2023-6-5'), amount: 550037 },
      { date: new Date('2023-6-6'), amount: 542475 },
      { date: new Date('2023-6-7'), amount: 148336 },
      { date: new Date('2023-6-8'), amount: 458922 },
      { date: new Date('2023-6-9'), amount: 228180 },
      { date: new Date('2023-6-10'), amount: 611517 },
      { date: new Date('2023-6-11'), amount: 854321 },
      { date: new Date('2023-6-12'), amount: 798438 },
      { date: new Date('2023-6-13'), amount: 24475 },
      { date: new Date('2023-6-14'), amount: 468221 },
      { date: new Date('2023-6-15'), amount: 222923 },
      { date: new Date('2023-6-16'), amount: 936566 },
      { date: new Date('2023-6-17'), amount: 440422 },
      { date: new Date('2023-6-18'), amount: 482187 },
      { date: new Date('2023-6-19'), amount: 810716 },
      { date: new Date('2023-6-20'), amount: 596526 },
      { date: new Date('2023-6-21'), amount: 708199 },
      { date: new Date('2023-6-22'), amount: 568591 },
      { date: new Date('2023-6-23'), amount: 768270 },
      { date: new Date('2023-6-24'), amount: 190446 },
      { date: new Date('2023-6-25'), amount: 553587 },
      { date: new Date('2023-6-26'), amount: 843507 },
      { date: new Date('2023-6-27'), amount: 164844 },
      { date: new Date('2023-6-28'), amount: 683332 },
      { date: new Date('2023-6-29'), amount: 496074 },
      { date: new Date('2023-6-30'), amount: 174623 },
      { date: new Date('2023-7-1'), amount: 656482 },
      { date: new Date('2023-7-2'), amount: 777449 },
      { date: new Date('2023-7-3'), amount: 608066 },
      { date: new Date('2023-7-4'), amount: 171331 },
      { date: new Date('2023-7-5'), amount: 738997 },
      { date: new Date('2023-7-6'), amount: 767866 },
      { date: new Date('2023-7-7'), amount: 743713 },
      { date: new Date('2023-7-8'), amount: 428707 },
      { date: new Date('2023-7-9'), amount: 916184 },
      { date: new Date('2023-7-10'), amount: 238643 },
      { date: new Date('2023-7-11'), amount: 252112 },
      { date: new Date('2023-7-12'), amount: 75528 },
      { date: new Date('2023-7-13'), amount: 636081 },
      { date: new Date('2023-7-14'), amount: 72679 },
      { date: new Date('2023-7-15'), amount: 593263 },
      { date: new Date('2023-7-16'), amount: 952964 },
      { date: new Date('2023-7-17'), amount: 283874 },
      { date: new Date('2023-7-18'), amount: 940537 },
      { date: new Date('2023-7-19'), amount: 15362 },
      { date: new Date('2023-7-20'), amount: 757213 },
      { date: new Date('2023-7-21'), amount: 183643 },
      { date: new Date('2023-7-22'), amount: 673989 },
      { date: new Date('2023-7-23'), amount: 587175 },
      { date: new Date('2023-7-24'), amount: 79007 },
      { date: new Date('2023-7-25'), amount: 429918 },
      { date: new Date('2023-7-26'), amount: 212004 },
      { date: new Date('2023-7-27'), amount: 945916 },
      { date: new Date('2023-7-28'), amount: 835416 },
      { date: new Date('2023-7-29'), amount: 418231 },
      { date: new Date('2023-7-30'), amount: 973847 },
      { date: new Date('2023-7-31'), amount: 392107 },
      { date: new Date('2023-8-1'), amount: 458958 },
      { date: new Date('2023-8-2'), amount: 865356 },
      { date: new Date('2023-8-3'), amount: 783011 },
      { date: new Date('2023-8-4'), amount: 163730 },
      { date: new Date('2023-8-5'), amount: 349527 },
      { date: new Date('2023-8-6'), amount: 542980 },
      { date: new Date('2023-8-7'), amount: 193289 },
      { date: new Date('2023-8-8'), amount: 118729 },
      { date: new Date('2023-8-9'), amount: 862065 },
      { date: new Date('2023-8-10'), amount: 370794 },
      { date: new Date('2023-8-11'), amount: 976485 },
      { date: new Date('2023-8-12'), amount: 378482 },
      { date: new Date('2023-8-13'), amount: 480166 },
      { date: new Date('2023-8-14'), amount: 679320 },
      { date: new Date('2023-8-15'), amount: 131578 },
      { date: new Date('2023-8-16'), amount: 992241 },
      { date: new Date('2023-8-17'), amount: 964734 },
      { date: new Date('2023-8-18'), amount: 565879 },
      { date: new Date('2023-8-19'), amount: 223092 },
      { date: new Date('2023-8-20'), amount: 230460 },
      { date: new Date('2023-8-21'), amount: 102811 },
      { date: new Date('2023-8-22'), amount: 59388 },
      { date: new Date('2023-8-23'), amount: 638427 },
      { date: new Date('2023-8-24'), amount: 324557 },
      { date: new Date('2023-8-25'), amount: 210629 },
      { date: new Date('2023-8-26'), amount: 813332 },
      { date: new Date('2023-8-27'), amount: 795193 },
      { date: new Date('2023-8-28'), amount: 351702 },
      { date: new Date('2023-8-29'), amount: 777573 },
      { date: new Date('2023-8-30'), amount: 903579 },
      { date: new Date('2023-8-31'), amount: 132307 },
      { date: new Date('2023-9-1'), amount: 931766 },
      { date: new Date('2023-9-2'), amount: 967542 },
      { date: new Date('2023-9-3'), amount: 320474 },
      { date: new Date('2023-9-4'), amount: 983345 },
      { date: new Date('2023-9-5'), amount: 415752 },
      { date: new Date('2023-9-6'), amount: 372454 },
      { date: new Date('2023-9-7'), amount: 451317 },
      { date: new Date('2023-9-8'), amount: 467764 },
      { date: new Date('2023-9-9'), amount: 30265 },
      { date: new Date('2023-9-10'), amount: 489951 },
      { date: new Date('2023-9-11'), amount: 133352 },
      { date: new Date('2023-9-12'), amount: 677503 },
      { date: new Date('2023-9-13'), amount: 251093 },
      { date: new Date('2023-9-14'), amount: 747296 },
      { date: new Date('2023-9-15'), amount: 886555 },
      { date: new Date('2023-9-16'), amount: 961264 },
      { date: new Date('2023-9-17'), amount: 276300 },
      { date: new Date('2023-9-18'), amount: 438094 },
      { date: new Date('2023-9-19'), amount: 517627 },
      { date: new Date('2023-9-20'), amount: 978423 },
      { date: new Date('2023-9-21'), amount: 783596 },
      { date: new Date('2023-9-22'), amount: 3325 },
      { date: new Date('2023-9-23'), amount: 86842 },
      { date: new Date('2023-9-24'), amount: 274582 },
      { date: new Date('2023-9-25'), amount: 721052 },
      { date: new Date('2023-9-26'), amount: 398839 },
      { date: new Date('2023-9-27'), amount: 654783 },
      { date: new Date('2023-9-28'), amount: 501091 },
      { date: new Date('2023-9-29'), amount: 204499 },
      { date: new Date('2023-9-30'), amount: 235862 },
      { date: new Date('2023-10-1'), amount: 78760 },
      { date: new Date('2023-10-2'), amount: 881674 },
      { date: new Date('2023-10-3'), amount: 130869 },
      { date: new Date('2023-10-4'), amount: 428236 },
      { date: new Date('2023-10-5'), amount: 637233 },
      { date: new Date('2023-10-6'), amount: 964220 },
      { date: new Date('2023-10-7'), amount: 689726 },
      { date: new Date('2023-10-8'), amount: 605798 },
      { date: new Date('2023-10-9'), amount: 32336 },
      { date: new Date('2023-10-10'), amount: 769212 },
      { date: new Date('2023-10-11'), amount: 345649 },
      { date: new Date('2023-10-12'), amount: 426129 },
      { date: new Date('2023-10-13'), amount: 775619 },
      { date: new Date('2023-10-14'), amount: 348571 },
      { date: new Date('2023-10-15'), amount: 107945 },
      { date: new Date('2023-10-16'), amount: 560783 },
      { date: new Date('2023-10-17'), amount: 447691 },
      { date: new Date('2023-10-18'), amount: 637357 },
      { date: new Date('2023-10-19'), amount: 141130 },
      { date: new Date('2023-10-20'), amount: 122880 },
      { date: new Date('2023-10-21'), amount: 431588 },
      { date: new Date('2023-10-22'), amount: 594261 },
      { date: new Date('2023-10-23'), amount: 113124 },
      { date: new Date('2023-10-24'), amount: 997181 },
      { date: new Date('2023-10-25'), amount: 939983 },
      { date: new Date('2023-10-26'), amount: 263431 },
      { date: new Date('2023-10-27'), amount: 762642 },
      { date: new Date('2023-10-28'), amount: 107491 },
      { date: new Date('2023-10-29'), amount: 406584 },
      { date: new Date('2023-10-30'), amount: 803162 },
      { date: new Date('2023-10-31'), amount: 684996 },
      { date: new Date('2023-11-1'), amount: 877313 },
      { date: new Date('2023-11-2'), amount: 921037 },
      { date: new Date('2023-11-3'), amount: 239838 },
      { date: new Date('2023-11-4'), amount: 914937 },
      { date: new Date('2023-11-5'), amount: 553057 },
      { date: new Date('2023-11-6'), amount: 676463 },
      { date: new Date('2023-11-7'), amount: 470427 },
      { date: new Date('2023-11-8'), amount: 404822 },
      { date: new Date('2023-11-9'), amount: 329851 },
      { date: new Date('2023-11-10'), amount: 911405 },
      { date: new Date('2023-11-11'), amount: 272098 },
      { date: new Date('2023-11-12'), amount: 103168 },
      { date: new Date('2023-11-13'), amount: 36550 },
      { date: new Date('2023-11-14'), amount: 68146 },
      { date: new Date('2023-11-15'), amount: 276277 },
      { date: new Date('2023-11-16'), amount: 280288 },
      { date: new Date('2023-11-17'), amount: 398278 },
      { date: new Date('2023-11-18'), amount: 537760 },
      { date: new Date('2023-11-19'), amount: 142234 },
      { date: new Date('2023-11-20'), amount: 101599 },
      { date: new Date('2023-11-21'), amount: 296882 },
      { date: new Date('2023-11-22'), amount: 259199 },
      { date: new Date('2023-11-23'), amount: 369368 },
      { date: new Date('2023-11-24'), amount: 349912 },
      { date: new Date('2023-11-25'), amount: 160954 },
      { date: new Date('2023-11-26'), amount: 539355 },
      { date: new Date('2023-11-27'), amount: 474506 },
      { date: new Date('2023-11-28'), amount: 718839 },
      { date: new Date('2023-11-29'), amount: 62496 },
      { date: new Date('2023-11-30'), amount: 100054 },
      { date: new Date('2023-12-1'), amount: 995656 },
      { date: new Date('2023-12-2'), amount: 649772 },
      { date: new Date('2023-12-3'), amount: 868482 },
      { date: new Date('2023-12-4'), amount: 21771 },
      { date: new Date('2023-12-5'), amount: 240783 },
      { date: new Date('2023-12-6'), amount: 948471 },
      { date: new Date('2023-12-7'), amount: 151855 },
      { date: new Date('2023-12-8'), amount: 92483 },
      { date: new Date('2023-12-9'), amount: 671906 },
      { date: new Date('2023-12-10'), amount: 356340 },
      { date: new Date('2023-12-11'), amount: 65606 },
      { date: new Date('2023-12-12'), amount: 212558 },
      { date: new Date('2023-12-13'), amount: 15940 },
      { date: new Date('2023-12-14'), amount: 600524 },
      { date: new Date('2023-12-15'), amount: 485537 },
      { date: new Date('2023-12-16'), amount: 48189 },
      { date: new Date('2023-12-17'), amount: 665319 },
      { date: new Date('2023-12-18'), amount: 650367 },
      { date: new Date('2023-12-19'), amount: 747341 },
      { date: new Date('2023-12-20'), amount: 738168 },
      { date: new Date('2023-12-21'), amount: 64945 },
      { date: new Date('2023-12-22'), amount: 955626 },
      { date: new Date('2023-12-23'), amount: 923536 },
      { date: new Date('2023-12-24'), amount: 533272 },
      { date: new Date('2023-12-25'), amount: 333207 },
      { date: new Date('2023-12-26'), amount: 169623 },
      { date: new Date('2023-12-27'), amount: 16779 },
      { date: new Date('2023-12-28'), amount: 563439 },
      { date: new Date('2023-12-29'), amount: 402259 },
      { date: new Date('2023-12-30'), amount: 908362 },
      { date: new Date('2023-12-31'), amount: 296789 },
      { date: new Date('2024-1-1'), amount: 951278 },
      { date: new Date('2024-1-2'), amount: 462729 },
      { date: new Date('2024-1-3'), amount: 258811 },
      { date: new Date('2024-1-4'), amount: 769834 },
      { date: new Date('2024-1-5'), amount: 109026 },
      { date: new Date('2024-1-6'), amount: 360151 },
      { date: new Date('2024-1-7'), amount: 470204 },
      { date: new Date('2024-1-8'), amount: 447810 },
      { date: new Date('2024-1-9'), amount: 270560 },
      { date: new Date('2024-1-10'), amount: 181111 },
      { date: new Date('2024-1-11'), amount: 435895 },
      { date: new Date('2024-1-12'), amount: 566335 },
      { date: new Date('2024-1-13'), amount: 851422 },
      { date: new Date('2024-1-14'), amount: 844209 },
      { date: new Date('2024-1-15'), amount: 941800 },
      { date: new Date('2024-1-16'), amount: 394985 },
      { date: new Date('2024-1-17'), amount: 439852 },
      { date: new Date('2024-1-18'), amount: 602895 },
      { date: new Date('2024-1-19'), amount: 141357 },
      { date: new Date('2024-1-20'), amount: 851399 },
      { date: new Date('2024-1-21'), amount: 41753 },
      { date: new Date('2024-1-22'), amount: 649049 },
      { date: new Date('2024-1-23'), amount: 179989 },
      { date: new Date('2024-1-24'), amount: 340846 },
      { date: new Date('2024-1-25'), amount: 869757 },
      { date: new Date('2024-1-26'), amount: 67567 },
      { date: new Date('2024-1-27'), amount: 224084 },
      { date: new Date('2024-1-28'), amount: 566694 },
      { date: new Date('2024-1-29'), amount: 993659 },
      { date: new Date('2024-1-30'), amount: 112739 },
      { date: new Date('2024-1-31'), amount: 639460 },
      { date: new Date('2024-2-1'), amount: 890697 },
      { date: new Date('2024-2-2'), amount: 759439 },
      { date: new Date('2024-2-3'), amount: 769824 },
      { date: new Date('2024-2-4'), amount: 416240 },
      { date: new Date('2024-2-5'), amount: 753014 },
      { date: new Date('2024-2-6'), amount: 816854 },
      { date: new Date('2024-2-7'), amount: 417036 },
      { date: new Date('2024-2-8'), amount: 754524 },
      { date: new Date('2024-2-9'), amount: 19809 },
      { date: new Date('2024-2-10'), amount: 365398 },
      { date: new Date('2024-2-11'), amount: 842986 },
      { date: new Date('2024-2-12'), amount: 626957 },
      { date: new Date('2024-2-13'), amount: 840568 },
      { date: new Date('2024-2-14'), amount: 642410 },
      { date: new Date('2024-2-15'), amount: 509630 },
      { date: new Date('2024-2-16'), amount: 362346 },
      { date: new Date('2024-2-17'), amount: 541485 },
      { date: new Date('2024-2-18'), amount: 543478 },
      { date: new Date('2024-2-19'), amount: 195428 },
      { date: new Date('2024-2-20'), amount: 960126 },
      { date: new Date('2024-2-21'), amount: 755408 },
      { date: new Date('2024-2-22'), amount: 797069 },
      { date: new Date('2024-2-23'), amount: 795225 },
      { date: new Date('2024-2-24'), amount: 334999 },
      { date: new Date('2024-2-25'), amount: 347339 },
      { date: new Date('2024-2-26'), amount: 45788 },
      { date: new Date('2024-2-27'), amount: 923968 },
      { date: new Date('2024-2-28'), amount: 850196 },
    ],
  };
  private finalData: BarsHighlightInternalElement[] = [];
  private years = new Map();
  private quarters = new Map();
  private months = new Map();

  private maxMonthlyDisplayValues = 90;

  private titlesArray = [
    BarsHighlightGroup.Year,
    BarsHighlightGroup.Quarter,
    BarsHighlightGroup.Month,
  ];

  ngAfterViewInit(): void {
    this.processInfo();
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config != null) {
      this.config.onToggleChange = (indx) => {
        const subtitle = this.titlesArray[indx];
        this.updateChartData(subtitle);
      };
      this.config.getSubtitles = () => {
        return this.titlesArray;
      };
      this.metadata = { ...this.metadata, ...this.config?.metadata };
    }
  }

  updateChartData(goToType: BarsHighlightGroup) {
    if (this.selectedType != goToType) {
      this.selectedType = goToType;
      this.clearGraph();
      this.initializeChart();
    }
  }

  private processInfo(): void {
    this.finalData = [];
    this.years = new Map();
    this.quarters = new Map();
    this.months = new Map();
    let processedData = [];
    for (let i = this.metadata.data.length - 1; i >= 0; i--) {
      const element: BarsHighlightElement | undefined = this.metadata.data[i];
      const year = element.date.getFullYear();
      const month = element.date.getMonth() + 1;
      const quarter = Math.ceil(month / 3);

      const complexMonth = `${year}${month}`;
      const complexQuarter = `${year}${quarter}`;

      this.years.set(year, year + '');
      this.quarters.set(complexQuarter, complexQuarter);
      this.months.set(complexMonth, complexMonth);

      processedData.push({
        ...element,
        year,
        month: complexMonth,
        quarter: complexQuarter,
      });
    }
    this.finalData = processedData.sort((a, b) => (a.date < b.date ? -1 : 1));
  }

  private clearGraph(): void {
    d3.selectAll(`#${this.id} > *`).remove();
  }

  private initializeChart(): void {
    setTimeout(() => {
      this.drawChart();
    }, 0);
  }
  private drawChart(): void {
    let groupBy: (d: BarsHighlightInternalElement) => any = (d) => d.quarter;
    let keys: string[] = [];
    let workingData = [...this.finalData];

    switch (this.selectedType) {
      case BarsHighlightGroup.Year:
        groupBy = (d) => d.quarter;
        keys = Array.from(this.years.values());
        break;
      case BarsHighlightGroup.Quarter:
        groupBy = (d) => d.month;
        keys = Array.from(this.quarters.values());
        break;
      case BarsHighlightGroup.Month:
        groupBy = (d) =>
          `${d.date.getFullYear()}-${d.date.getMonth()}-${d.date.getDate()}`;
        keys = Array.from(this.months.values());
        workingData = [
          ...this.finalData.slice(
            Math.max(this.finalData.length - this.maxMonthlyDisplayValues, 0)
          ),
        ];
        break;
    }
    const availableColors = [
      'rgb(85,170,167)',
      'rgb(235,121,58)',
      'rgb(240,171,32)',
      'rgb(37,91,140)',
      'rgb(185,214,90)',
    ];
    const colors: { [key: string]: string } = Array.from(
      this.years.keys()
    ).reduce((result, v, i) => {
      const colorIndex = Math.floor(i % availableColors.length);
      result[v] = availableColors[colorIndex];
      return result;
    }, {});
    const groups: d3.InternMap<string, BarsHighlightInternalElement[]> =
      d3.group(workingData, groupBy);

    let values: { [key: string]: number } = {};
    groups.forEach((x: BarsHighlightInternalElement[], key: string) => {
      let total = 0;
      x.forEach((y) => {
        total += y.amount;
      });
      values[key] = total;
    });

    const margin = { top: 20, right: 30, bottom: 130, left: 230 },
      width = 3600 - margin.left - margin.right,
      height = 1500 - margin.top - margin.bottom;

    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .attr('preserveAspectRatio', 'none')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    let x = d3
      .scaleBand()
      .range([0, width])
      .domain(Array.from(groups.keys()))
      .paddingInner(0.2)
      .paddingOuter(0.2);
    let shown = {};
    let xAxis = svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(
        d3.axisBottom(x).tickFormat((l, i) => {
          return this.formatTick(l + '', shown);
        })
        .tickPadding(50)
      );
    xAxis.select('.domain').attr('stroke', 'white');
    xAxis.selectAll('.tick text').style('font-size', '100');

    const domainY: [number, number] = d3.extent(Object.values(values)) as [
      number,
      number
    ];
    const min =
      domainY[0] - domainY[0] / 20 < 0 ? 0 : domainY[0] - domainY[0] / 20;
    const y = d3.scaleLinear().domain([min, domainY[1]]).range([height, 0]);

    let yAxis = svg
      .append('g')
      .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')));
    yAxis.select('.domain').attr('stroke', 'white');
    yAxis.selectAll('.tick text').style('font-size', '100');

    svg
      .selectAll('.bar')
      .data(groups)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d, i) => {
        const result = x(d[0]) as number;
        return result;
      })
      .attr('y', (d, i) => {
        const result = y(0);
        return result;
      })
      .attr('width', x.bandwidth())
      .attr('height', (d, i) => {
        const result = height - y(0);
        return result < 0 ? 0 : result;
      })
      .attr('fill', (d) => {
        return colors[d[1][0].year + ''];
      });

    //

    svg
      .selectAll('rect')
      .transition()
      .duration(800)
      .attr('y', (d: any) => {
        return y(values[d[0]]);
      })
      .attr('height', (d: any) => {
        return height - y(values[d[0]]);
      })
      .delay((d, i) => {
        return this.selectedType === BarsHighlightGroup.Month ? 50 : i * 50;
      });
  }

  private formatTick(value: string, shown: { [key: string]: boolean }): string {
    let result: string = '';
    let comparable: string = '';
    switch (this.selectedType) {
      case BarsHighlightGroup.Year:
        result = value.substring(0, 4);
        comparable = result;
        break;
      case BarsHighlightGroup.Quarter:
        const month = parseInt(value.slice(4));
        const quarter = Math.ceil(month / 3);
        result = 'Q' + quarter;
        const year = value.substring(0, 4);
        comparable = year + '' + quarter;
        break;
      case BarsHighlightGroup.Month:
        const parts = value.split('-');
        const mth = parseInt(parts[1]);
        result = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ][mth];
        comparable = parts[0] + '-' + parts[1];
        break;
    }
    if (shown[comparable] == null) {
      shown[comparable] = true;
      return result;
    }
    return '';
  }
}
