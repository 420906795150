import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { KeyValueChipList } from './key-value';

@Component({
  selector: 'giq-chips-list',
  templateUrl: 'chips-list.component.html',
  styleUrls: ['chips-list.component.scss'],
})
export class ChipsListComponent implements OnChanges, OnDestroy {
  @Input() label: string | null = null;
  @Input() possibleValues: KeyValueChipList[] = [];
  @Input() placeholder: string | null = '';
  @Input() showSearchIcon = false;
  @Output() selectionChanged: EventEmitter<KeyValueChipList[]> =
    new EventEmitter<KeyValueChipList[]>();

  public selection: KeyValueChipList[] = [];
  public allElements: KeyValueChipList[] = [];
  public control = new FormControl<string | KeyValueChipList>('');
  public filteredElements: KeyValueChipList[] = [];

  private _subscriptions: Subscription = new Subscription();

  @ViewChild('input') input: ElementRef<HTMLInputElement> | undefined;

  constructor() {
    this._subscriptions.add(
      this.control.valueChanges.subscribe(
        (value: string | KeyValueChipList | null) => {
          if (value == null) {
            this.filteredElements = this.allElements;
          } else {
            if (typeof value === 'string' && value) {
              this.filteredElements = this._filter(value);
            } else {
              this.filteredElements = this.allElements;
            }
          }
        }
      )
    );
  }

  clear(emit: boolean = true) {
    this.selection = [];
    if (emit) {
      this.selectionChanged.emit(this.selection);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let { possibleValues } = changes;
    if (possibleValues != null) {
      this.allElements = possibleValues.currentValue.map(
        (x: KeyValueChipList) => {
          const comparisonValue = x.value.toLowerCase().replace(/[^\w]/g, '');
          return { ...x, comparisonValue };
        }
      );
      if (this.allElements?.length) {
        this.control.setValue('');
      }
      this.checkSelectedValues();
    }
  }

  public remove(selec: KeyValueChipList): void {
    const index = this.selection.indexOf(selec);

    if (index >= 0) {
      this.selection.splice(index, 1);
      this.selectionChanged.emit(this.selection);
    }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    let value = event.option.value as KeyValueChipList;
    if (this.selection.indexOf(value) === -1) {
      this.selection.push(event.option.value);
      this.selectionChanged.emit(this.selection);
    }
    this.input!.nativeElement.value = '';
    this.control.setValue(null);
  }

  private checkSelectedValues(): void {
    // let newSelection: KeyValueChipList[] = [];
    // this.selection.forEach((x, indx) => {
    //   const index = this.allElements.findIndex((y) => y.key == x.key);
    //   if (index > 0) {
    //     newSelection.push(x);
    //   }
    // });
    // this.selection = newSelection;
    // this.selectionChanged.emit(this.selection);
  }

  private _filter(value: string): KeyValueChipList[] {
    const filterValue = value.toLowerCase();

    const coincidences = this.allElements.filter((element) =>
      element.comparisonValue.includes(filterValue)
    );
    return coincidences;
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
