import { createStore, select, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { UserInfo } from './user-info';

interface UserProps {
  authenticated: boolean;
  loggingOut: boolean;
  info: UserInfo | undefined;
  agentAutomationDivision: string | undefined;
}

const UserStore = createStore(
  { name: 'user' },
  withProps<UserProps>({
    authenticated: false,
    info: undefined,
    agentAutomationDivision: undefined,
    loggingOut: false,
  })
);

export const userAuthenticated$: Observable<boolean> = UserStore.pipe(
  select((state) => state.authenticated)
);

export const loggingOut$: Observable<boolean> = UserStore.pipe(
  select((state) => state.loggingOut)
);

export const userInfo$: Observable<UserInfo | undefined> = UserStore.pipe(
  select((state) => state.info)
);

export const hasPermission$ = (permission: string) => {
  return UserStore.pipe(
    select((state) => state.info?.frontActions.includes(permission))
  );
};

export const hasPermission = (permission: string) => {
  return UserStore.state.info?.frontActions.includes(permission);
};

export const userDivision$: Observable<string | undefined> = UserStore.pipe(
  select((state) => state?.agentAutomationDivision)
);

export function setUserDivision(division: string) {
  UserStore.update((state: any) => ({
    ...state,
    agentAutomationDivision: division,
  }));
}

export function setUserAuthenticated(authenticated: boolean) {
  UserStore.update((state) => ({
    ...state,
    authenticated,
  }));
}

export function loggingOut() {
  console.log('Logging out...');
  UserStore.update((state) => ({
    ...state,
    loggingOut: true,
  }));
}

export function setUserInfo(userInfo: UserInfo) {
  UserStore.update((state) => ({
    ...state,
    info: userInfo,
  }));
}
