<div class="highlight" *ngIf="config">
  <div class="chart">
    <div class="element" *ngFor="let item of elements">
      <div class="chartAmount" [id]="item.id"></div>
    </div>
    <div class="labels">
      <div class="data">
        <span class="dot" [style.--color]="metadata.differenceColor"></span>
        <span class="label">{{ metadata.differenceLabel }} </span>
      </div>
      <div class="data">
        <span class="dot" [style.--color]="metadata.baseColor"></span>
        <span class="label">{{ metadata.baseLabel }}</span>
      </div>
    </div>
  </div>
</div>