import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { setUserAuthenticated } from '../state/user/user.store';
import { AuthService } from './auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        let isAuthCall = request.url.includes('/auth');
        if (
          (err.status == 403 || err.status == 0) &&
          this.authService.isTokenExpired()
        ) {
          setUserAuthenticated(false);
          err.status = 403;
          if (isAuthCall) {
            this.router.navigate(['unauthorized']);
          } else {
            this.dialog.open(DialogComponent, {
              disableClose: true,
              data: {},
            });
          }
          return throwError(() => err);
        }
        return throwError(() => err);
      })
    );
  }
}
