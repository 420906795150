import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { loggingOut$, userAuthenticated$ } from './core/state/user/user.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.checkSession();
  }
  public authenticated$: Observable<boolean> = userAuthenticated$;
  public loggingOut$: Observable<boolean> = loggingOut$;
}
