<h3>Data exchange</h3>

<div class="workspace-content">
  <div class="app" *ngIf="psFormAccess$ | async">
    <div class="app-image" [routerLink]="['ua-ps-admin']">
      <img src="assets/app_customercareservicetracker.svg" />
    </div>
    <div class="app-name">Customer Care <br />Service Tracker</div>
  </div>

  <div class="app" *ngIf="automationFormAccess$ | async">
    <div class="app-image" [routerLink]="['agent-automation']">
      <img src="assets/app_agentinsightautomationtracker.svg" />
    </div>
    <div class="app-name">
      Recruiting Pipeline <br />
      Data Collection
    </div>
  </div>

  <div class="app" *ngIf="worksiteAccess$ | async">
    <div class="app-image" [routerLink]="['worksite-tracker']">
      <img src="assets/app_lndworksitetracker.svg" />
    </div>
    <div class="app-name">Worksite Manual <br />Payments Log</div>
  </div>

  <div class="app" *ngIf="bankCompliance$ | async">
    <div class="app-image" [routerLink]="['bank-compliance']">
      <img src="assets/app_bank_compliance.png" />
    </div>
    <div class="app-name">Bank Compliance <br />Early Fraud Detection</div>
  </div>

  <div class="app" *ngIf="claimAuditLogsAccess$ | async">
    <div class="app-image" [routerLink]="['claim-audit-logs']">
      <img src="assets/app_claimauditlogs.svg" />
    </div>
    <div class="app-name">Claim Audit <br />Logs</div>
  </div>
  <div class="app" *ngIf="productionTask$ | async">
    <div class="app-image" [routerLink]="['production-task']">
      <img src="assets/prod-task.svg" />
    </div>
    <div class="app-name">
      Production Form <br />
      Task Log
    </div>
  </div>
  <div class="app" *ngIf="underwriting$ | async">
    <div class="app-image" [routerLink]="['underwriting']">
      <img src="assets/underwriting.svg" />
    </div>
    <div class="app-name">Underwriting</div>
  </div> 
</div>
<div class="loading-content" *ngIf="(hasAnyAccess$ | async) == false">
  <img class="loading-icon" src="assets/GlobeIQ_Icon.svg" />
  <span class="loading-text">No data exchange apps found</span>
</div>
