import {
  Component,
  Injector,
  Input,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  asyncScheduler,
  map,
  observeOn,
} from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { reportsOfCategory$ } from 'src/app/core/state/reports/reports.store';
import { Report } from 'src/app/models/reports';
import { ReportsService } from '../../core/reports.service';
import { ReportsDetailsService } from '../reports-details.service';

@Component({
  selector: 'giq-workspace-category',
  templateUrl: './workspace-category.component.html',
  styleUrls: ['./workspace-category.component.scss'],
})
export class WorkspaceCategoryComponent implements OnInit {
  @Input() title: string = '';
  @Input() categoryId: string = '';

  public reportCount = 0;

  public reports$: Observable<Report[]> = new BehaviorSubject<Report[]>([]);

  constructor(
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private service: ReportsService,
    private router: Router,
    private reportDetailsService: ReportsDetailsService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.reportDetailsService.initDetailsServiceWithParams(
      this.injector,
      this.viewContainerRef
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    let categoryIdChange: SimpleChange = changes['categoryId'];
    if (categoryIdChange != null && categoryIdChange.currentValue !== '') {
      this.reports$ = reportsOfCategory$(this.categoryId).pipe(
        observeOn(asyncScheduler),
        map((reps: Report[]) => {
          this.reportCount = reps.length;
          if (this.reportCount > 5) {
            reps.splice(4, this.reportCount - 5);
          }
          return reps;
        })
      );
    }
  }

  public setFavorite(report: Report, mouseEvent: MouseEvent): void {
    this.service.SetFavorite(report.id, !report.favorite).subscribe();
    report.favorite = !report.favorite;
  }

  public openReport(report: Report, mouseEvent: MouseEvent): void {
    this.authService.refresh();
    let route = this.service.openReport(report);
    this.router.navigate(route);
  }
}
