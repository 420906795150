import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { Highlight } from 'src/app/models/reports';
import {
  Display,
  LineHighlightElement,
  LineHighlightMetadata,
  TimeScale,
} from './line-highlight.metadata';
 
@Component({
  selector: 'giq-line-highlight',
  templateUrl: 'line-highlight.component.html',
  styleUrls: ['line-highlight.component.scss'],
})
export class LineHighlightComponent implements OnChanges {
  @Input() config: Highlight | undefined;

  public id: string = 'line-' + Math.ceil(Math.random() * 100000);
  selectedIndex: number = 0;
  metadataSets!: LineHighlightMetadata[];
  public metadata: LineHighlightMetadata = {
    colors: [],
    data: [],
    timeScale: TimeScale.Monthly,
    display: Display.None,
    division: '',
    dotsColors: [],
    showAmount: '',
    showDots: ''
  };
  isToggleEnabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config != null) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => {
        return this.getSubtitles();
      };

      const elements = this.config
        ?.metadata as unknown as LineHighlightMetadata;
      if (elements) {
        this.isToggleEnabled = elements.data.some((set: any) => set.division);
        this.metadataSets = elements.data.map((set: any) => {
          const division = set.division || '';
          const display = set.display || Display.None;

          const newData = Object.keys(set)
            .filter((key) => key !== 'division')
            .map((key) => set[key])
            .filter(
              (item) => typeof item === 'object'
            ) as LineHighlightElement[];

          newData.forEach((x: any) => (x.date = new Date(x.date)));

          return {
            timeScale: elements.timeScale,
            colors: elements.colors,
            data: newData.sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            ),
            division: division,
            display: elements.display,
            dotsColors: elements.dotsColors,
            showAmount: elements.showAmount,
            showDots: elements.showDots
          };
        });

        this.metadata = this.metadataSets[this.selectedIndex];
      }
      setTimeout(() => this.createSvg(), 0);
    }
  }

  updateChartData(index: number): void {
    this.selectedIndex = index;
    this.metadata = this.metadataSets[index];
    this.clearGraph();
    this.createSvg();
  }

  getSubtitles(): string[] {
    const elements = this.config?.metadata as unknown as LineHighlightMetadata;

    if (elements?.data?.length <= 1 || !this.isToggleEnabled) {
      return [];
    } else {
      const divisions = elements.data
        .map((set: any) => set.division)
        .filter((division) => !!division);
      return [...new Set(divisions)];
    }
  }

  private clearGraph(): void {
    d3.selectAll(`#${this.id} > *`).remove();
  }

  private createSvg(): void {
    const margin = { top: 50, right: 20, bottom: 50, left: 20 },
      width = 500 - margin.right,
      height = 250 - margin.top - margin.bottom;

    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `-10 0 ${width + margin.left + margin.right + 20} ${
          height + margin.top + margin.bottom
        }`
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const groups = d3.group(this.metadata.data, (d) => d.key);

    const domainX: [Date, Date] = d3.extent(
      this.metadata.data,
      (d: LineHighlightElement) => d.date
    ) as [Date, Date];

    const x = d3.scaleTime().domain(domainX).range([0, width]);

    let xAxis;

    const labelWidth = 50;
    const maxTicks = Math.floor(width / labelWidth);
    const totalWeeks = d3.timeWeek.count(domainX[0], domainX[1]);
    const tickInterval = Math.ceil(totalWeeks / maxTicks);
    const xAxisRange = domainX[1].getMonth() - domainX[0].getMonth();
    const yearRange = domainX[1].getFullYear() - domainX[0].getFullYear();
    const numberOfTickts = xAxisRange < 5 && yearRange == 0 ? xAxisRange : 5;
    const totalQuarters =
      (domainX[1].getFullYear() - domainX[0].getFullYear()) * 4 +
      Math.floor(domainX[1].getMonth() / 3) -
      Math.floor(domainX[0].getMonth() / 3);
    const quarterTickInterval = Math.ceil(totalQuarters / maxTicks);

    switch (this.metadata.timeScale) {
      case TimeScale.Weekly:
        xAxis = svg
          .append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .call(
            d3
              .axisBottom(x)
              .ticks(d3.timeWeek.every(tickInterval))
              .tickSize(0)
              .tickPadding(15)
              .tickFormat(d3.timeFormat('%m/%d') as any)
          );
        break;

      case TimeScale.Monthly:
        xAxis = svg
          .append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .call(
            d3
              .axisBottom(x)
              .ticks(numberOfTickts)
              .tickSize(0)
              .tickPadding(15)
              .tickFormat(d3.timeFormat('%b') as any)
          );
        break;
      case TimeScale.MonthNumbers:
        xAxis = svg
          .append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .call(
            d3
              .axisBottom(x)
              .ticks(numberOfTickts)
              .tickSize(0)
              .tickPadding(15)
              .tickFormat(d3.timeFormat('%m') as any)
          );
        break;

      case TimeScale.Yearly:
        xAxis = svg
          .append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .call(
            d3
              .axisBottom(x)
              .ticks(5)
              .tickSize(0)
              .tickPadding(15)
              .tickFormat(d3.timeFormat('%Y') as any)
          );
        break;
      case TimeScale.YrQtr:
        xAxis = svg
          .append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .call(
            d3
              .axisBottom(x)
              .ticks(d3.timeMonth.every(quarterTickInterval * 3))
              .tickSize(0)
              .tickPadding(5)
              .tickFormat((date: any) => {
                const yearShort = d3.timeFormat('%y')(date);
                const quarter = Math.floor(date.getMonth() / 3) + 1;
                return `${yearShort}Q${quarter}`;
              })
          );
        break;
    }

    xAxis.select('.domain').attr('stroke', '#dcdbdb');

    let values: number[] = [];
    let keys: string[] = [];

    groups.forEach((x: LineHighlightElement[]) => {
      let key = '';
      x.forEach((y) => {
        values.push(y.yValue);
        key = y.key;
      });
      keys.push(key);
    });
    const domainY: [number, number] = d3.extent(values) as [number, number];
    const buffer = (domainY[1] - domainY[0]) * 0.15;
    const maxY = domainY[1] ? domainY[1] + buffer : 1;
    const y = d3.scaleLinear().domain([domainY[0], maxY]).range([height, 0]);

    const minTicks = 1;
    const maxYAxisTicks = Math.floor(height / 50);
    const tickHeight = height / maxYAxisTicks;

    const numberOfTicks = Math.min(6, maxYAxisTicks);

    /* const yAxis = svg.append('g').call(
      d3
        .axisLeft(y)
        .ticks(numberOfTicks)
        .tickSize(0)
        .tickPadding(15)
        .tickFormat((d) => {
          const value = Number(d);
          if (value >= 1000000) {
            const scaledValue = value / 1000000;
            if (Number.isInteger(scaledValue)) {
              return d3.format('.0f')(scaledValue) + 'M';
            } else {
              return d3.format('.2f')(scaledValue) + 'M';
            }
          } else if (value >= 1000) {
            const scaledValue = value / 1000;
            if (Number.isInteger(scaledValue)) {
              return d3.format('.0f')(scaledValue) + 'k';
            } else if ((scaledValue * 10) % 1 === 0) {
              return d3.format('.1f')(scaledValue) + 'k';
            } else {
              return d3.format('.2f')(scaledValue) + 'k';
            }
          } else if (value < 1000) {
            return d3.format('')(value);
          }
          return d3.format('.2f')(value);
        })
    );
    yAxis.select('.domain').attr('stroke', '#dcdbdb');*/

    const color = d3.scaleOrdinal().domain(keys).range(this.metadata.colors);

    const line = d3
      .line()
      .x((d: any) => x(d.date))
      .y((d: any) => y(d.yValue));

    svg
      .selectAll('.line')
      .data(groups)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('class', 'line')
      .attr('stroke', (d) => color(d[0]) as any)
      .attr('stroke-width', 4.5)
      .attr('d', (d) => line(Array.from(d.values())[1] as any))
      .attr('stroke-dasharray', function () {
        const totalLength: number = this.getTotalLength();
        return totalLength + ' ' + totalLength;
      })
      .attr('stroke-dashoffset', function () {
        return this.getTotalLength();
      })
      .transition()
      .duration(1000)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'line-tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('z-index', 2)
      .style('border', '1px solid #ddd')
      .style('background-color', 'white')
      .style('padding', '5px');

    const formatDate = d3.timeFormat('%b %d %Y');
    const formatValue = (value: number) => {
      if (value < 100000) return value.toString();
      return d3.format('.2s')(value).replace(/\.0$/, '');
    };

    svg
      .selectAll('.dot')
      .data(
        this.metadata.showDots ?
          this.metadata.data.filter(d => this.metadata.showDots.includes(d.key))
          : this.metadata.data )
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', (d) => x(d.date))
      .attr('cy', (d) => y(d.yValue))
      .attr('r', 7)
      .attr('fill', (d, i) => {
        return this.metadata.dotsColors.length > 0
          ? this.metadata.dotsColors[i % this.metadata.dotsColors.length]
          : this.metadata.colors[i % this.metadata.colors.length];
      })
      .on('mouseover', function (event, d) {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(
            `Date: ${formatDate(d.date)}<br>Value: ${formatValue(d.yValue)}`
          )
          .style('left', event.pageX + 5 + 'px')
          .style('top', event.pageY - 28 + 'px');
        d3.select(this).transition().duration(200).attr('r', 7);
      })
      .on('mouseout', function () {
        tooltip.transition().duration(500).style('opacity', 0);
        d3.select(this).transition().duration(500).attr('r', 7);
      });

    const legendWidthPerItem = 100;
    const totalLegendWidth = keys.length * legendWidthPerItem;
    //const legendXOffset = (width - totalLegendWidth) / 2;
    const formatNumber = (value: number) => {
      if (value >= 1000000 ) {
        const scaledValue = Math.floor(value / 1e5)/10;
        return d3.format('.1f')(scaledValue) + 'M';
      } else if( value >= 1000 ){
        const scaledValue = Math.floor(value / 100) /10;
        return  d3.format('.1f')(scaledValue) + 'K';
      } else return d3.format('')(value);

    };

    const formatPercentage = (value: number) => {
      const roundedValue = (value % 1) < 0.5 ? Math.floor(value) : Math.ceil(value);
      return roundedValue
    }
    const legendYOffset = -35;
    const legendXOffset = 10;
    const maxYValue = d3.max(this.metadata.data, (d) => d.yValue) || 0;
    const adjustedOffset = Math.max(legendYOffset, y(maxYValue) - 20);

    const legend = svg
      .append('g')
      .attr('transform', `translate(${legendXOffset}, ${legendYOffset})`)
      .attr('class', 'legend-group');

    const legendItem = legend
      .selectAll('.legend-item')
      .data(keys)
      .enter()
      .append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d, i) => `translate(0, ${i * 20})`);

    legendItem
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 7)
      .style('fill', (d) => color(d) as string);;

    legendItem
      .append('text')
      .attr('x', 15)
      .attr('y', 0)
      .style('text-anchor', 'start')
      .style('dominant-baseline', 'middle')
      .text((d) => d);

    const maxValue = Math.max(...this.metadata.data.map((item) => item.yValue));
    let maxValueLabeled = false;
    svg
      .selectAll('.label')
      .data(this.metadata.data)
      .enter()
      .append('text')
      .attr('class', 'label')
      .attr('x', (d, i) => {
        const xPos = x(d.date)!;
        const prev = i > 0 ? this.metadata.data[i - 1] : null;
        const next =
          i < this.metadata.data.length - 1 ? this.metadata.data[1 + 1] : null;

        if (prev && next) {
          if (prev.yValue < d.yValue && next.yValue < d.yValue) {
            return xPos - 10;
          } else if (prev.yValue > d.yValue && next.yValue > d.yValue) {
            return xPos + 8;
          }
        }
        return xPos;
      })
      .attr('y', (d, i) => {
        const yPos = y(d.yValue)!;
        const prev = i > 0 ? this.metadata.data[i - 1] : null;
        const next =
          i < this.metadata.data.length - 1 ? this.metadata.data[1 + 1] : null;

        if (prev && next) {
          if (prev.yValue < d.yValue && next.yValue < d.yValue) {
            return yPos - 10;
          } else if (prev.yValue > d.yValue && next.yValue > d.yValue) {
            return yPos - 20;
          }
        }
        return yPos - 15;
      })
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .text((d) => {
        let labelText = '';
        const isShowAmountKey = this.metadata.showAmount === d.key;
        if(isShowAmountKey){
          labelText = `$${formatNumber(d.yValue)}`;
        } else {
        switch (this.metadata.display) {
          case Display.Percentage:
            labelText = `${formatPercentage(d.yValue)}%`;
            break;
          case Display.MaxPercentage:
            if (d.yValue === maxValue) {
              labelText = `${formatPercentage(d.yValue)}%`;
            }
            break;
          case Display.Amount:
            labelText = `$${formatNumber(d.yValue)}`;
            break;
          case Display.MaxAmount:
            if (d.yValue === maxValue && !maxValueLabeled) {
              maxValueLabeled = true;
              labelText = `$${formatNumber(d.yValue)}`;
            }
            break;
          case Display.None:
          default:
            return '';
        }
      }
        return labelText;
      });
  }
}
