<form (submit)="onSearch($event)" class="search-bar-container">
  <input
    [disabled]="loading"
    [(ngModel)]="search"
    autocomplete="off"
    name="search"
    placeholder="Search..."
  />
  <mat-icon style="cursor: pointer" matSuffix (click)="onSearch($event)">
    search
  </mat-icon>
</form>
