import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StockValue } from './stock-value';

@Injectable()
export class StockService {
    constructor(private httpCLient: HttpClient) { }

    public getStockValue(): Observable<StockValue> {
        return this.httpCLient.get<StockValue>(environment.apiUrl + '/configs/stockvalue');
    }
}