import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/core/reports.service';
import { Report } from 'src/app/models/reports';

@Component({
  selector: 'giq-records-result-modal',
  templateUrl: './records-result-modal.component.html',
  styleUrls: ['./records-result-modal.component.scss'],
})
export class RecordsResultModalComponent {
  dataSource = [];
  searchString = '';
  constructor(
    public dialogRef: MatDialogRef<RecordsResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private reportsService: ReportsService
  ) {
    const { searchString, records } = data;

    this.dataSource = records;
    this.searchString = searchString;
  }

  public columnTitles = ['reportName', 'overview', 'metrics', 'actions'];

  goToReport(report: Report) {
    let navigation = this.reportsService.openReport(report);
    this.router.navigate(navigation);
    this.dialogRef.close();
  }

  seeMoreClick() {
    this.router.navigate(['reports'], {
      queryParams: {
        searchString: this.searchString,
      },
    });
    this.dialogRef.close();
  }
}
