import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({ selector: '[giqTouchedValidation]' })
export class TouchedValidationDirective {
  @Input() giqTouchedValidation?: AbstractControl;
  constructor() {}

  @HostBinding('class')
  get elementClass(): string {
    let theClass = '';

    if (this.giqTouchedValidation?.touched) {
      theClass = this.giqTouchedValidation?.errors ? 'error' : 'ok';
    }

    return theClass;
  }
}
