import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { Highlight } from 'src/app/models/reports';
import {
  ScatterplotMetadata,
  ScatterplotMetadataElement,
} from './scatterplot-highlight.metadata';

@Component({
  selector: 'giq-scatterplot-highlight',
  templateUrl: 'scatterplot-highlight.component.html',
  styleUrls: ['scatterplot-highlight.component.scss'],
})
export class ScatterplotHighlightComponent implements AfterViewInit, OnChanges {
  @Input() config: Highlight | undefined;

  public id: string = 'scatterplot-' + Math.ceil(Math.random() * 100000);

  public metadata: ScatterplotMetadata = {
    data: [],
  };

  ngAfterViewInit(): void {
    this.createSvg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null) {
      const newMetadata = { ...this.metadata, ...this.config?.metadata };
      newMetadata.data.forEach((x:any) => (x.date = new Date(x.date)));
      this.metadata = newMetadata;
    }
  }

  private createSvg(): void {
    const margin = { top: 10, right: 20, bottom: 30, left: 40 },
      width = 500 - margin.left - margin.right,
      height = 250 - margin.top - margin.bottom;
    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .attr('preserveAspectRatio', 'none')
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const domainX: [Date, Date] = d3.extent(
      this.metadata.data,
      (d: ScatterplotMetadataElement) => d.date
    ) as [Date, Date];
    const minMilis = domainX[0].getTime() - 1000 * 60 * 60 * 24;
    const minDate = domainX[0].setTime(minMilis);
    const x = d3.scaleTime().domain([minDate, domainX[1]]).range([0, width]);

    const xAxis = svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(
        d3
          .axisBottom(x)
          .ticks(5)
          .tickSize(0)
          .tickFormat(d3.timeFormat('%m/%d') as any)
      );

    xAxis.select('.domain').attr('stroke', 'white');
    const values = this.metadata.data.reduce(
      (acc: number[], x: ScatterplotMetadataElement) => {
        acc.push(x.amount);
        return acc;
      },
      []
    );

    const domainY: [number, number] = d3.extent(values) as [number, number];
    const y = d3.scaleLinear().domain(domainY).range([height, 0]);

    const yAxis = svg
      .append('g')
      .call(d3.axisLeft(y).ticks(5).tickSize(0).tickFormat(d3.format('.2s')));

    yAxis.select('.domain').attr('stroke', 'white');

    svg
      .append('g')
      .selectAll('dot')
      .data(this.metadata.data)
      .enter()
      .append('circle')
      .attr('cx', function (d) {
        return x(d.date);
      })
      .attr('cy', function (d) {
        return y(domainY[0]);
      })
      .attr('r', 1.5)
      .style('fill', '#69b3a2');

    svg
      .selectAll('circle')
      .transition()
      .delay(function (d, i) {
        return i * 3;
      })
      .duration(2000)
      .attr('cx', function (d: any) {
        return x(d.date);
      })
      .attr('cy', function (d: any) {
        return y(d.amount);
      });
  }
}
