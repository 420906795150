<div class="controls">
  <button
    mat-button
    type="button"
    giq-interaction
    (click)="chart.expandAll().fit()"
  >
    Expand All
  </button>

  <button
    mat-button
    type="button"
    giq-interaction
    (click)="chart.collapseAll().fit()"
  >
    Collapse All
  </button>

  <mat-form-field appearance="fill">
    <mat-label>Orientation</mat-label>
    <mat-select [(value)]="position" (selectionChange)="changeLayout()">
      <mat-option [value]="'top'">Top</mat-option>
      <mat-option [value]="'left'">Left</mat-option>
    </mat-select>
  </mat-form-field>

  <form class="search-container" (submit)="onSearch($event)">
    <mat-form-field class="searchAgent">
      <input
        [(ngModel)]="searchValue"
        autocomplete="off"
        name="searchAgent"
        matInput
        placeholder="Search in hierarchy..."
    /></mat-form-field>
  </form>
</div>
<div #chartContainer id="chartContainer"></div>
