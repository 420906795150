import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Highlight } from 'src/app/models/reports';
import { DonutHighlight, DonutMetaDataElement } from './donut-highlight.metadata';

import * as d3 from 'd3';
import { PieArcDatum } from 'd3';

@Component({
  selector: 'giq-donut-highlight',
  templateUrl: 'donut-highlight.component.html',
  styleUrls: ['donut-highlight.component.scss'],
})
export class DonutHighlightComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() config: Highlight | undefined;

  public id: string = 'donut-' + Math.ceil(Math.random() * 100000);
  public indicator: string = '';
  public elements: DonutMetaDataElement[] = [];
  public metadata: DonutHighlight = {
    donutSubtitle: '',
    donutText: '',
    indicator: '',
    indicatorSubtitle: '',
    color: '31,78,121',
    secondary: '68,194,241',
    percentage: 0,
    indicatorFormat: '$.3s',
    donutTextFormat: '$.3s',
    elements: this.elements,
  };
  public single = false;
  public selectedIndex = 0;
  public selectedElement: DonutMetaDataElement = {
    donutSubtitle: '',
    donutText: '',
    indicator: '',
    indicatorSubtitle: '',
    color: '31,78,121',
    secondary: '68,194,241',
    percentage: 0,
    indicatorFormat: '$.3s',
    donutTextFormat: '$.3s',
  };

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.createSvg();
    this.updateSvg();
  }

  private getSubtitles(): string[] {
    return this.elements.map((item: DonutMetaDataElement) => {
      return item.donutSubtitle;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config != null) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => {
        return this.getSubtitles();
      };

      const meta = { ...this.metadata, ...this.config?.metadata };
      meta.percentage = meta.percentage > 100 ? 100 : meta.percentage;

      if (Array.isArray(meta.elements) && meta.elements.length > 1) {
        this.single = false;
        this.elements = meta.elements;
      } else {
        this.single = true;
        const firstElement = meta.elements[0];

        this.elements = [
          {
            donutSubtitle: firstElement.donutSubtitle,
            donutText: firstElement.donutText,
            indicator: firstElement.indicator,
            indicatorSubtitle: firstElement.indicatorSubtitle,
            color: firstElement.color,
            secondary: firstElement.secondary,
            percentage: firstElement.percentage,
            indicatorFormat: firstElement.indicatorFormat,
            donutTextFormat: firstElement.donutTextFormat,
          },
        ];
      }

      this.metadata = meta;

      if (this.elements.length > 0) {
        this.selectedElement = this.elements[this.selectedIndex];
      }

      this.updateIndicator();
      this.updateSvg();
    }
  }

  public updateChartData(index: number) {
    this.selectedIndex = index;
    this.selectedElement = this.elements[index];

    this.updateIndicator();
    this.updateSvg(); // Update the SVG with the new selected element
  }

  private updateIndicator(): void {
    if (
      typeof this.selectedElement?.indicator === 'number' &&
      this.selectedElement?.indicatorFormat != null
    ) {
      const value = this.selectedElement.indicator;
      if (value >= 1000000000) {
        const formatted = (value / 1000000000).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
        this.indicator = formatted.endsWith('.0')
          ? `$${Math.round(value / 1000000000)}B`
          : `$${formatted}B`;
      }
      if (value >= 1000000) {
        const formatted = (value / 10000).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
        this.indicator = formatted.endsWith('.0')
          ? `$${Math.round(value / 10000)}M`
          : `$${formatted}M`;
      } else if (value >= 1000) {
        this.indicator = `$${Math.round(value / 1000)}k`;
      } else {
        this.indicator = value;
      }
    } else {
      this.indicator = this.selectedElement?.indicator ?? '';
    }
  }

  private createSvg(): void {
    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 800 800`)
      .append('g')
      .attr('transform', 'translate(400,400)');

    this.addDefs(svg);
  }

  private updateSvg(): void {
    const width = 800;
    const height = 800;
    const margin = 50;
    const radius = Math.min(width, height) / 2 - margin;

    const data = [
      this.selectedElement.percentage,
      100 - this.selectedElement.percentage,
    ];

    const pie = d3.pie<number>().value((d) => d);
    const arc = (d: PieArcDatum<number>) => {
      return (
        d3
          .arc()
          .innerRadius(radius - 100)
          .outerRadius(radius) as any
      )(d);
    };

    const svg = d3.select<SVGGElement, unknown>(`#${this.id} g`);
    svg.selectAll('*').remove(); // Clear the SVG before updating

    // Re-apply defs for gradient updates
    this.addDefs(svg);

    // Update the paths
    const paths = svg
      .selectAll<SVGPathElement, PieArcDatum<number>>('path')
      .data(pie(data));
    paths.exit().remove();
    paths
      .enter()
      .append('path')
      .merge(paths as any)
      .attr('fill', (d, i) =>
        i == 0 ? `url(#${this.id}pattern)` : `rgb(242,242,242)`
      )
      .attr('stroke', 'rgb(242,242,242)')
      .style('stroke-width', '2px')
      .transition()
      .delay((d, i) => i * 100)
      .duration(200)
      .attrTween('d', (d) => {
        const i = d3.interpolate(d.startAngle, d.endAngle);
        return (t) => {
          d.endAngle = i(t);
          return arc(d);
        };
      });

    // Update the text elements
    svg.selectAll('text').remove();

    svg
      .append('text')
      .text((): string => {
        const value = this.selectedElement.donutText;
        if (typeof value === 'number') {
          if (value >= 1000000000) {
            const formatted = (value / 1000000000).toFixed(1);
            return formatted.endsWith('.0')
              ? `$${Math.round(value / 1000000000)}B`
              : `$${formatted}B`;
          } else if (value >= 1000000) {
            const formatted = (value / 1000000).toFixed(1);
            return formatted.endsWith('.0')
              ? `$${Math.round(value / 1000000)}M`
              : `$${formatted}M`;
          } else if (value >= 1000) {
            const formatted = (value / 1000).toFixed(1);
            return formatted.endsWith('.0')
              ? `$${Math.round(value / 1000)}k`
              : `$${formatted}k`;
          } else {
            return `$${value.toString()}`;
          }
        } else if (typeof value === 'string') {
          return value;
        }
        return '';
      })
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'auto')
      .style('font-size', 100)
      .style('font-weight', 'bold')
      .attr('opacity', '0.5');
    svg
      .append('text')
      .text(this.selectedElement?.donutSubtitle ?? '')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'hanging')
      .style('font-size', 60)
      .attr('dy', 20)
      .attr('opacity', '0.5');
  }

  private addDefs(
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>
  ): void {
    const defs = svg.append('defs');
    const gradient = defs
      .append('linearGradient')
      .attr('gradientTransform', 'rotate(90)')
      .attr('id', this.id + 'main-gradient');
    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', `rgb(${this.selectedElement?.color})`);
    gradient
      .append('stop')
      .attr('offset', '50%')
      .attr('stop-color', `rgb(${this.selectedElement?.secondary})`);
    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', `rgb(${this.selectedElement?.color})`);

    const gradient2 = defs
      .append('linearGradient')
      .attr('gradientTransform', 'rotate(90)')
      .attr('id', this.id + 'main-gradient-two');
    gradient2
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', `rgb(${this.selectedElement?.color})`);
    gradient2
      .append('stop')
      .attr('offset', '50%')
      .attr('stop-color', `rgb(${this.selectedElement?.secondary})`);
    gradient2
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', `rgb(${this.selectedElement?.color})`);

    const pattern = defs
      .append('pattern')
      .attr('id', this.id + 'pattern')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 600)
      .attr('height', 600);
    const g = pattern.append('g').attr('transform', 'rotate(0,300,300)');
    g.append('rect')
      .attr('shape-rendering', 'crispEdges')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 300)
      .attr('height', 600)
      .attr('fill', `url(#${this.id}main-gradient)`);
    g.append('rect')
      .attr('shape-rendering', 'crispEdges')
      .attr('x', 300)
      .attr('y', 0)
      .attr('width', 300)
      .attr('height', 600)
      .attr('fill', `url(#${this.id}main-gradient-two)`);
  }
}
