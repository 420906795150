import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { Highlight } from 'src/app/models/reports';
import {
  InternalSalesScrollElement,
  InternalSalesScrollMetadata,
} from './sales-scroll.metadata';

@Component({
  selector: 'giq-sales-scroll-highlight',
  templateUrl: 'sales-scroll-highlight.component.html',
  styleUrls: ['sales-scroll-highlight.component.scss'],
})
export class SalesScrollHighlightComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() config: Highlight | undefined;
  public metadataSets: InternalSalesScrollMetadata[] = [];
  public selectedIndex: number = 0;
  public metadata: InternalSalesScrollMetadata = {
    label: '',
    elements: [],
    division: '',
  };

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.createSvg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => this.getSubtitles();

      const elements = this.config.metadata?.['elements'];
      if (Array.isArray(elements)) {
        this.metadataSets = elements.map((item: any) => ({
          label: this.config?.metadata['label'] as string,
          elements: Object.entries(item)
            .filter(
              ([key, value]) => key !== 'division' && typeof value === 'object'
            )
            .map(
              ([key, value], elementIndex) =>
                ({
                  ...(value as object),
                  id: `salesScroll-${elementIndex}-${Math.ceil(
                    Math.random() * 10000
                  )}`,
                } as InternalSalesScrollElement)
            ),
          division: item['division'] || '',
        }));
      }
      this.updateChartData(this.selectedIndex);
      setTimeout(() => this.createSvg(), 0);
    }
  }

  getSubtitles(): string[] {
    return this.metadataSets
      .map((set) => set.division)
      .filter((division) => division);
  }

  updateChartData(index: number): void {
    this.selectedIndex = index;
    this.metadata = this.metadataSets[index];
    setTimeout(() => this.createSvg(), 0);
  }

  private createSvg(): void {
    this.metadata.elements.forEach((element: InternalSalesScrollElement) => {
      const container = d3.select(`#${element.id}`);

      container.selectAll('svg').remove();
      const svg = container
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('preserveAspectRatio', 'none')
        .attr('viewBox', '0 0 230 50');

      svg
        .append('line')
        .attr('x1', 15)
        .attr('y1', 25)
        .attr('x2', 215)
        .attr('y2', 25)
        .attr('stroke-width', 30)
        .attr('stroke-linecap', 'round')
        .attr('stroke', '#EAEBF1');

      svg
        .append('line')
        .attr('x1', 15)
        .attr('y1', 25)
        .attr('x2', 15)
        .attr('y2', 25)
        .attr('stroke-width', 22)
        .attr('stroke-linecap', 'round')
        .attr('stroke', element.color)
        .transition()
        .duration(800)
        .attr(
          'x2',
          element.percentage ? 200 * (element.percentage / 100) + 15 : 15
        );

      svg
        .append('text')
        .attr('text-anchor', 'end')
        .attr('dominant-baseline', 'middle')
        .attr('font-size', '10')
        .style('font-weight', 'bold')
        .attr('fill', () => {
          if (element.positive == null) {
            return 'black';
          } else {
            return element.positive ? 'green' : 'red';
          }
        })
        .attr('x', 225)
        .attr('y', 26)
        .text(element.percentage + '%');
    });
  }
}
