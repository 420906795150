import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { PieArcDatum } from 'd3';
import {
  ComplexDonutElement,
  ComplexDonutMetadata,
} from './complex-donut-chart.metadata';

@Component({
  selector: 'giq-complex-donut-chart',
  templateUrl: 'complex-donut-chart.component.html',
  styleUrls: ['complex-donut-chart.component.scss'],
})
export class ComplexDonutComponent implements OnChanges, AfterViewInit {
  @Input() metadata: ComplexDonutMetadata = {
    title: '',
    subtitle: '',
    data: [],
  };
  public id: string = 'complex-donut-' + Math.ceil(Math.random() * 100000);

  private predefinedSize: { [key: number]: number } = {
    0: 30,
    1: 29,
    2: 30,
    3: 32,
    4: 33,
  };

  ngAfterViewInit(): void {
    this.createSvg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { metadata } = changes;
    if (metadata != null && metadata.firstChange === false) {
      this.clearGraph();
      setTimeout(() => {
        this.createSvg();
      }, 0);
    }
  }

  private clearGraph(): void {
    d3.selectAll(`#${this.id} > *`).remove();
  }

  private createSvg(): void {
    const data = this.metadata.data.sort(
      (a: ComplexDonutElement, b: ComplexDonutElement) =>
        b.donutValue - a.donutValue
    );
    const fullWidth = 1600;
    const width = 800;
    const height = 800;
    const margin = 50;
    const radius = Math.min(width, height) / 2 - margin;
    const innerRadius = radius - 200;

    const names = d3.map(data, (x) => x.label);
    const values = d3.map(data, (x) => x.donutValue);
    const colors = d3.map(data, (x) => x.color);
    const labels = d3.map(data, (x) => x.donutLabel);
    const percentages = d3.map(data, (x) => x.percentage.toFixed(1));
    const I = d3.range(names.length).filter((i) => !isNaN(values[i]));

    const pie = d3.pie().value((i: any) => values[i])(I);
    const arc = (d: PieArcDatum<any>) => {
      return (d3.arc().innerRadius(innerRadius).outerRadius(radius) as any)(d);
    };
    const labelRadius = innerRadius * 0.2 + radius * 0.7;
    const arcLabel = d3.arc().innerRadius(labelRadius).outerRadius(labelRadius);

    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('preserveAspectRatio', 'none')
      .attr('viewBox', `0 0 ${fullWidth} ${height}`);

      const tenPercent = height * 0.15;
    svg
      .append('rect')
      .attr('x', width / 2)
      .attr('y', tenPercent)
      .attr('width', fullWidth - width / 2)
      .attr('height', tenPercent)
      .attr('stroke', 'none')
      .attr('fill', 'rgb(231, 240,255)');

    svg
      .append('rect')
      .attr('x', width / 2)
      .attr('y', 0)
      .attr('width', fullWidth - width / 2)
      .attr('height', tenPercent)
      .attr('class', 'title')
      .attr('stroke', 'none')
      .attr('fill', 'rgb(242, 247,255)');

    svg
      .append('text')
      .attr('x', fullWidth - margin / 2)
      .attr('y', tenPercent / 2)
      .text(this.metadata.title)
      .attr('text-anchor', 'end')
      .attr('dominant-baseline', 'middle')
      .style('font-size', height * 0.07)
      .style('font-weight', 'bold');

    svg
      .append('text')
      .attr('x', fullWidth - margin / 2)
      .attr('y', tenPercent + tenPercent / 2 + 5)
      .text(this.metadata.subtitle)
      .attr('text-anchor', 'end')
      .attr('dominant-baseline', 'middle')
      .style('font-size', height * 0.07)
      .attr('fill', 'gray')
      .style('font-weight', 'bold');


    const donut = svg
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    donut
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', radius + margin)
      .attr('stroke', 'none')
      .attr('fill', 'rgb(242, 247,255)');

    donut
      .selectAll('pieces')
      .data(pie)
      .enter()
      .append('path')
      .attr('fill', (d, i) => {
        return colors[i] as string;
      })
      .attr('stroke', 'rgb(242,242,242)')
      .transition()
      .delay((d, i) => i * 100)
      .duration(200)
      .attrTween('d', (d) => {
        const i = d3.interpolate(d.startAngle, d.endAngle);
        return (t) => {
          d.endAngle = i(t);
          return arc(d);
        };
      });
    donut
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', radius - 150)
      .attr('stroke', 'none')
      .attr('opacity', 0.5)
      .attr('fill', 'white');

    donut
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('class', 'inner-circle')
      .attr('r', radius - 250)
      .attr('stroke', 'white')
      .attr('fill', 'rgb(235,236,236)');

    const getAngle = (d: any) => {
      return ((180 / Math.PI) * (d.startAngle + d.endAngle)) / 2 - 90;
    };

    donut
      .selectAll('text')
      .data(pie)
      .enter()
      .append('text')
      .style('font-size', 40)
      .attr('fill', 'white')
      .attr('transform', (d: any) => {
        let result = `translate(${arcLabel.centroid(d)})`;
        if (d.value < 5) {
          result = result + `rotate(${getAngle(d)})`;
        }
        return result;
      })
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('font-weight', (_, i) => (i ? null : 'bold'))
      .text((d, i) => {
        if (d.value <= 2) return '';
        return d3.format('$.3s')(labels[i]);
      });

    const indicatorSize = 30;
    // let indicatorX: number[] = [fullWidth / 2 + indicatorSize * 2];
    // let indicatorY: number[] = [tenPercent * 2 + indicatorSize * 2];
    // for (let i = 1; i < 5; i++) {
    //   indicatorX[i] = indicatorX[i - 1] - indicatorSize / 2;
    //   indicatorY[i] = indicatorY[i - 1] + indicatorSize * 3;
    // }
    const indicatorX = [850, 855, 840, 810, 760];
    const indicatorY = [300, 400, 500, 600, 700];
    svg
      .selectAll('.indicators')
      .data(names)
      .enter()
      .append('circle')
      .attr('cx', (_, i) => indicatorX[i])
      .attr('cy', (_, i) => indicatorY[i])
      .attr('r', indicatorSize)
      .attr('stroke', 'white')
      .attr('fill', (d, i) => colors[i]);
    svg
      .selectAll('.indicators')
      .data(names)
      .enter()
      .append('text')
      .attr('x', (_, i) => indicatorX[i] - 10)
      .attr('y', (_, i) => indicatorY[i] + 15)
      .style('font-size', 35)
      .style('fill', '#fff')
      .text((d, i) => i + 1);

    svg
      .selectAll('.indicatorsText')
      .data(names)
      .enter()
      .append('text')
      .attr('text-anchor', 'start')
      .attr('dominant-baseline', 'middle')
      .attr('x', (_, i) => indicatorX[i] + indicatorSize * 2)
      .attr('y', (_, i) => indicatorY[i] + indicatorSize / 4)
      .style('font-size', 40)
      .text((_, i) => {
        const size = this.predefinedSize[i];
        let name = names[i];
        if (name.length > size) {
          name = name.slice(0, size - 2).trim() + '..';
        }
        return name;
      });

    svg
      .selectAll('.percentages')
      .data(percentages)
      .enter()
      .append('text')
      .attr('text-anchor', 'end')
      .attr('dominant-baseline', 'middle')
      .attr('x', (_, i) => fullWidth - margin / 2)
      .attr('y', (_, i) => indicatorY[i] + indicatorSize / 4)
      .style('font-size', 40)
      .text((_, i) => percentages[i] + '%');

    svg
      .selectAll('.lines')
      .data(percentages)
      .enter()
      .append('line')
      .attr('x1', (_, i) => indicatorX[i] + indicatorSize * 2)
      .attr('y1', (_, i) => indicatorY[i] + indicatorSize)
      .attr('x2', (_, i) => fullWidth - margin / 2)
      .attr('y2', (_, i) => indicatorY[i] + indicatorSize)
      .attr('stroke', 'black');
  }
}
