import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { Highlight } from 'src/app/models/reports';
import {
  InternalTubesElementMetadata,
  InternalTubesMetadata,
} from './tubes-highlight.metadata';

@Component({
  selector: 'giq-tubes-highlight',
  templateUrl: 'tubes-highlight.component.html',
  styleUrls: ['tubes-highlight.component.scss'],
})
export class TubesHighlightComponent implements OnChanges, AfterViewInit {
  @Input() config: Highlight | undefined;

  public metadata: InternalTubesMetadata = {
    baseColor: 'ffc000',
    differenceColor: '#2e74b6',
    baseLabel: '',
    differenceLabel: '',
    baseValues: [],
  };

  public elements: InternalTubesElementMetadata[] = [];

  constructor() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createSvg();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null) {
      this.metadata = { ...this.metadata, ...this.config?.metadata };
      this.elements = [];

      this.clearGraph();
      this.metadata.baseValues.forEach((x) => {
        this.elements.push({
          id: 'tubes-' + Math.ceil(Math.random() * 10000),
          baseValue: x,
        });
      });
    }
  }

  private clearGraph(): void {
    d3.selectAll('.chartAmount > *').remove();
  }

  private createSvg(): void {
    this.elements.forEach((element: InternalTubesElementMetadata) => {
      const id = `#${element.id}`;
      const svg = d3
        .select(id)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('preserveAspectRatio', 'none')
        .attr('viewBox', '0 0 50 250');

      const differenceValue = 100 - element.baseValue;
      const defs = svg.append('defs');
      const gradient = defs
        .append('linearGradient')
        .attr('id', `${element.id}-gradient`)
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 25)
        .attr('y1', 25)
        .attr('x2', 25)
        .attr('y2', 225);
      if (element.baseValue < 100) {
        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', `${this.metadata.differenceColor}`);
        gradient
          .append('stop')
          .attr('offset', () => {
            return `${differenceValue}%`;
          })
          .attr('stop-color', `${this.metadata.differenceColor}`);
      }

      if (differenceValue < 100) {
        gradient
          .append('stop')
          .attr('offset', `${differenceValue}%`)
          .attr('stop-color', `${this.metadata.baseColor}`);
        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', `${this.metadata.baseColor}`);
      }

      // gray background
      svg
        .append('line')
        .attr('x1', 25)
        .attr('y1', 25)
        .attr('x2', 25)
        .attr('y2', 225)
        .attr('stroke-width', 50)
        .attr('stroke-linecap', 'round')
        .attr('stroke', '#f2f2f2');

      // blue background
      // base is down value
      // difference is up value
      svg
        .append('line')
        .attr('x1', 25)
        .attr('y1', 25)
        .attr('x2', 25)
        .attr('y2', 25)
        .attr('stroke-width', 35)
        .attr('stroke-linecap', 'round')
        .attr('stroke', `url(#${element.id}-gradient)`)
        .transition()
        .duration(800)
        .attr('y2', 225);

      if (differenceValue >= 1) {
        svg
          .append('text')
          .attr('text-anchor', () => {
            return differenceValue <= 10 ? 'end' : 'middle';
          })
          .attr('dominant-baseline', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('font-size', '10')
          .style('font-weight', '200')
          .attr('fill', 'white')
          .attr('x', () => {
            return differenceValue > 10 ? (differenceValue * -2 - 25) / 2 : -10;
          })
          .attr('y', 25)
          .attr('transform', 'rotate(-90)')
          .text(differenceValue + '%');
      }

      if (element.baseValue >= 1) {
        svg
          .append('text')
          .attr('text-anchor', 'start')
          .attr('dominant-baseline', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('font-size', '10')
          .style('font-weight', '200')
          .attr('fill', 'white')
          .attr('x', -240)
          .attr('y', 27.5)
          .attr('transform', 'rotate(-90)')
          .text(element.baseValue + '%');
      }
    });
  }
}
