<form>
  <div class="buttons">
    <span class="label">{{ label }}</span>
    <span class="button" *ngIf="selection.length > 0" (click)="clear()">
      Clear
    </span>
  </div>
  <mat-form-field class="chip-list" appearance="fill">
    <!-- <mat-label>{{ label }}</mat-label> -->
    <input
      matInput
      type="text"
      [placeholder]="placeholder ?? ''"
      #input
      [formControl]="control"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option *ngFor="let element of filteredElements" [value]="element">
        {{ element.value }}
      </mat-option>
    </mat-autocomplete>
    <mat-icon *ngIf="showSearchIcon" matSuffix>search</mat-icon>
  </mat-form-field>

  <mat-chip-grid #chipGrid aria-label="Fruit selection" class="chips">
    <mat-chip-row *ngFor="let selec of selection" (removed)="remove(selec)">
      {{ selec.value }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
</form>
