import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ReportsService } from 'src/app/core/reports.service';
import { Highlight } from 'src/app/models/reports';
import { ReportType } from 'src/app/models/reports/report-type';
import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { EditHighlightsService } from './edit-highlights/highlights.service';
import { AlertService } from 'src/app/core/alerts/alerts.service';
import { GIQError } from 'src/app/core/errors/giq-error';

@Component({
  selector: 'giq-highlights',
  templateUrl: 'highlights.component.html',
  styleUrls: ['highlights.component.scss'],
  providers: [EditHighlightsService],
})
export class HighlightsComponent implements OnInit {
  public containsElements = false;
  public highlights: Highlight[] = [];
  public highlights$: Observable<Highlight[]> = this.reportsService
    .GetHighlights()
    .pipe(
      tap((elements: Highlight[]) => {
        this.containsElements = elements.length > 0;
      }),
      map((elements: Highlight[]) => {
        return elements.sort((a, b) => (a.order ?? 100) - (b.order ?? 100));
      })
    );

  public saving: boolean = false;
  public isSavingProcess: boolean = false;

  constructor(
    private reportsService: ReportsService,
    private router: Router,
    private alertService: AlertService,
    private highlightService: EditHighlightsService
  ) {}

  ngOnInit(): void {
    this.highlights$.subscribe(
      (data: Highlight[]) => {
        this.highlights = data.sort(
          (a, b) => (a.order ?? 100) - (b.order ?? 100)
        );
        this.containsElements = this.highlights.length > 0;
      },
      (error : GIQError) => {
        this.alertService.error(error.errorMessage);
      }
    );
  }

  public openReport(high: Highlight): void {
    if (high.reportCategoryId == ReportType.App) {
      let navigation = this.reportsService.GetUrlMappingForReportApps(high.id);
      this.router.navigate([navigation]);
    } else {
      this.router.navigate(['reports', high.reportId]);
    }
  }

  public ngAfterViewChecked(): void {
    window.dispatchEvent(new Event('resize'));
  }

  dragEntered(event: CdkDragEnter<number>): void {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;
    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement && phContainer) {
      phContainer.removeChild(phElement);
      phContainer.parentElement!.insertBefore(
        phElement,
        dropIndex > dragIndex ? phContainer.nextSibling : phContainer
      );
    }

    moveItemInArray(this.highlights, dragIndex, dropIndex);

    this.highlights.forEach((high, index) => {
      high.order = index;
    });

    this.saving = true;
    this.updateHighlights();
  }

  private updateHighlights(): void {
    this.highlights$ = new Observable((observer) => {
      observer.next([...this.highlights]);
      observer.complete();
    });
  }

  public saveChanges(): void {
    this.isSavingProcess = true;

    const preference = this.highlights.map((high) => ({
      id: high.id,
      active: high.active ?? true,
    }));

    this.highlightService.saveUserPreference(preference).subscribe({
      next: (_) => {
        this.alertService.success('Changes saved successfully');
        this.saving = false;
        this.isSavingProcess = false;
      },
      error: (error: GIQError) => {
        this.saving = false;
        this.alertService.error(error.errorMessage);
      },
    });
  }
}
