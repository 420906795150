<div class="headers">
  <h3 style="margin: 0" class="header-name">Report Catalog</h3>
  <giq-bread-crumbs [crumbs]="crumbs"></giq-bread-crumbs>
</div>

<div class="main-container">
  <ngx-spinner [fullScreen]="false"></ngx-spinner>

  <div class="table-container">
    <table mat-table [dataSource]="records$">
      <ng-container matColumnDef="reportName">
        <th class="header-column align-left" mat-header-cell *matHeaderCellDef>
          Report Name
        </th>
        <td mat-cell *matCellDef="let element" class="column align-left">
          <span>{{ element.name }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="domain">
        <th class="header-column" mat-header-cell *matHeaderCellDef>Domain</th>
        <td mat-cell *matCellDef="let element" class="column">
          <span>{{ element.categoryName }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="analyst">
        <th class="header-column" mat-header-cell *matHeaderCellDef>
          Business Owner
        </th>
        <td mat-cell *matCellDef="let element" class="column">
          <span>{{ element.analyst }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="refreshFrequency">
        <th class="header-column" mat-header-cell *matHeaderCellDef>
          Refresh Frequency
        </th>
        <td mat-cell *matCellDef="let element" class="column">
          <span>{{
            element.refreshFrequency == "ON_DEMAND"
              ? "Real-time"
              : (element.refreshFrequency | pascalCase)
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="info">
        <th class="header-column" mat-header-cell *matHeaderCellDef>Info</th>
        <td mat-cell *matCellDef="let element" class="column">
          <mat-icon class="icon-gray" [giqShowReportDetails]="element.id"
            >info_outline</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="favorite">
        <th class="header-column" mat-header-cell *matHeaderCellDef>
          Favorite
        </th>
        <td mat-cell *matCellDef="let element" class="column">
          <giq-favorite-app
            class="title-favorite"
            [reportId]="element.id"
          ></giq-favorite-app>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="header-column" mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element" class="column">
          <mat-icon
            (click)="goToReport(element)"
            class="material-icons-outlined icon-gray"
          >
            visibility_outline
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnTitles"></tr>
      <tr class="row" mat-row *matRowDef="let row; columns: columnTitles"></tr>
    </table>
  </div>

  <form (submit)="fetchList($event)" class="filters-container">
    <div>
      <h3>Filters</h3>

      <div class="search">
        <div class="searchFilters">
          <mat-form-field appearance="fill" class="filter-select">
            <mat-select [formControl]="category" placeholder="Domain">
              <mat-option>None</mat-option>
              <mat-option
                *ngFor="let category of categories$ | async"
                [value]="category.id"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input
              autocomplete="record-id"
              [formControl]="searchString"
              matInput
              placeholder="Search..."
            />
          </mat-form-field>

          <!-- <mat-form-field appearance="fill" class="filter-select">
            <mat-select [formControl]="dataSource" placeholder="Data Source">
              <mat-option>None</mat-option>
              <mat-option value="2eqo495l10">TABLEAU</mat-option>
              <mat-option value="oghi7v0dme">GLOBEIQ</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
      </div>
    </div>

    <div class="searchBtn">
      <button
        mat-button
        color="primary"
        type="submit"
        [disabled]="disableSearch()"
      >
        Apply Filters
      </button>
      <button mat-button type="button" (click)="clearFilter()">
        Clear Filters
      </button>
    </div>
  </form>
</div>
