import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { userAuthenticated$ } from '../state/user/user.store';

export const authGuard = () => {
  const router = inject(Router);
  return userAuthenticated$.pipe(
    map((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        return isAuthenticated;
      } else {
        return router.createUrlTree(['login'], {
          queryParams: { urlState: router.url },
        });
      }
    })
  );
};
