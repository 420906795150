import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportsService } from 'src/app/core/reports.service';
import { Report } from 'src/app/models/reports';
import { REPORT_ID } from '../reports-details.service';

@Component({
  selector: 'giq-report-details',
  templateUrl: 'report-details.component.html',
  styleUrls: ['report-details.compoent.scss'],
})
export class ReportDetailsComponent implements OnInit {
  public report$: Observable<Report | undefined>;

  constructor(
    private reportsService: ReportsService,
    @Inject(REPORT_ID) reportId: string
  ) {
    this.report$ = this.reportsService.getReport(reportId);
  }

  ngOnInit() {}
}
