import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { userAuthenticated$ } from 'src/app/core/state/user/user.store';
import { environment, securityProviders } from 'src/environments/environment';

@Component({
  selector: 'giq-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public showLoginOptions = false;
  public readyToLogin = false;
  public possibleCompanies: string[] = [];

  private loginCompany: string | null = null;
  private url: string = environment.defaultRoute;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    activatedRoute: ActivatedRoute
  ) {
    this.possibleCompanies = Object.keys(securityProviders);
    let urlState =
      activatedRoute.snapshot.queryParams['urlState'] ??
      environment.defaultRoute;
    if (urlState.includes('logout') || urlState.includes('unauthorized')) {
      urlState = null;
    }
    this.url =
      urlState != null
        ? urlState.split('/').filter(Boolean).join('/')
        : environment.defaultRoute;
    if (environment.fuera) {
      this.loginCompany = activatedRoute.snapshot.params['company'];
      if (
        this.loginCompany != null &&
        !this.possibleCompanies.includes(this.loginCompany)
      ) {
        this.loginCompany = null;
      }

      if (this.loginCompany == null) {
        this.showLoginOptions = true;
        this.readyToLogin = false;
      } else {
        this.showLoginOptions = false;
        this.readyToLogin = true;
      }
    } else {
      this.loginCompany = 'main';
      this.readyToLogin = true;
    }
  }

  ngOnInit() {
    this.listenLogin();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public setAndLogin(loginCompany: string) {
    this.showLoginOptions = false;
    this.loginCompany = loginCompany;
    this.login();
  }

  private listenLogin() {
    this.subscriptions.add(
      userAuthenticated$.subscribe((authenticated) => {
        if (authenticated) {
          this.router.navigate([this.url]);
        } else if (this.readyToLogin === true) {
          this.login();
        }
      })
    );
  }

  private login(): void {
    this.authService.setOAuthConfiguration(this.loginCompany ?? 'main');
    this.authService.login(this.url);
  }
}
