<ng-container *ngIf="reportInfo$ | async as reportInfo; else loadingOrError">
  <div class="headers">
    <div class="report-name">{{ reportInfo.name }}</div>
    <giq-bread-crumbs [crumbs]="crumbs"></giq-bread-crumbs>
  </div>
  <div
    class="content"
    [class.customWidth]="customWidth"
    [style.width]="customWidth ? reportInfo.width : ''"
  >
    <giq-report-problem
      class="problems"
      [id]="reportId"
      [description]="reportDescription"
      type="Report"
    ></giq-report-problem>
    <div id="vizContainer" class="report-viz"></div>
  </div>
</ng-container>

<ng-template #loadingOrError>
  <div class="loading-content">
    <ng-container *ngIf="error$ | async; else loading">
      <span>{{ errorMessage }}</span>
    </ng-container>
    <ng-template #loading>
      <giq-loading class="loading-icon"></giq-loading>
      <span class="loading-text">Loading report. . .</span>
    </ng-template>
  </div>
</ng-template>
