import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import * as geo from 'd3-geo';
import { Highlight } from 'src/app/models/reports';
import { MapMetadata, MapMetadataElement } from './map.metadata';
import { states } from './states';
import { BarsHighlightGroup } from '../bars/bars-highlight.metadata';

@Component({
  selector: 'giq-map-highligh',
  templateUrl: 'map-highlight.component.html',
  styleUrls: ['map-highlight.component.scss'],
})
export class MapHighlightComponent implements AfterViewInit, OnChanges {
  @Input() config: Highlight | undefined;

  public id: string = 'map-' + Math.ceil(Math.random() * 10000);
  public selectedIndex = 0;
  public selectedElement: MapMetadataElement | undefined;
  public elements: MapMetadataElement[] = [];
  public single = false;

  private translateX = 385;
  private translateY = 225;
  private width = 750;
  private height = 450;
  private scale = 1000;
  private svgStates:
    | d3.Selection<SVGPathElement, any, SVGSVGElement, unknown>
    | undefined = undefined;

  ngAfterViewInit(): void {
    this.createSvg();
    this.updateStateColors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config != null) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => {
        return this.getSubtitles();
      };
      let metadata: MapMetadata = { elements: [] };
      metadata = { ...metadata, ...this.config?.metadata };
      if (metadata.elements.length > 2) {
        this.elements = [metadata.elements[0], metadata.elements[1]];
      } else {
        this.elements = metadata.elements ?? [];
      }

      if (this.elements.length > 0) {
        this.selectedElement = this.elements[0];
      }

      this.single = this.elements.length == 1;

      this.updateStateColors();
    }
  }
  private getSubtitles(): string[] {
    return this.elements.map((item: MapMetadataElement) => {
      return item.label;
    });
  }

  public updateChartData(index: number) {
    this.selectedIndex = index;
    this.selectedElement = this.elements[this.selectedIndex];
    this.updateStateColors();
  }

  private updateStateColors() {
    if (this.svgStates != null) {
      this.svgStates.style('fill', (d) => {
        if (this.selectedElement?.states.includes(d.id)) {
          return this.selectedElement.color;
        }
        return '#eaebf1';
      });
    }
  }

  private createSvg(): void {
    const projection = geo
      .geoAlbersUsa()
      .translate([this.translateX, this.translateY])
      .scale(this.scale);
    const path = geo.geoPath().projection(projection);
    const selector = '#' + this.id;
    const svg = d3
      .select(selector)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`);

    this.svgStates = svg
      .selectAll('path')
      .data(states.features)
      .enter()
      .append('path')
      .attr('d', path as any)
      .style('stroke', 'white')
      .style('stroke-width', '2')
      .style('fill', (d) => {
        return '#eaebf1';
      });
  }
}
