import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { Highlight } from 'src/app/models/reports';

import {
  BarChartMetadata,
  BarChartElement,
} from './horizontal-bar-highlight.metadata';

@Component({
  selector: 'giq-horizontal-bar-highlight',
  templateUrl: './horizontal-bar-highlight.component.html',
  styleUrls: ['./horizontal-bar-highlight.component.scss'],
})
export class HorizontalBarChartComponent implements AfterViewInit, OnChanges {
  @Input() config: Highlight | undefined;

  public metadata: BarChartMetadata = {
    data: [],
    barColor: '85,170,167',
  };

  public finalData: BarChartElement[] = [];

  @ViewChild('horizontalBar', { static: true }) chartContainer!: ElementRef;

  ngAfterViewInit(): void {
    if (this.metadata) {
      this.processInfo();
      this.createChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null) {
      const newMetadata = { ...this.metadata, ...this.config?.metadata };
      this.metadata = newMetadata;
      this.processInfo();
      this.createChart();
    }
  }

  private processInfo(): void {
    this.finalData = this.metadata.data.sort((a, b) => b.yValue - a.yValue);
  }

  private createChart(): void {
    const data = this.finalData;
    const element = this.chartContainer.nativeElement;
  
    d3.select(element).selectAll('svg').remove();
    d3.select(element).selectAll('*').remove();
  
    const numberOfBars = data.length;
    let barHeight = 30;
    let paddingBetweenBars = 10;
  
    if (numberOfBars == 5) {
      barHeight = 30;
      paddingBetweenBars = 7.5;
    } else {
      if (numberOfBars >= 2 && numberOfBars <= 3) {
        barHeight = 57;
        paddingBetweenBars = 12.5;
      } else {
        barHeight = 30;
      }
    }
  
    const margin = { top: 10, right: 30, bottom: 50, left: 140 };
    const width = 500 - margin.left - margin.right;
    const height =
      numberOfBars * (barHeight + paddingBetweenBars) +
      margin.top +
      margin.bottom;
  
    const formatValue = (value: number) => {
      if (value < 1000) return value.toString();
      return d3.format('.2s')(value).replace(/\.0$/, '');
    };
  
    const svg = d3
      .select(element)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .attr('preserveAspectRatio', 'none')
      .append('g')
      .attr('transform', `translate(${margin.left-70}, ${margin.top})`);
  
    const x = d3
      .scaleLinear()
      .domain([1, d3.max(data, (d) => d.yValue)!])
      .range([0, width * 0.9]);
  
    const y = d3
      .scaleBand()
      .domain(data.map((d) => d.key))
      .range([0, numberOfBars * (barHeight + paddingBetweenBars)])
      .padding(0.1);
  
    // Create a tooltip div and hide it initially
    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'white')
      .style('border', '1px solid #ccc')
      .style('padding', '5px')
      .style('border-radius', '4px')
      .style('font-size', '12px');
  
    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', x(0))
      .attr('y', (d) => y(d.key)!)
      .attr('width', 0)
      .attr('height', barHeight)
      .attr('fill', 'rgb(' + this.metadata.barColor + ')')
      .on('mouseover', (event, d) => {
        tooltip
          .html(formatValue(d.yValue))
          .style('visibility', 'visible');
      })
      .on('mousemove', (event) => {
        tooltip
          .style('top', event.pageY - 10 + 'px')
          .style('left', event.pageX + 10 + 'px');
      })
      .on('mouseout', () => {
        tooltip.style('visibility', 'hidden');
      })
      .transition()
      .duration(800)
      .attr('width', (d) => Math.max(x(d.yValue), 1));
  
    svg
      .selectAll('.category')
      .data(data)
      .enter()
      .append('text')
      .attr('x', -17)
      .attr('y', (d) => y(d.key)! + barHeight / 2 + 5)
      .attr('text-anchor', 'end')
      .style('fill', 'black')
      .style('font-size', '1.2rem')
      .text((d) => d.key);
  }
  
}
