import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProblemWindowComponent } from './problem-window/problem-window.component';

@Component({
  selector: 'giq-report-problem',
  templateUrl: 'report-problem.component.html',
  styleUrls: ['report-problem.component.scss'],
})
export class ReportProblemComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  @Input() id: string | undefined;
  @Input() type: string | undefined;
  @Input() description: string | undefined;

  ngOnInit() {}

  public openWindow() {
    const dialogRef = this.dialog.open(ProblemWindowComponent, {
      data: { id: this.id, type: this.type, description: this.description },
      closeOnNavigation: true,
      disableClose: true,
      panelClass: 'problem-panel',
    });
  }
}
