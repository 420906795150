import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { map, Observable } from 'rxjs';
import { ConfirmActionDialogComponent } from 'src/app/common/confirm-action-dialog/confirm-action-dialog.component';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private options: Partial<IndividualConfig> = {
    positionClass: 'giq-toast-top-right',
    enableHtml: true,
    tapToDismiss: true,
  };
  constructor(private toastr: ToastrService, private dialog: MatDialog) {}

  public error(
    message: string,
    title: string | null = null,
    options: Partial<IndividualConfig> | null = null
  ): void {
    const finalOptions =
      options != null ? { ...this.options, ...options } : this.options;
    this.toastr.error(message, title ?? 'Error', finalOptions);
  }

  public warning(
    message: string,
    options: Partial<IndividualConfig> | null = null
  ): void {
    const finalOptions =
      options != null ? { ...this.options, ...options } : this.options;
    this.toastr.warning(message, undefined, finalOptions);
  }

  public success(
    message: string,
    options: Partial<IndividualConfig> | null = null
  ): void {
    const finalOptions =
      options != null ? { ...this.options, ...options } : this.options;
    this.toastr.success(message, undefined, finalOptions);
  }

  public confirmDialog(
    title: string,
    message: string,
    params?: { [key: string]: object }
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      disableClose: true,
      data: {
        title,
        message,
        params: params,
      },
    });

    return dialogRef.afterClosed().pipe(
      map((data: boolean) => {
        return data;
      })
    );
  }
}
