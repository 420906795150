<ngx-spinner [fullScreen]="false"></ngx-spinner>
<div class="headers">
  <h3 class="header-name">FHD Hierarchy</h3>
  <giq-bread-crumbs [crumbs]="crumbs"></giq-bread-crumbs>
</div>

<div class="main-container">
  <div class="menu">
    <button
      mat-button
      type="button"
      (click)="configureAgencies()"
      *ngIf="isSuperAdmin && agenciesLoaded"
      giq-interaction
    >
      Configure Agencies
    </button>
    <mat-form-field
      appearance="fill"
      class="filter-select"
      *ngIf="showAgencies"
    >
      <mat-label>Agency</mat-label>
      <mat-select
        [(value)]="selectedAgency"
        (selectionChange)="agencyChanged()"
      >
        <mat-option *ngFor="let item of agencies" [value]="item">{{
          item.agentName
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-button
      type="button"
      (click)="configureAdministrators()"
      *ngIf="allowAdminConfiguration && selectedAgency != null"
      giq-interaction
    >
      Configure Administrators
    </button>
    <div class="buttons">
      <button
        mat-button
        type="button"
        (click)="save()"
        *ngIf="modifying"
        giq-interaction
      >
        Save
      </button>
      <button
        mat-button
        type="button"
        (click)="discard()"
        *ngIf="modifying"
        giq-interaction
      >
        Discard
      </button>
    </div>
  </div>
  <giq-org-chart
    [agency]="selectedAgency"
    [data]="data$ | async"
    [agencyUsers]="agencyUsers"
    (onModifying)="onModifyingHierarchy($event)"
    [canModify]="canModify"
  ></giq-org-chart>
</div>
