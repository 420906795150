<ng-container *ngIf="highlights$ | async as highs">
  <ng-container *ngIf="containsElements">
    <div class="title">
      <h3>Highlights</h3>
      <mat-icon *ngIf="saving && !isSavingProcess" class="button-link" (click)="saveChanges()">save</mat-icon>
      <mat-icon matTooltip="Edit" [routerLink]="['/highlights']">settings</mat-icon>
      <giq-small-load *ngIf="isSavingProcess"></giq-small-load>
    </div>
    <div class="highlights-content" cdkDropListGroup>
      <div class="highlight-list">
        <div class="highlight-container" *ngFor="let high of highs; let i = index" cdkDropList [cdkDropListData]="i">
          <div class="highlight" (click)="openReport(high)" cdkDrag
               [cdkDragData]="i" 
               (cdkDragEntered)="dragEntered($event)">
            <giq-highlight [config]="high"></giq-highlight>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
