import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'giq-divider',
    templateUrl: 'divider.component.html',
    styleUrls: ['divider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DividerComponent {
    @Input() title: string | undefined;
}
