<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content class="content">
  <mat-form-field>
    <input tabindex="1" matInput [formControl]="search" />
  </mat-form-field>
  <mat-selection-list (selectionChange)="selectionChanged($event)">
    <mat-list-option
      *ngFor="let agent of viewingAgents | async"
      [value]="agent"
      [selected]="selectedAgents.includes(agent)"
    >
      {{ agent.agentName }} - {{ agent.agentNumber }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    (click)="submit()"
    [disabled]="
      (!!data.allowEmpty === false && selectedAgents.length === 0) ||
      (data.allowEmpty === true && somethingChanged === false)
    "
  >
    Confirm
  </button>
  <button mat-button class="close" (click)="cancel()">Cancel</button>
</mat-dialog-actions>
