import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { StockValue } from './stock-value';
import { StockService } from './stock.service';

@Component({
  selector: 'giq-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
  providers: [StockService],
})
export class StockComponent implements OnInit {
  public stock: StockValue = {
    change: '0.0',
    percentage: '0%',
    updated: 'not available',
    value: '$0.0',
  };
  public negative: boolean = false;
  constructor(private stockService: StockService) {}

  ngOnInit(): void {
    this.stockService
      .getStockValue()
      .pipe(take(1))
      .subscribe((x) => {
        this.stock = x;
        this.negative = x.change.startsWith('-');
      });
  }
}
