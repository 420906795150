<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content class="mat-typography content">
  <div class="data-entry">
    {{ data.message }}
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="onSubmit()">Confirm</button>
  <button mat-button class="close" mat-dialog-close (click)="onCancel()">
    Cancel
  </button>
</mat-dialog-actions>
