import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { LottieModule } from 'ngx-lottie';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from './charts/charts.module';
import { GiqCommonModule } from './common/common-module';
import { HeaderComponent } from './common/header/header.component';
import { LandingComponent } from './common/landing/landing.component';
import { CallbackComponent } from './common/login/callback/callback.component';
import { LoginComponent } from './common/login/login.component';
import { LogoutComponent } from './common/login/logout/logout.component';
import { MenuComponent } from './common/menu/menu.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { NotificationsComponent } from './common/notifications/notifications.component';
import { RecordsResultModalComponent } from './common/search-bar/records-result-modal/records-result-modal.component';
import { SearchBarComponent } from './common/search-bar/search-bar.component';
import { StockComponent } from './common/stock/stock.component';
import { ToDoComponent } from './common/to-do/to-do.component';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { UserInfoComponent } from './common/user-info/user-info.component';
import { AccessTokenInterceptor } from './core/auth/access-token.interceptor';
import { ApiKeyInterceptor } from './core/auth/api-key.interceptor';
import { AuthService } from './core/auth/auth.service';
import { LocalAuthService } from './core/auth/localauth.service';
import { OutAuthService } from './core/auth/out-auth.service';
import { RefreshInterceptor } from './core/auth/refresh.interceptor';
import { UnauthorizedInterceptor } from './core/auth/unauthorized.interceptor';
import { DialogComponent } from './core/dialog/dialog.component';
import { IconRegistryService } from './core/icon-registry/icon-registry';
import { TopOverlayComponent } from './core/top-menu/top-overlay/top-overlay.component';
import { DataExchangeComponent } from './data-exchange/data-exchange.component';
import { EditHighlightsComponent } from './reports/highlights/edit-highlights/edit-highlights.component';
import { AreaHighlightComponent } from './reports/highlights/highlight/area/area-highlight.component';
import { BarsHighlightComponent } from './reports/highlights/highlight/bars/bars-highlight.component';
import { ComplexDonutHighlightComponent } from './reports/highlights/highlight/complex-donut/complex-donut-highlight.component';
import { DonutHighlightComponent } from './reports/highlights/highlight/donut/donut-highlight.component';
import { DualHighlightComponent } from './reports/highlights/highlight/dual/dual-highlight.component';
import { HighlightComponent } from './reports/highlights/highlight/highlight.component';
import { ImageHighlightComponent } from './reports/highlights/highlight/image/image-highlight.component';
import { LineHighlightComponent } from './reports/highlights/highlight/line/line-highlight.component';
import { MapHighlightComponent } from './reports/highlights/highlight/map/map-highlight.component';
import { SalesScrollHighlightComponent } from './reports/highlights/highlight/sales-scroll/sales-scroll-highlight.component';
import { ScatterplotHighlightComponent } from './reports/highlights/highlight/scatterplot/scatterplot-highlight.component';
import { SplitTextHighlightComponent } from './reports/highlights/highlight/split-text/split-text-highlight.component';
import { TubesHighlightComponent } from './reports/highlights/highlight/tubes/tubes-highlight.component';
import { HighlightsComponent } from './reports/highlights/highlights.component';
import { ReportCatalogComponent } from './reports/report-catalog/report-catalog.component';
import { ReportDetailsComponent } from './reports/report-details/report-details.component';
import { ShowReportDetailsDirective } from './reports/report-details/report-details.directive';
import { ReportComponent } from './reports/report/report.component';
import { ReportsDetailsService } from './reports/reports-details.service';
import { ReportsComponent } from './reports/reports/reports.component';
import { WorkspaceCategoryComponent } from './reports/workspace-category/workspace-category.component';
import { WorkspaceComponent } from './reports/workspace/workspace.component';
import { FaqComponent } from './support/faq/faq.component';
import { SupportComponent } from './support/support.component';
import { HorizontalBarChartComponent } from './reports/highlights/highlight/horizontal-bar/horizontal-bar-highlight.component';
import { SectorHighlightComponent } from './reports/highlights/highlight/sector/sector-highlight.component';
import { VerticalBarHighlightComponent } from './reports/highlights/highlight/vertical-bar/vertical-bar-highlight.component';
import { FhdHierarchyComponent } from './fhd-hierarchy/fhd-hierarchy.component';
import { FhdInputFormComponent } from './fhd-input-form/fhd-input-form.component';
import { OrgChartComponent } from './fhd-hierarchy/org-chart/org-chart.component';
import { AgentSelectorComponent } from './fhd-hierarchy/agent-selector/agent-selector.component';
import { GrowthAdjustmentsInputFormComponent } from './growth-adjustments-input-form/growth-adjustments-input-form.component';
import {  LineOverBarsHighlightComponent } from './reports/highlights/highlight/line-over-bars/line-over-bars-highlight.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}
// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    ImageHighlightComponent,
    HighlightsComponent,
    UnauthorizedComponent,
    AppComponent,
    HeaderComponent,
    UserInfoComponent,
    NotificationsComponent,
    ToDoComponent,
    StockComponent,
    SearchBarComponent,
    ShowReportDetailsDirective,
    TopOverlayComponent,
    ReportDetailsComponent,
    MenuComponent,
    LandingComponent,
    ReportsComponent,
    NotFoundComponent,
    WorkspaceComponent,
    WorkspaceCategoryComponent,
    CallbackComponent,
    LogoutComponent,
    LoginComponent,
    ReportComponent,
    DataExchangeComponent,
    DialogComponent,
    ReportCatalogComponent,
    SupportComponent,
    FaqComponent,
    RecordsResultModalComponent,
    EditHighlightsComponent,
    HighlightComponent,
    SplitTextHighlightComponent,
    MapHighlightComponent,
    SalesScrollHighlightComponent,
    TubesHighlightComponent,
    AreaHighlightComponent,
    DonutHighlightComponent,
    LineHighlightComponent,
    DualHighlightComponent,
    ComplexDonutHighlightComponent,
    BarsHighlightComponent,
    ScatterplotHighlightComponent,
    HorizontalBarChartComponent,
    SectorHighlightComponent,
    VerticalBarHighlightComponent,
    FhdHierarchyComponent,
    FhdInputFormComponent,
    OrgChartComponent,
    AgentSelectorComponent,
    GrowthAdjustmentsInputFormComponent,
    LineOverBarsHighlightComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    ToastrModule.forRoot(),
    NgxSpinnerModule.forRoot({ type: 'ball-grid-pulse' }),
    LottieModule.forRoot({ player: playerFactory }),
    GiqCommonModule.forRoot(),
    ChartsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: AuthService,
      useClass: environment.production
        ? environment.fuera === true
          ? OutAuthService
          : AuthService
        : LocalAuthService,
    },
    {
      provide: ReportsDetailsService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(_: IconRegistryService) {
    console.log(environment.version);
  }
}
