<mat-toolbar class="main-toolbar">
  <div
    class="logo not-mobile"
    [routerLink]="'/home'"
    giq-interaction
    [style]="'--version:\'' + version + '\''"
    matTooltipPosition="below"
    matTooltipClass="blue-tooltip"
  >
    <img src="assets/globeiq_full_logo.svg" />
  </div>
  <giq-menu class="mobile" [mobile]="true"></giq-menu>
  <div class="search">
    <giq-search-bar></giq-search-bar>
  </div>
  <div class="extras">
    <giq-stock class="extra"></giq-stock>
    <!-- <giq-to-do class="extra"></giq-to-do> -->
    <giq-notifications class="extra"></giq-notifications>
    <giq-user-info></giq-user-info>
  </div>
</mat-toolbar>
