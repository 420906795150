<div class="main-container" *ngIf="showLoginOptions; else loading">
  <img src="assets/GlobeIQ_withTagline.svg" />
  <div class="companies">
    <button
      mat-stroked-button
      *ngFor="let company of possibleCompanies"
      (click)="setAndLogin(company)"
    >
      {{ company }}
    </button>
  </div>
</div>

<ng-template #loading>
  <div class="main-container">
    <img src="assets/GlobeIQ_withTagline.svg" />
    <div class="message">Establishing secured connection...</div>
  </div>
</ng-template>
