import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { AreasChart, AreasChartElement } from './area.model';

@Component({
  selector: 'giq-area-chart',
  templateUrl: 'area-chart.component.html',
  styleUrls: ['area-chart.component.scss'],
})
export class AreaChartComponent implements AfterViewInit, OnChanges {
  @Input() info: AreasChart | null = null;
  @Input() public errorMessageChart: string | null = null;
  @Input() public loadingChart = true;
  @Input() public hideIndicators = false;

  public data: AreasChartElement[] = [];
  public names: { [key: string]: string } = {};

  public keys: string[] = [];
  public color: any;
  public id: string = 'area-chart-' + Math.ceil(Math.random() * 100000);

  constructor() {}

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { info } = changes;
    if (info != null && info.firstChange === false) {
      d3.selectAll(`#${this.id} > *`).remove();
      this.initializeChart();
    }
  }

  public highlight(key: string) {
    d3.selectAll('.areas').style('opacity', 0.1);
    d3.selectAll(`#${key}`).style('opacity', 1);
  }

  public unhighlight() {
    d3.selectAll('.areas').style('opacity', 0.8);
  }

  private initializeChart(): void {
    if (this.info == null) {
      return;
    }
    this.errorMessageChart = null;
    this.loadingChart = true;
    this.data = this.info.data;
    this.names = this.info.names;
    if (this.data.length > 0) {
      this.keys = Object.keys(this.data[0]).filter((x) => x != 'date');
      this.calculateColors(this.info.colors);
      this.createChart();
      this.loadingChart = false;
    } else {
      this.loadingChart = true;
      this.errorMessageChart = 'No data matches the current filters.';
    }
  }

  private calculateColors(colors: string[]) {
    this.color = d3.scaleOrdinal().domain(this.keys).range(colors);
  }

  private createChart(): void {
    const margin = { top: 20, right: 30, bottom: 30, left: 55 },
      width = 3600 - margin.left - margin.right,
      height = 1500 - margin.top - margin.bottom;

    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .attr('preserveAspectRatio', 'none')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Add X axis
    let domainX: [Date, Date] = d3.extent(this.data, (d: AreasChartElement) => {
      return d.date;
    }) as [Date, Date];

    let x = d3.scaleTime().domain(domainX).range([0, width]);

    let xAxis = svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x).ticks(this.data.length, '%d'));
    xAxis.selectAll('.tick text').style('font-size', '15');

    // Add Y axis
    const flatValues: number[] = this.data.flatMap((value: any) => {
      let result: number[] = [];
      this.keys.forEach((x) => {
        result.push(value[x]);
      });
      return result;
    });

    let domainY: [number, number] = d3.extent(flatValues) as [number, number];
    let y = d3
      .scaleLinear()
      .domain([0, domainY[1]])
      .range([height, margin.bottom]);
    let yAxis = svg
      .append('g')
      .call(d3.axisLeft(y).ticks(10).tickSize(3).tickFormat(d3.format('.2s')));
    yAxis.selectAll('.tick text').style('font-size', '30');

    let area = (key: string, zero: boolean) =>
      d3
        .area()
        .curve(d3.curveLinear)
        .x((d: any, i) => {
          return x(d.date);
        })
        .y0(y(0))
        .y1((d: any) => {
          const val = zero ? 0 : d[key];
          return y(val);
        });

    const areas = svg.append('g');

    this.keys.forEach((key) => {
      // Show the areas
      areas
        .append('path')
        .datum(this.data as any)
        .attr('class', 'areas')
        .attr('id', key)
        .style('opacity', '0.8')
        .style('fill', (d) => {
          const c: string = this.color(key) as string;
          return c;
        })
        .attr('d', area(key, true))
        .transition()
        .duration(1000)
        .attr('d', area(key, false));
    });
  }
}
