import { Component, ElementRef, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  TopMenuConfig,
  TopMenuItem,
  TopMenuService,
} from 'src/app/core/top-menu';
import { IToDo, IToDoAction, dummyTodos } from './to-do.model';
import { ToDoService } from './to-do.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'giq-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.scss'],
  providers: [TopMenuService],
})
export class ToDoComponent implements OnInit {
  private itemsEmitter: BehaviorSubject<TopMenuItem[] | undefined> =
    new BehaviorSubject<TopMenuItem[] | undefined>(undefined);

  private menuConfig: TopMenuConfig = {
    title: 'ToDo',
    items: this.itemsEmitter.asObservable(),
    panelClass: 'notifications-panel',
    showMoreFunction: () => alert('Showing more'),
    showMore: false,
    limitItems: 5,
    closeOnItemClick: true,
    noElements: 'Nothing to do',
  };

  constructor(
    private topMenuService: TopMenuService,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
    private router: Router,
    private todoService: ToDoService
  ) {}

  todosCount: number = 0;
  todos: IToDoAction[] = [];
  subs: Subscription[] = [];
  loading: boolean = false;
  clickedIds: string[] = [];

  ngOnDestroy(): void {
    this.itemsEmitter.complete();
  }

  ngOnInit(): void {
    this.loading = true;
    const sub = this.todoService
      .getTodos()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res) => {
        this.todosCount = res.filter((todo) => !todo.completed).length;
      });
    this.subs.push(sub);

    this.topMenuService.configureService(this.viewContainerRef);
  }

  public openTodos() {
    this.loading = true;
    const sub = this.todoService
      .getTodos()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res) => {
        const items = res.map((todo: IToDo) => {
          return {
            ...todo,
            content: todo.message,
            importance: todo.importance,
            numberIcon: todo.indicator,
            title: todo.title,
            onClick: () => {
              if (todo.action?.length) {
                this.router.navigate(todo.action);
              }
            },
          };
        });
        this.itemsEmitter.next(items as any);
        let overlay = this.topMenuService.openMenu(
          this.menuConfig,
          this.elementRef
        );
      });
    this.subs.push(sub);
  }
}
