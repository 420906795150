import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { TOP_MENU_CONFIG, TOP_MENU_OVERLAY_REF } from '../top-menu-service';
import { TopMenuConfig, TopMenuItem } from '../top-menu.models';

@Component({
  templateUrl: './top-overlay.component.html',
  styleUrls: ['./top-overlay.component.scss'],
})
export class TopOverlayComponent implements OnInit, OnDestroy {
  private _items$: BehaviorSubject<TopMenuItem[] | undefined> =
    new BehaviorSubject<TopMenuItem[] | undefined>(undefined);
  public items$: Observable<TopMenuItem[] | undefined> =
    this._items$.asObservable();
  public numberOfItems = 0;
  constructor(
    @Inject(TOP_MENU_CONFIG) public config: TopMenuConfig,
    @Inject(TOP_MENU_OVERLAY_REF) private overlayRef: OverlayRef
  ) {}

  ngOnDestroy(): void {
    this._items$.complete();
  }

  ngOnInit(): void {
    this.getItems();
  }

  public seeMore() {
    if (this.config.showMoreFunction != null) {
      this.config.showMoreFunction();
    }
  }

  private getItems(): void {
    if (Array.isArray(this.config.items) || this.config.items == null) {
      let result = this.limitItems(this.config.items);
      this._items$.next(result);
    } else {
      this.items$ = this.config.items.pipe(
        map((items: TopMenuItem[] | undefined) => {
          if (items != null) {
            return this.limitItems(items);
          }
          return undefined;
        })
      );
    }
  }

  public alertClick(item: TopMenuItem): void {
    if (item.onClick != null) {
      if (this.config.closeOnItemClick === true) {
        this.overlayRef.dispose();
      }
      item.onClick(item);

      if (item.read !== undefined) {
        item.read = true;
      }
    }
  }

  private limitItems(items: TopMenuItem[]) {
    let result: TopMenuItem[] = [];
    this.numberOfItems = items.length;
    if (this.config.showMore) {
      result = items.slice(0, this.config.limitItems);
    } else {
      result = items;
    }
    return result;
  }
}
