import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'giq-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public version: string = environment.version;
}
