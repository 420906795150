import { Injectable } from "@angular/core";
import { IndividualConfig, ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class AlertService {
    private options: Partial<IndividualConfig> = { positionClass: 'giq-toast-top-right' };
    constructor(private toastr: ToastrService) {

    }

    public error(message: string, title: string | null = null): void {
        this.toastr.error(message, title ?? 'Error', this.options);
    }

    public warning(message: string): void {
        this.toastr.warning(message, undefined, this.options);
    }

    public success(message: string): void {
        this.toastr.success(message, undefined, this.options);
    }
}