import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
    selector: 'giq-dialog',
    templateUrl: 'dialog.component.html',
})
export class DialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        private router: Router
    ) { }

    login(): void {
        this.router.navigate(['login'], { queryParams: { urlState: this.router.url } })
        this.dialogRef.close();
    }
}