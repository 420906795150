import { Component } from '@angular/core';
import { catchError, EMPTY, Observable, Subject } from 'rxjs';
import { GIQError } from 'src/app/core/errors/giq-error';
import { noReports$ } from 'src/app/core/state/reports/reports.store';
import { Category } from 'src/app/models/reports/category';
import { ReportsService } from '../../core/reports.service';
import { ReportsDetailsService } from '../reports-details.service';

@Component({
  selector: 'giq-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
  providers: [ReportsDetailsService],
})
export class WorkspaceComponent {
  public error$: Subject<boolean> = new Subject<boolean>();
  public categories$: Observable<Category[]>;
  public noReports$: Observable<boolean> = noReports$;
  public errorMessage: string = '';

  constructor(private reportsService: ReportsService) {
    this.categories$ = this.reportsService.GetWorkspaceCategories().pipe(
      catchError((error: GIQError) => {
        this.errorMessage = error.errorMessage;
        this.error$.next(true);
        return EMPTY;
      })
    );
  }
}
