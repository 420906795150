<div class="bread-crumbs">
  <ng-container *ngFor="let c of crumbs; first as isFirst; last as isLast">
    <mat-icon svgIcon="arrow-small-left" *ngIf="isFirst"></mat-icon>
    <span
      *ngIf="c.url != null"
      [routerLink]="[c.url]"
      [queryParams]="c.query"
      routerLinkActive="router-link-active"
      giq-interaction
      class="link"
      >{{ c.name }} {{ !isLast ? "/" : "" }}</span
    >
    <span
      *ngIf="c.url == null"
      routerLinkActive="router-link-active"
      giq-interaction
      >{{ c.name }} {{ !isLast ? "/" : "" }}</span
    >
  </ng-container>
</div>
