export interface LineHighlightMetadata {
    data: LineHighlightElement[];
    colors: string[];
    timeScale: TimeScale;
    division: string;
  }
  
  export interface LineHighlightElement {
    key: string;
    date: Date;
    yValue: number;
    
  }

  export enum TimeScale {
    Monthly = 'Monthly',
    Yearly = 'Yearly',
    Weekly = 'Weekly',
  }
  
  