import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICONS } from './icons';

@Injectable({ providedIn: 'root' })
export class IconRegistryService {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    for (const icon of ICONS) {
      matIconRegistry.addSvgIcon(
        icon,
        domSanitizer.bypassSecurityTrustResourceUrl(
          `../../../../assets/icons/${icon}.svg`
        )
      );
    }
  }
}
