import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[giqWholeNumberOnly]',
})
export class WholeNumberOnlyDirective {
  constructor() {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '.') {
      event.preventDefault();
    }
  }
  @HostListener('paste', ['$event'])
  onPasteEvent(event: any) {
    if (!new RegExp(/^\d+$/).test(event.clipboardData?.getData('text') ?? '')) {
      event.preventDefault();
    }
  }
}
