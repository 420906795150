import { Component, Input, OnInit } from '@angular/core';
import { Highlight } from 'src/app/models/reports';

@Component({
    selector: 'giq-image-highlight',
    templateUrl: 'image-highlight.component.html',
    styleUrls: ['image-highlight.component.scss']
})

export class ImageHighlightComponent implements OnInit {
    @Input() config: Highlight | undefined;
    constructor() { }

    ngOnInit() { }
}