<div class="highlight-container" *ngIf="config != null">
  <div class="header-container">
    <div class="report-name-section" [matTooltip]="config.reportName">
      {{ config.reportName }}
    </div>
    <div class="sub-title-section" [matTooltip]="config.metadata['subtitle']">
      {{ config.metadata["subtitle"] }}
    </div>
    <div class="action-section">
      <div class="toggle-group" *ngIf="getActionData(config) as actionData">
        <span (click)="handleToggle(i, $event)" class="toggle-item" *ngFor="let toggle of actionData; index as i"
          [class.active]="i == selectedIndex">{{toggle}}</span>
        <div class="toggle-highlight" [ngStyle]="{'transform': 'translateX(' + ((selectedIndex * 50)-(25 * (actionData.length -1))) + 'px)'}">
        </div>
      </div>     
    </div>
  </div>
  <ng-container ngIf="config !=null"
    *ngTemplateOutlet="templates[config!.type]; context: { config: config }"></ng-container>
</div>
<ng-template #image let-config="config">
  <giq-image-highlight [config]="config"></giq-image-highlight>
</ng-template>

<ng-template #splitText let-config="config">
  <giq-split-text-highlight [config]="config"></giq-split-text-highlight>
</ng-template>

<ng-template #map let-config="config">
  <giq-map-highligh [config]="config"></giq-map-highligh>
</ng-template>

<ng-template #salesScroll let-config="config">
  <giq-sales-scroll-highlight [config]="config"></giq-sales-scroll-highlight>
</ng-template>

<ng-template #tubes let-config="config">
  <giq-tubes-highlight [config]="config"></giq-tubes-highlight>
</ng-template>

<ng-template #area let-config="config">
  <giq-area-highlight [config]="config"></giq-area-highlight>
</ng-template>

<ng-template #donut let-config="config">
  <giq-donut-highlight [config]="config"></giq-donut-highlight>
</ng-template>

<ng-template #line let-config="config">
  <giq-line-highlight [config]="config"></giq-line-highlight>
</ng-template>

<ng-template #dual let-config="config">
  <giq-dual-highlight [config]="config"></giq-dual-highlight>
</ng-template>

<ng-template #complexDonut let-config="config">
  <giq-complex-donut-highlight [config]="config"></giq-complex-donut-highlight>
</ng-template>

<ng-template #bars let-config="config">
  <giq-bars-highlight [config]="config"></giq-bars-highlight>
</ng-template>

<ng-template #scatterplot let-config="config">
  <giq-scatterplot-highlight [config]="config"></giq-scatterplot-highlight>
</ng-template>

<ng-template #horizontalBar let-config="config">
  <giq-horizontal-bar-highlight
    [config]="config"
  ></giq-horizontal-bar-highlight>
</ng-template>

<ng-template #sector let-config="config">
  <giq-sector-highlight [config]="config"></giq-sector-highlight>
</ng-template>

<ng-template #verticalBar let-config="config">
  <giq-vertical-bar-highlight [config]="config"></giq-vertical-bar-highlight>
</ng-template>