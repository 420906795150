import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { INotification, dummyNotifications } from './notifications.model';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private httpClient: HttpClient) {}

  getNotifications() {
    // return of(dummyNotifications).pipe(delay(1000));
    return this.httpClient.get<INotification[]>(`${environment.apiUrl}/alerts`);
  }

  markAsRead(ids: string[]) {
    // return of(dummyNotifications).pipe(delay(1000));
    return this.httpClient.post<any>(
      `${environment.apiUrl}/alerts/markAsRead`,
      {
        ids,
        read: true,
        readAt: new Date(),
      }
    );
  }
}
