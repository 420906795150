import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IToDo, dummyTodos } from './to-do.model';
import { environment } from 'src/environments/environment';
import { of, delay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToDoService {
  constructor(private httpClient: HttpClient) {}

  getTodos() {
    // return of(dummyTodos).pipe(delay(1000));
    return this.httpClient.get<IToDo[]>(`${environment.apiUrl}/todos`);
  }

  // modifyToDo(ids: string[]) {
  //   return this.httpClient.put<any>(
  //     `${environment.apiUrl}/todos/markAsRead`,
  //     {
  //       ids,
  //       read: true,
  //       readAt: new Date(),
  //     }
  //   );
  // }
}
