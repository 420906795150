<div class="highlight" *ngIf="config">
  <div class="chart-container">
   <div #sector class="chart"></div>

  <div class="labels-container">
    <div class="labels-table scroll">
      <table>
        <tbody>
          <tr 
          *ngFor="let item of metadata.data; let i = index"
            [ngClass]="{ 'odd-row': i % 2 === 0, 'even-row': i % 2 !== 0 }"
            >
            <td class="column-one" [matTooltip]="item.label" [ngClass]="{'two-column': item?.percentageChange == null,
            'long-value': formatValue(item.donutLabel).formattedValue.length > 5
          }">
              <span 
              class="color-box" 
              [style.background-color]="item.color"
               ></span
               >{{ item.label }}
             </td>

             <td class="column-two" [ngClass]="{'two-column-width': item?.percentageChange == null
            }">
               {{ formatValue(item.donutLabel).formattedValue }} ({{
                 roundPercentage(item.percentage)
               }}%)
             </td>
             <td
 [style.color]="item?.positive ? 'green' : 'red'"
 class="column-three"
>
 {{
   item?.percentageChange != null && item?.positive != null
     ? formatPercentageChange(item.percentageChange, item.positive)
     : ''
 }}
</td>


           </tr>
         </tbody>
       </table>
     </div>
   </div>
 </div>
</div>
