import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Question } from './question';

@Component({
  selector: 'giq-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  public questions: Question[] = [
    {
      question: 'What is GlobeIQ?',
      answer:
        'GlobeIQ is Globe Life’s internal Enterprise Reporting & Analytics platform that delivers comprehensive and trusted data source reports-dashboards capabilities across all Business/IT domains.  Unified with Globe’s Integrated Data Marketplace(IDM) – GlobeIQ delivers technology capabilities in self-service analytics, dashboards & data automation, and execute responsive content personalization with role-based access permissions.',
    },
    {
      question: 'What reports are available currently on GlobeIQ?',
      answer:
        'GlobeIQ reports are available within respective domains.  At current – GlobeIQ reporting domains include: Sales Administration, Enterprise Leads, Customer Service, Claims, and Actuarial.  More will be added with each updates!',
    },
    {
      question: 'How do I request access to GlobeIQ?',
      isHtml: true,
      answer:
        'To request access to specific reports/domains, please follow the process below. <br /> <ul><li>Start with ASKIT</li><li>Request Something</li><li>Data Analytics</li><li>Globe IQ</li><li>Add/Modify/Remove Access</li><li>Select the domain(s) you need access to and submit.</li></ul>',
    },
    {
      question: 'How do I add/modify my access to GlobeIQ?',
      isHtml: true,
      answer:
        'Requests are handled via The Core: ASKIT <br /> Direct path: <a target="_blank" href="https://globelife.cherwellondemand.com/CherwellPortal/AskIT?_=5e6d10ca#2">ASKIT GlobeIQ Add/Modify</a>',
    },
    {
      question: 'Once I have access, how do I navigate?',
      isHtml: true,
      answer:
        'GlobeIQ is accessible via the Employee Services section within <a target="_blank" href="http://core.globe.life/"> The Core</a>',
    },
    {
      question: 'What should I expect to see?',
      answer:
        'GlobeIQ content is driven via role-based access.  Accessible domains will prominently be displayed via the homepage, along with any favorited reports.  Aside from reports, our Data & Analytics organization will routinely publish roadmaps, recent wins, and milestones throughout the year via Announcements!',
    },
    {
      question: 'What are Highlights, and how do they work?',
      answer:
        'Highlights is a proprietary feature, built within GlobeIQ! Highlights are analytics widgets that offer quick-glance, live data-feeds to business impactful metrics.  The metrics link directly to the broader report for ease of access and further deep dives.',
    },
    {
      question: 'How do I favorite a report?',
      answer:
        'This is a core feature in GlobeIQ that greatly streamlines report navigations and accessibility of the dashboards.  Located next to the report title – the bookmark icon, upon a user-click, will conveniently move the specify report into the Favorite domain card. Uncheck to remove from Favorites.',
    },
    {
      question:
        'I have reports emailed daily that flood my inbox. Am I able to include this in GlobeIQ?',
      answer:
        'Absolutely! Please reach out to your leader and they will work with the Data & Analytics COE to prioritize and queue up your request.  We are here to help!',
    },
    {
      question:
        'Is there a process which would allow my department to highlight a certain report?',
      answer:
        'As we work on adding the reports to GlobeIQ, we will work with the business owner if it should be highlighted based on content.',
    },
    {
      question:
        'Is there a time limit on sessions which would cause a timeout?',
      answer:
        'Yes.  GlobeIQ has implemented a time limit on in-active user-sessions to strictly follow our InfoSec security standards.',
    },
    {
      question:
        'Do I need to request Tableau access when requesting GlobeIQ? What kind of access?',
      isHtml: true,
      answer:
        'No. <a target="_blank" href="https://globelife.cherwellondemand.com/CherwellPortal/AskIT?_=5e6d10ca#2">GlobeIQ Add/Modify/Remove </a> process will automatically streamline the user’s access needed to view Tableau reports within GlobeIQ.',
    },
    {
      question: 'What are alerts?',
      answer:
        'Alerts are system notifications that highlight important updates or events.  It can be broad or user specific.',
    },
    {
      question: 'What is the “I” next to the report name?',
      answer:
        'It refers to “Information” and you can quickly see key facts about each report.',
    },
    {
      question: 'Need more help?',
      answer:
        'Please send us an email to: globeiq@globe.life  Our team will reach out to further assist!',
    },
  ];

  constructor(private sanitizer: DomSanitizer) {
    this.questions.forEach((element: Question) => {
      element.html = element.isHtml
        ? sanitizer.bypassSecurityTrustHtml(element.answer)
        : undefined;
    });
  }
}
