import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CHATS, ADMIN, EXTRACTS } from 'src/app/constants/permissions';
import { hasPermission, userInfo$ } from 'src/app/core/state/user/user.store';
import { environment } from 'src/environments/environment';
import { MenuItem } from './menu-item';
import { TopMenuConfig, TopMenuService } from 'src/app/core/top-menu';
import { Router } from '@angular/router';

@Component({
  selector: 'giq-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [TopMenuService],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() mobile: boolean = false;

  public menuOpen: boolean | undefined;
  public menuList: MenuItem[] = [];
  public baseList: MenuItem[] = [
    {
      icon: 'house',
      name: 'Home',
      route: 'home',
      frontAction: null,
      availableOutside: true,
    },
    // {
    //   icon: 'list',
    //   name: 'Data Catalog',
    //   route: 'catalog',
    // },
    {
      icon: 'exchange',
      name: 'Data Exchange',
      route: 'exchange',
      frontAction: null,
      availableOutside: true,
    },
    {
      icon: 'folder',
      name: 'Extracts',
      route: 'extracts',
      frontAction: EXTRACTS,
      availableOutside: false,
    },
    {
      cssIcon: 'forum',
      name: 'Chats',
      route: 'chats',
      frontAction: CHATS,
      availableOutside: true,
    },
    {
      icon: 'help',
      name: 'Support',
      route: 'support',
      frontAction: null,
      availableOutside: true,
    },
    {
      icon: 'admin-panel-settings',
      name: 'Admin',
      route: 'admin',
      frontAction: ADMIN,
      availableOutside: true,
    },
    // {
    //   icon: 'mug',
    //   name: 'Customer Lounge',
    //   route: 'lounge',
    // },
    // {
    //   icon: 'question',
    //   name: 'Help',
    //   route: 'support',
    // },
    // {
    //   icon: 'dialog',
    //   name: 'Chat',
    //   route: 'chat',
    // },
  ];
  private menuConfig: TopMenuConfig = {
    items: [],
    panelClass: 'main-menu-panel',
    showMore: false,
    closeOnItemClick: true,
  };

  constructor(
    private topMenuService: TopMenuService,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
    private router: Router
  ) {}

  public openMenu() {
    let overlay = this.topMenuService.openMenu(
      this.menuConfig,
      this.elementRef
    );
  }

  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.topMenuService.configureService(this.viewContainerRef);
    this.subscriptions.add(
      userInfo$.subscribe((x) => {
        this.menuList = this.baseList.filter(
          (x) =>
            ((environment.fuera && x.availableOutside) || !environment.fuera) &&
            (x.frontAction == null || hasPermission(x.frontAction))
        );
        this.menuConfig.items = this.menuList.map((menu) => ({
          icon: menu.cssIcon,
          svgIcon: menu.icon,
          content: menu.name,
          onClick: () => this.router.navigate(['/' + menu.route]),
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public changeMenuState(): void {
    this.menuOpen = !this.menuOpen;
  }

  public close() {
    this.menuOpen = false;
  }
}
