export interface BarsHighlightMetadata {
    data: BarsHighlightElement[];
  }
  
  export interface BarsHighlightElement {
    date: Date;
    amount: number;
  }
  
  export interface BarsHighlightInternalElement {
    date: Date;
    amount: number;
    quarter: string;
    year: number;
    month: string;
  }
  
  export enum BarsHighlightGroup {
    Year= 'Year',
    Quarter= 'Quarter',
    Month= 'Month',
  }
  