import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, InjectionToken, Injector, OnDestroy, StaticProvider, ViewContainerRef } from '@angular/core';
import { Subject, Subscription, take } from 'rxjs';
import { ReportDetailsComponent } from './report-details/report-details.component';

export const REPORT_ID: InjectionToken<string> = new InjectionToken<string>("Overlay_Report_Id");

@Injectable()
export class ReportsDetailsService implements OnDestroy {
    private popup$: Subject<{ reportId: string | undefined; elementRef: ElementRef }> = new Subject();
    private overlayRef: OverlayRef | null = null;
    private subscriptions: Subscription = new Subscription();
    private injector?: Injector;
    private viewContainerRef?: ViewContainerRef;

    constructor(private overlay: Overlay) { }

    ngOnDestroy(): void {
        this.popup$.complete();
        this.hidePopup();
        this.subscriptions.unsubscribe();
    }

    public initDetailsServiceWithParams(injector: Injector, viewContainerRef: ViewContainerRef) {
        this.injector = injector;
        this.viewContainerRef = viewContainerRef;
        this.subscriptions.add(
            this.popup$.subscribe({
                next: (params: { reportId: string | undefined, elementRef: ElementRef }) => {
                    if (params?.reportId == null) {
                        this.hidePopup();
                    } else {
                        this.showPopup(params.reportId, params.elementRef);
                    }
                }
            })
        )
    }

    public showReportDetailsFor(reportId: string | undefined, elementRef: ElementRef): void {
        this.popup$.next({ reportId, elementRef });
    }

    private hidePopup(): void {
        if (this.overlayRef != null) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }

    private showPopup(reportId: string, elementRef: ElementRef): void {
        if (this.injector != null) {
            this.hidePopup();
            const config = this.getConfiguration(elementRef);
            this.overlayRef = this.overlay.create(config);
            const injector = this.createInjector(reportId);
            this.overlayRef.attach(
                new ComponentPortal(
                    ReportDetailsComponent,
                    this.viewContainerRef,
                    injector
                )
            );

            const overlaySubscription = this.overlayRef
                .backdropClick()
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        overlaySubscription.unsubscribe();
                        this.hidePopup();
                    }
                });
        }
    }

    private getConfiguration(elementRef: ElementRef): OverlayConfig {
        return {
            hasBackdrop: true,
            disposeOnNavigation: true,
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy: this.overlay.position().flexibleConnectedTo(elementRef.nativeElement).withPositions([
                { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' },
                { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top' },
                { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
                { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
                { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
                { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
            ])
        };
    }

    private createInjector(reportId: string): Injector {
        const options: { providers: StaticProvider[]; parent?: Injector } = {
            parent: this.injector,
            providers: [
                { provide: REPORT_ID, useValue: reportId }
            ]
        };
        const portal = Injector.create(options);
        return portal;
    }

}