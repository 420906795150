import { Component, ElementRef, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { TopMenuConfig, TopMenuService } from 'src/app/core/top-menu';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'giq-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public version: string = environment.version;
}
