<ng-container *ngIf="highlights$ | async as highs">
  <div class="title">
    <h3>Highlights</h3>
    <mat-icon
      *ngIf="saving && !isSavingProcess"
      class="button-link"
      (click)="saveChanges()"
      >save</mat-icon
    >
    <mat-icon matTooltip="Edit" [routerLink]="['/highlights']">
      settings
    </mat-icon>
    <giq-small-load *ngIf="isSavingProcess"></giq-small-load>
  </div>

  <ng-container *ngIf="containsElements; else welcomeMessage">
    <div class="highlights-content" cdkDropListGroup>
      <div class="highlight-list">
        <div
          class="highlight-container"
          *ngFor="let high of highs; let i = index"
          cdkDropList
          [cdkDropListData]="i"
        >
          <div
            class="highlight"
            (click)="openReport(high)"
            cdkDrag
            [cdkDragData]="i"
            (cdkDragEntered)="dragEntered($event)"
          >
            <giq-highlight [config]="high"></giq-highlight>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #welcomeMessage>
  <div class="highlights-welcome">
    <p class="title">Dynamic Highlights is here</p>
    <p class="text">
      Dynamic Highlights is the new personalized widget that helps you cut the
      queue, gather insights without opening a single report, and get things
      done effortlessly.
    </p>
    <p class="text">
      Tap the gear icon to get started.
      <mat-icon matTooltip="Edit" [routerLink]="['/highlights']">
        settings
      </mat-icon>
    </p>
  </div>
</ng-template>
