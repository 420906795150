import { Component, Input, SimpleChanges } from '@angular/core';
import { Highlight } from 'src/app/models/reports';
import {
  Display,
  LineOverBarHighlightElement,
  LineOverBarsMetadata,
  TimeScale,
} from './line-over-bars-highlight.metadata';
import * as d3 from 'd3';
import * as moment from 'moment';

@Component({
  selector: 'giq-line-over-bars-highlight',
  templateUrl: './line-over-bars-highlight.component.html',
  styleUrls: ['./line-over-bars-highlight.component.scss'],
})
export class LineOverBarsHighlightComponent {
  @Input() config: Highlight | undefined;
  public id: string = 'line-over-bars-' + Math.ceil(Math.random() * 100000);
  public metadataSets: LineOverBarsMetadata[] = [];
  selectedIndex: number = 0;
  public metadata: LineOverBarsMetadata = {
    colors: [],
    data: [],
    timeScale: TimeScale.Monthly,
    display: Display.None,
    division: '',
    subtitle: '',
    lineName: '',
    barName: '',
  };
  isToggleEnabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config != null) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => {
        return this.getSubtitles();
      };

      const elements = this.config?.metadata as unknown as LineOverBarsMetadata;
      if (elements) {
        this.isToggleEnabled = elements.data.some((item: any) => item.division);
        this.metadataSets = elements.data.map((item: any) => {
          const division = item.division || '';
          const display = item.display || 'None';

          const newData = Object.keys(item)
            .filter((key) => key !== 'division')
            .map((key) => item[key])
            .filter(
              (item) => typeof item === 'object'
            ) as LineOverBarHighlightElement[];

          newData.forEach((x: any) => (x.date = new Date(x.date)));

          return {
            timeScale: elements.timeScale,
            colors: elements.colors,
            data: newData.sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            ),
            division: division,
            display: elements.display,
            subtitle: '',
            lineName: elements.lineName || '',
            barName: elements.barName || '',
          };
        });

        this.metadata = this.metadataSets[this.selectedIndex];
      }
      setTimeout(() => this.createSvg(), 0);
    }
  }

  updateChartData(index: number): void {
    this.selectedIndex = index;
    this.metadata = this.metadataSets[index];
    this.clearGraph();
    this.createSvg();
  }

  getSubtitles(): string[] {
    const elements = this.config?.metadata as unknown as LineOverBarsMetadata;

    if (elements?.data?.length <= 1 || !this.isToggleEnabled) {
      return [];
    } else {
      const divisions = elements.data
        .map((item: any) => item.division)
        .filter((division) => !!division);
      return [...new Set(divisions)];
    }
  }

  private clearGraph(): void {
    d3.selectAll(`#${this.id} > *`).remove();
  }

  private createSvg(): void {
    const margin = { top: 50, right: 0, bottom: 50, left: 0 },
      width = 800 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    this.metadata.data.forEach(
      (d) => (d.date = new Date(d.date).toISOString().split('T')[0])
    );

    const x = d3
      .scaleBand()
      .domain(
        this.metadata.data.map(
          (d) => new Date(d.date).toISOString().split('T')[0]
        )
      )
      .range([0, width])
      .padding(0.3);

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(this.metadata.data, (d) => Math.max(d.barValue, d.lineValue))!,
      ])
      .nice()
      .range([height, 0]);

    const labeledYears = new Set<string>();
    const labeledQuartersByYear = new Map<string, Set<string>>();
    const labeledMonthsByYear = new Map<string, Set<string>>();
    let uniqueDates = Array.from(
      new Set(this.metadata.data.map((d) => d.date))
    );

    if (
      this.metadata.timeScale === TimeScale.Weekly &&
      uniqueDates.length > 10
    ) {
      let step = Math.ceil(uniqueDates.length / 5);
      uniqueDates = uniqueDates.filter((d, i) => i % step === 0);
    }
    const barColor = this.metadata.colors[this.selectedIndex * 2] || '#C5E0B4';
    const lineColor =
      this.metadata.colors[this.selectedIndex * 2 + 1] || '#CFAFE7';

    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3
          .axisBottom(x)
          .tickValues(uniqueDates)
          .tickFormat((d) => {
            const dateMoment = moment(d, 'YYYY-MM-DD');
            const year = dateMoment.year().toString();
            const month = dateMoment.format('MMM');
            const weekDate = dateMoment.format('MM/DD');

            switch (this.metadata.timeScale) {
              case TimeScale.Yearly:
                if (!labeledYears.has(year)) {
                  labeledYears.add(year);
                  return year;
                }
                return '';

              case TimeScale.Quarterly:
                const quarterLabel = `Q${
                  Math.floor(dateMoment.month() / 3) + 1
                }`;
                if (!labeledQuartersByYear.has(year)) {
                  labeledQuartersByYear.set(year, new Set<string>());
                }
                const labeledQuarters = labeledQuartersByYear.get(year)!;
                if (!labeledQuarters.has(quarterLabel)) {
                  labeledQuarters.add(quarterLabel);
                  return quarterLabel;
                }
                return '';

              case TimeScale.Weekly:
                return weekDate;

              case TimeScale.MonthNumbers:
                const monthNumber = (dateMoment.month() + 1).toString();
                if (!labeledMonthsByYear.has(year)) {
                  labeledMonthsByYear.set(year, new Set<string>());
                }
                const labeledMonthNumeric = labeledMonthsByYear.get(year);
                if (!labeledMonthNumeric?.has(monthNumber)) {
                  labeledMonthNumeric?.add(monthNumber);
                  return monthNumber;
                }
                return '';

              case TimeScale.YrQtr:
                const qtrLabel = `${dateMoment.format('YY')}Q${
                  Math.floor(dateMoment.month() / 3) + 1
                }`;
                if (!labeledQuartersByYear.has(year)) {
                  labeledQuartersByYear.set(year, new Set<string>());
                }
                const labelQuarters = labeledQuartersByYear.get(year);
                if (!labelQuarters?.has(qtrLabel)) {
                  labelQuarters?.add(qtrLabel);
                  return qtrLabel;
                }
                return '';

              case TimeScale.Monthly:
              default:
                if (!labeledMonthsByYear.has(year)) {
                  labeledMonthsByYear.set(year, new Set<string>());
                }
                const labeledMonths = labeledMonthsByYear.get(year)!;
                if (!labeledMonths.has(month)) {
                  labeledMonths.add(month);
                  return month;
                }
                return '';
            }
          })
          .tickPadding(10)
      )
      .selectAll('text')
      .style('font-size', '1.5rem')
      .style('text-anchor', 'middle');

      svg.selectAll('.domain');
    svg.selectAll('.tick line').remove();


    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'line-tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('z-index', 2)
      .style('border', '1px solid #ddd')
      .style('background-color', 'white')
      .style('padding', '5px');

    svg
      .selectAll('.bar')
      .data(this.metadata.data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x(new Date(d.date).toISOString().split('T')[0])!)
      .attr('y', (d) => yScale(d.barValue))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - yScale(d.barValue))
      .attr('fill', barColor)
      .on('mouseover', function (event, d) {
        tooltip.style('opacity', 1);
      })
      .on('mousemove', function (event, d) {
        const formattedAmount = formatNumber(d.barValue);
        tooltip
          .html(`${d.date}<br>${formattedAmount}`)
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 28 + 'px');
      })
      .on('mouseout', function () {
        tooltip.style('opacity', 0);
      })
      .transition()
      .duration(200);

    const line = d3
      .line<LineOverBarHighlightElement>()
      .x(
        (d) =>
          x(new Date(d.date).toISOString().split('T')[0])! + x.bandwidth() / 2
      )
      .y((d) => yScale(d.lineValue))
      .curve(d3.curveLinear);

    svg
      .append('path')
      .datum(this.metadata.data)
      .attr('fill', 'none')
      .attr('stroke', lineColor)
      .attr('stroke-width', 6)
      .attr('d', line)
      .transition()
      .duration(800);

    svg
      .selectAll('.dot')
      .data(this.metadata.data)
      .enter()
      .append('circle')
      .attr(
        'cx',
        (d) =>
          x(new Date(d.date).toISOString().split('T')[0])! + x.bandwidth() / 2
      )
      .attr('cy', (d) => yScale(d.lineValue))
      .attr('r', 7)
      .attr('fill', lineColor)
      .on('mouseover', function (event, d) {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(`Date: ${d.date}<br>Value: ${formatNumber(d.lineValue)}`)
          .style('left', event.pageX + 5 + 'px')
          .style('top', event.pageY - 28 + 'px');
        d3.select(this).transition().duration(200).attr('r', 7);
      })
      .on('mouseout', function () {
        tooltip.transition().duration(500).style('opacity', 0.9);
        d3.select(this).transition().duration(500).attr('r', 7);
      });

      const formatNumber = (value: number) => {
        if (value >= 1000000 ) {
          const scaledValue = Math.floor(value / 1e5)/10;
          return d3.format('.1f')(scaledValue) + 'M';
        } else if( value >= 1000 ){
          const scaledValue = Math.floor(value / 100) /10;
          return  d3.format('.1f')(scaledValue) + 'K';
        } else return d3.format('')(value);
  
      };

    svg
      .selectAll('.label')
      .data(this.metadata.data)
      .enter()
      .append('text')
      .attr(
        'x',
        (d) =>
          x(new Date(d.date).toISOString().split('T')[0])! + x.bandwidth() / 2
      )
      .attr('y', (d) => yScale(d.lineValue) - 10)
      .attr('text-anchor', 'middle')
      .style('font-size', '1.9rem')
      .text((d) => `${formatNumber(d.lineValue)}`);

    const legendXOffset = 10;
    const legendYOffset = -36;

    const legend = svg
      .append('g')
      .attr('transform', `translate(${legendXOffset}, ${legendYOffset})`);

    const legendItems = [
      { name: this.metadata.barName, color: barColor },
      { name: this.metadata.lineName, color: lineColor },
    ];

    legend
      .selectAll('.legend-item')
      .data(legendItems)
      .enter()
      .append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d, i) => `translate(0, ${i * 25})`)
      .call((g) => {
        g.append('circle')
          .attr('r', 10)
          .style('fill', (d) => d.color);
        g.append('text')
          .attr('x', 25)
          .attr('y', 5)
          .text((d) => d.name)
          .style('font-size', '1.55rem');
      });
  }
}
