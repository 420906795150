import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { withErrorMapping } from 'src/app/core/errors/with-error-mapping';
import { Highlight } from 'src/app/models/reports';
import { environment } from 'src/environments/environment';

@Injectable()
export class EditHighlightsService {
  private readonly url = environment.apiUrl + '/reports/highlights/user';
  constructor(private httpClient: HttpClient) {}

  public getAllUserHighlights(): Observable<Highlight[]> {
    return this.httpClient.get<Highlight[]>(this.url).pipe(withErrorMapping);
  }

  public saveUserPreference(
    preferences: { id: string; active: boolean }[]
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url, { highlightStatuses: preferences })
      .pipe(withErrorMapping);
  }
}
