import { createStore, select, withProps } from '@ngneat/elf';
import {
  addEntities,
  selectAllEntities,
  selectEntity,
  selectManyByPredicate,
  setEntities,
  updateEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { Observable, first, switchMap } from 'rxjs';
import { combineLatestWith, map } from 'rxjs/operators';
import { Category, Highlight, Report } from 'src/app/models/reports';

interface ReportsProps {
  highlightsRetrieved: boolean;
  reportsRetrieved: boolean;
  noReports: boolean;
}

const ReportStateStore = createStore(
  { name: 'reportFlags' },
  withProps<ReportsProps>({
    highlightsRetrieved: false,
    reportsRetrieved: false,
    noReports: false,
  })
);

const ReportsStore = createStore({ name: 'reports' }, withEntities<Report>());

const CategoriesStore = createStore(
  { name: 'categories' },
  withEntities<Category>({ initialValue: [] })
);

const HighlightsStore = createStore(
  { name: 'highlights' },
  withEntities<Highlight>({ initialValue: [] })
);

function setNoReports(noReports: boolean) {
  ReportStateStore.update((state) => ({
    ...state,
    noReports,
  }));
}

export const highlightsRetrieved$: Observable<boolean> = ReportStateStore.pipe(
  select((state) => state.highlightsRetrieved)
);

export function highlightsRetrieved(highlightsRetrieved: boolean) {
  ReportStateStore.update((state) => ({
    ...state,
    highlightsRetrieved,
  }));
}

export const reportsRetrieved$: Observable<boolean> = ReportStateStore.pipe(
  select((state) => state.reportsRetrieved)
);

export const noReports$: Observable<boolean> = ReportStateStore.pipe(
  select((state) => state.noReports)
);

export function reportsRetrieved(reportsRetrieved: boolean) {
  ReportStateStore.update((state) => ({
    ...state,
    reportsRetrieved,
  }));
}

export function changeFavorite(reportId: string, setFavorite: boolean) {
  ReportsStore.update(updateEntities(reportId, { favorite: setFavorite }));
}

export function setReports(reports: Report[]) {
  setNoReports(reports.length === 0);
  ReportsStore.update(setEntities(reports));
}

export function setHighlights(highlights: Highlight[]) {
  HighlightsStore.update(setEntities(highlights));
}

export function setCategories(categories: Category[]) {
  CategoriesStore.update(
    addEntities({ id: 'favs', name: 'Favorites', color: '#44ABA8' })
  );
  CategoriesStore.update(addEntities(categories));
}

export const allHighlights$: Observable<Highlight[]> = HighlightsStore.pipe(
  selectAllEntities()
);

export const allCategories$: Observable<Category[]> = CategoriesStore.pipe(
  selectAllEntities()
);

export const getReport$ = (reportId: string) =>
  ReportsStore.pipe(selectEntity(reportId));

export const reportsOfCategory$ = (
  catId: string | null,
  dataSource: string | null = null
) =>
  ReportsStore.pipe(
    selectManyByPredicate(({ categoryId, favorite, dataSourceId }) => {
      let categoryMatch = false;
      let dataSourceMatch = false;
      if (catId != null) {
        if (catId === 'favs') {
          categoryMatch = favorite === true;
        } else {
          categoryMatch = categoryId == catId;
        }
      }
      if (dataSource != null) {
        dataSourceMatch = dataSourceId == dataSource;
      }
      return categoryMatch || dataSourceMatch;
    }),
    map((list: Report[]) => list.sort((a, b) => (a.name < b.name ? -1 : 1)))
  );

export const findReportsByMetrics$ = (searchString: string) => {
  const lowerSearchString = searchString.toLocaleLowerCase().trim();
  return ReportsStore.pipe(
    selectManyByPredicate((report: Report) => {
      return report.metrics?.map((rep) => rep.toLowerCase().trim())
            .filter((str) => str.includes(lowerSearchString)).length
        ? true
        : false;
    })
  );
};

export const findReportsByProperty$ = (searchString: string) => {
  const lowerSearchString = searchString.toLocaleLowerCase().trim();

  return ReportsStore.pipe(
    selectManyByPredicate((report: Report) => {
      // search on these properties
      const newObject = {
        overview: report.overview,
        name: report.name,
        analyst: report.analyst,
        categoryName: report.categoryName,
      };

      return Object.values(newObject)
        .map((reportValue) => {
          if (typeof reportValue === 'string') {
            return reportValue.toLowerCase().trim();
          }
          return reportValue;
        })
        .some((val) => {
          if (val) {
            let res = val.includes(lowerSearchString);
            return res;
          }
          return false;
        });
    })
  );
};

export const findReportsByString$ = (searchString: string) => {
  return findReportsByMetrics$(searchString)
    .pipe(combineLatestWith(findReportsByProperty$(searchString)))
    .pipe(
      map(([result1, result2]) => {
        // remove duplicates
        const fullArr = [...result1, ...result2];
        let result: Report[] = [];

        fullArr.forEach((report) => {
          if (!result.find((r) => r.id === report.id)) {
            result.push(report);
          }
        });

        return result;
      })
    );
};

export const findReportsByStringAndFilters$ = (
  searchString: string,
  { domain }: { domain: string }
) => {
  return findReportsByString$(searchString)
    .pipe(first())
    .pipe(switchMap((x) => reportsOfCategory$(domain)));
};
