export const ICONS: string[] = [
  'house',
  'folder',
  'list',
  'arrows',
  'mug',
  'question',
  'dialog',
  'todo',
  'notification',
  'megaphone',
  'assignment',
  'arrow-small-left',
  'exchange',
  'menu_arrow',
  'help',
  'search',
  'sales-fullscreen',
  'sales-gross-fullscreen',
  'admin-panel-settings'  
];
