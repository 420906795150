<h1 mat-dialog-title>Report a problem or concern</h1>
<div mat-dialog-content>
  <p class="type">{{ data.type }}</p>
  <p class="description">{{ data.description }}</p>
  <mat-form-field class="comments">
    <textarea
      giq-auto-resize
      [formControl]="comments"
      [giqColorValidation]="comments"
      matInput
    >
    </textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions [align]="saving ? 'center' : 'end'">
  <ng-container *ngIf="!saving">
    <button mat-button cdkFocusInitial (click)="cancel()">Cancel</button>
    <button mat-button color="primary" (click)="save()">Send Report</button>
  </ng-container>
  <ng-container *ngIf="saving">
    <span><giq-small-load></giq-small-load>Saving your report</span>
  </ng-container>
</div>
