import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CallbackComponent } from './common/login/callback/callback.component';
import { LoginComponent } from './common/login/login.component';
import { LogoutComponent } from './common/login/logout/logout.component';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import {
  AGENT_AUTOMATION,
  BANK_COMPLIANCE,
  CHATS,
  ADMIN,
  CLAIM_AUDIT_LOGS,
  CUSTOMER_CARE,
  EXTRACTS,
  PRODUCTION_TASK,
  SALESBOARD,
  UNDERWRITING,
  WORKSITE_TRACKER,
  PRODUCTION_TRACKER,
  LEGAL_COMPLAINTS,
  FHDHIERARCHY,
  LEGAL_EXPENSE,
  OUTAGE_TRACKER,
  FHDINPUTFORM,
  GROWTH_ADJUSTMENTS,
} from './constants/permissions';
import { appGuard } from './core/auth/app.guard';
import { authGuard } from './core/auth/auth.guard';
import { DataExchangeComponent } from './data-exchange/data-exchange.component';
import { EditHighlightsComponent } from './reports/highlights/edit-highlights/edit-highlights.component';
import { ReportCatalogComponent } from './reports/report-catalog/report-catalog.component';
import { ReportComponent } from './reports/report/report.component';
import { ReportsComponent } from './reports/reports/reports.component';
import { FaqComponent } from './support/faq/faq.component';
import { SupportComponent } from './support/support.component';
import { FhdHierarchyComponent } from './fhd-hierarchy/fhd-hierarchy.component';
import { FhdInputFormComponent } from './fhd-input-form/fhd-input-form.component';
import { GrowthAdjustmentsInputFormComponent } from './growth-adjustments-input-form/growth-adjustments-input-form.component';

const insideRoutes: Routes =
  environment.fuera === true
    ? [
        {
          path: 'exchange/agent-automation',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: AGENT_AUTOMATION,
          },
          loadChildren: () =>
            import('./ua-agent-insight/ua-agent-insight.module').then(
              (m) => m.UaAgentInsightModule
            ),
        },
        {
          path: 'exchange/fhd-hierarchy',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: FHDHIERARCHY,
          },
          component: FhdHierarchyComponent,
        },
        {
          path: 'exchange/fhd-input-form',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: FHDINPUTFORM,
          },
          component: FhdInputFormComponent,
        },
      ]
    : [
        {
          path: 'exchange/salesboard',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: SALESBOARD,
          },
          loadChildren: () =>
            import('./salesboard/salesboard.module').then(
              (m) => m.SalesBoardModule
            ),
        },
        {
          path: 'exchange/ua-ps-admin',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: CUSTOMER_CARE,
          },
          loadChildren: () =>
            import('./ua-ps-admin-form/ua-ps-admin-form.module').then(
              (m) => m.UaPsAdminFormModule
            ),
        },
        {
          path: 'exchange/worksite-tracker',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: WORKSITE_TRACKER,
          },
          loadChildren: () =>
            import('./ua-worksite-tracker/ua-worksite-tracker.module').then(
              (m) => m.UaWorksiteTrackerModule
            ),
        },
        {
          path: 'exchange/bank-compliance',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: BANK_COMPLIANCE,
          },
          loadChildren: () =>
            import('./ua-bank-compliance/ua-bank-compliance.module').then(
              (m) => m.UaBankComplianceModule
            ),
        },
        {
          path: 'exchange/claim-audit-logs',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: CLAIM_AUDIT_LOGS,
          },
          loadChildren: () =>
            import('./ua-claim-audit-logs/ua-claim-audit-logs.module').then(
              (m) => m.UaClaimAuditLogsModule
            ),
        },
        {
          path: 'exchange/production-task',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: PRODUCTION_TASK,
          },
          loadChildren: () =>
            import('./ua-production-task/ua-production-task.module').then(
              (m) => m.UaProductionTaskModule
            ),
        },
        {
          path: 'exchange/legal-complaints',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: LEGAL_COMPLAINTS,
          },
          loadChildren: () =>
            import('./ua-legal-complaints/ua-legal-complaints.module').then(
              (m) => m.UaLegalCompaintsModule
            ),
        },
        {
          path: 'exchange/outage-tracker',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: OUTAGE_TRACKER,
          },
          loadChildren: () =>
            import('./ua-outage-tracker/ua-outage-tracker.module').then(
              (m) => m.UaOutageTrackerModule
            ),
        },
        {
          path: 'extracts',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: EXTRACTS,
          },
          loadChildren: () =>
            import('./ua-extracts/ua-extracts.module').then(
              (m) => m.UaExtractsModule
            ),
        },
        {
          path: 'exchange/legal-expense-invoice',
          canActivate: [authGuard, appGuard],
          data: {
            appPermission: LEGAL_EXPENSE,
          },
          loadChildren: () =>
            import(
              './ua-legal-expense-invoice/ua-legal-expense-invoice.module'
            ).then((m) => m.UaLegalExpenseInvoiceModule),
        },
      ];

const routes: Routes = [
  { path: '', redirectTo: environment.defaultRoute, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'login/callback', component: CallbackComponent, pathMatch: 'full' },
  { path: 'login/company/:company', component: LoginComponent },
  { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
  {
    path: 'reports/:reportId',
    component: ReportComponent,
    pathMatch: 'full',
    canActivate: [authGuard],
  },
  {
    path: 'home',
    component: ReportsComponent,
    title: 'GlobeIQ - Reports',
    canActivate: [authGuard],
  },
  {
    path: 'reports',
    component: ReportCatalogComponent,
    title: 'GlobeIQ - Reports',
    canActivate: [authGuard],
  },
  {
    path: 'highlights',
    component: EditHighlightsComponent,
    title: 'GlobeIQ- Edit Highlights',
    canActivate: [authGuard],
  },
  {
    path: 'exchange',
    component: DataExchangeComponent,
    title: 'GlobeIQ - Exchange Apps',
    canActivate: [authGuard],
  },
  ...insideRoutes,
  {
    path: 'exchange/underwriting',
    canActivate: [authGuard, appGuard],
    data: {
      appPermission: UNDERWRITING,
    },
    loadChildren: () =>
      import('./underwriting/underwriting.module').then(
        (m) => m.UnderwritingModule
      ),
  },
  {
    path: 'support',
    component: SupportComponent,
    title: 'GlobeIQ - Support',
    canActivate: [authGuard],
    pathMatch: 'full',
  },
  {
    path: 'support/faq',
    component: FaqComponent,
    title: 'GlobeIQ - FAQ',
    canActivate: [authGuard],
    pathMatch: 'full',
  },
  {
    path: 'chats',
    canActivate: [authGuard, appGuard],
    data: {
      appPermission: CHATS,
    },
    loadChildren: () =>
      import('./chatbots/chatbot.module').then((m) => m.ChatbotModule),
  },
  {
    path: 'admin',
    canActivate: [authGuard, appGuard],
    data: {
      appPermission: ADMIN,
    },
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    title: 'GlobeIQ- Admin',
  },
  {
    path: 'production-tracker',
    canActivate: [authGuard, appGuard],
    data: {
      appPermission: PRODUCTION_TRACKER,
    },
    loadChildren: () =>
      import('./production-tracker/production-tracker.module').then(
        (m) => m.ProductionTrackerModule
      ),
    title: 'GlobeIQ- ProductionTracker',
  },
  {
    path: 'exchange/growth-adjustments-input-form',
    canActivate: [authGuard, appGuard],
    data: {
      appPermission: GROWTH_ADJUSTMENTS,
    },
    component: GrowthAdjustmentsInputFormComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
