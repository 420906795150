<div class="report-header">
  <span class="title">{{ title }}</span>
  <span class="subtitle">{{ reportCount }} reports available</span>
</div>
<div class="report-container">
  <span class="report-item" *ngFor="let rep of reports$ | async">
    <mat-icon class="favorite" (click)="setFavorite(rep, $event)">
      {{ rep.favorite == true ? "bookmark" : "bookmark_outline" }}
    </mat-icon>
    <span class="name" (click)="openReport(rep, $event)">{{ rep.name }}</span>
    <mat-icon [giqShowReportDetails]="rep.id"> info_outline</mat-icon>
  </span>
</div>
<div class="view-all">
  <span
    *ngIf="reportCount >= 6"
    [routerLink]="['/reports']"
    [queryParams]="{ category: categoryId }"
  >
    View All
  </span>
</div>
