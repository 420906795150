import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import {
  Observable,
  asyncScheduler,
  from,
  map,
  observeOn,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { authCodeFlowConfig, environment } from 'src/environments/environment';
import { UserInfo } from '../state/user/user-info';
import {
  loggingOut,
  setUserAuthenticated,
  setUserInfo,
  userInfo$,
} from '../state/user/user.store';
import { IAuthService } from './iauth.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';

@Injectable()
export class AuthService implements IAuthService {
  private refreshing = false;
  public currentConfig: AuthConfig | null = null;

  constructor(
    private oauthService: OAuthService,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) {
    this.currentConfig = authCodeFlowConfig;
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.clearHashAfterLogin = true;
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case 'token_expires':
          setUserAuthenticated(false);
          this.dialog.open(DialogComponent, {
            disableClose: true,
            data: {},
          });
      }
    });
  }

  public setOAuthConfiguration(_: string): void {
    this.currentConfig = authCodeFlowConfig;
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.clearHashAfterLogin = true;
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  }

  public login(state: string): void {
    localStorage.clear();
    this.oauthService.initCodeFlow(state);
    this.oauthService.tryLogin();
  }

  public isTokenExpired(): boolean {
    let expiration = this.oauthService.getAccessTokenExpiration();
    const now = new Date();
    const expired = expiration == null || new Date(expiration) < now;
    return expired;
  }

  public checkSession(): boolean {
    let validToken = this.oauthService.getAccessToken();
    if (validToken != null) {
      let expiration = this.oauthService.getAccessTokenExpiration();
      if (expiration != null) {
        if (expiration > Date.now()) {
          setUserAuthenticated(true);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }

  public refresh(): void {
    let expiration = this.oauthService.getAccessTokenExpiration();
    const now = new Date().getTime();
    const difference = (expiration ?? 0) - now;
    const inRenewalTime = difference > 20000 && difference < 600000;
    if (inRenewalTime && this.refreshing == false) {
      this.refreshing = true;
      console.log('calling Renew', difference);
      this.oauthService
        .refreshToken()
        .then((x) => {
          this.refreshing = false;
        })
        .finally(() => {
          this.refreshing = false;
        });
    }
  }

  public getToken(): Observable<{
    state: string | undefined;
    validToken: boolean;
  }> {
    return from(this.oauthService.tryLogin()).pipe(
      observeOn(asyncScheduler),
      map(() => {
        let token = this.oauthService.getAccessToken();
        let validToken = token != null && token != '';
        let state = this.oauthService.state;
        return { state, validToken };
      })
    );
  }

  public getUserInfo(): Observable<UserInfo> {
    return userInfo$.pipe(
      switchMap((info: UserInfo | undefined) => {
        if (info != null) {
          return of(info);
        }
        return this.httpClient
          .get<UserInfo>(environment.apiUrl + '/auth/user-info')
          .pipe(
            tap((info: UserInfo) => {
              setUserInfo(info);
            })
          );
      })
    );
  }

  public getSchema(): string {
    return 'Bearer';
  }

  public getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public logout(): void {
    loggingOut();
    this.oauthService.revokeTokenAndLogout();
    setUserAuthenticated(false);
  }
}
