import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes(environment.apiUrl)) {
      let headers = request.headers ?? new HttpHeaders();
      const sessionOpen = this.authService.checkSession();
      let accessToken = this.authService.getSchema() + ' ';
      if (
        request.url === this.authService.currentConfig?.tokenEndpoint &&
        !sessionOpen
      ) {
        accessToken += 'AuthorizationTokenRequest';
      } else {
        accessToken += this.authService.getAccessToken();
      }

      headers = headers.set('Authorization', accessToken);
      let clone = request.clone({ headers: headers });
      return next.handle(clone);
    }
    return next.handle(request);
  }
}
