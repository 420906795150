<h3>FAQ</h3>
<mat-accordion multi class="panel">
  <mat-expansion-panel *ngFor="let question of questions" class="question">
    <mat-expansion-panel-header>
      <mat-panel-title class="title"> {{ question.question }} </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="!question.isHtml">
      {{ question.answer }}
    </div>
    <div *ngIf="question.isHtml" [innerHtml]="question.html"></div>
  </mat-expansion-panel>
</mat-accordion>
