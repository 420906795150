import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { withErrorMapping } from '../../core/errors/with-error-mapping';
import { IGrowthAdjustments } from '../model/growth-adjustments-input-form';

@Injectable({
  providedIn: 'root'
})
export class GrowthAdjustmentsInputFormService {
  baseURL = `${environment.apiUrl}`; 
  constructor(private httpClient: HttpClient) { } 

  addGrowthAdjustments(payload: IGrowthAdjustments) {    
    return this.httpClient
      .post<IGrowthAdjustments>(       
        `${this.baseURL}/growthadjustments/create`,  payload 
      )
      .pipe(withErrorMapping);
  }
}
