import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Highlight } from 'src/app/models/reports';
import { DualHighlightMetadata } from './dual-highlight.metadata';

import * as d3 from 'd3';

@Component({
  selector: 'giq-dual-highlight',
  templateUrl: 'dual-highlight.component.html',
  styleUrls: ['dual-highlight.component.scss'],
})
export class DualHighlightComponent implements AfterViewInit, OnChanges {
  @Input() config: Highlight | undefined;

  public id: string = 'dual-' + Math.ceil(Math.random() * 100000);

  public metadata: DualHighlightMetadata = {
    colorLeft: '244,117,46',
    colorRight: '62,140,191',
    labelLeft: '',
    labelRight: '',
    data: [],
  };
  ngAfterViewInit(): void {
    this.createSvg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null) {
      this.metadata = { ...this.metadata, ...this.config?.metadata };
    }
  }

  private createSvg(): void {
    const margin = {
      top: 0,
      right: 0,
      bottom: 15,
      left: 50,
      gap: 10,
      graph: 50,
    };
    const width = 500 - margin.left - margin.right - margin.gap - margin.graph;
    const height = 250 - margin.top - margin.bottom;

    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${
          width + margin.left + margin.right + margin.gap + margin.graph
        } ${height + margin.top + margin.bottom}`
      )
      .attr('preserveAspectRatio', 'none')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const barWidth = (width - margin.left - margin.right) / 2 - 10;

    const x = d3
      .scaleLinear()
      .domain([0, d3.max(this.metadata.data, (d) => d.left + d.right) ?? 0])
      .range([0, width - margin.left - margin.right]);

    const y = d3
      .scaleBand()
      .range([0, height])
      .domain(this.metadata.data.map((d) => d.key))
      .padding(0.1);
    const axis = svg.append('g').call(d3.axisLeft(y).tickSize(0));
    svg.selectAll('.tick text').attr('font-size', '17px');

    axis.select('.domain').attr('stroke', 'transparent');

    const tooltip = d3
      .select('body')
      .append('div')
      .style('position', 'absolute')
      .style('background', '#fff')
      .style('padding', '5px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '4px')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('font-size', '10px');

    const formatValue = (value: number) => {

      if (value > 1000000) {
        const formatValue = Math.floor(value / 1e5)/10;
        return formatValue + 'M';
      } else if (value > 10000) {
        return (Math.floor(value / 100) /10)  + 'K';
      } else {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } 
  

    svg
      .selectAll('.rightBar')
      .data(this.metadata.data)
      .enter()
      .append('rect')
      .attr('x', margin.left + barWidth + 5)
      .attr('y', (d) => y(d.key)!)
      .attr('width', (d) => x(d.right))
      .attr('height', y.bandwidth() - 10)
      .attr('fill', `rgb(${this.metadata.colorRight})`)
      .on('mouseover', function (event, d) {
        tooltip.style('opacity', 1);
      })
      .on('mousemove', function (event, d) {
        tooltip
          .html(formatValue(d.right))
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 28 + 'px');
      })
      .on('mouseout', function () {
        tooltip.style('opacity', 0);
      })
      .attr('rx', 15)
      .attr('ry', 15)
      .transition()
      .duration(800);

    svg
      .selectAll('.rightLabel')
      .data(this.metadata.data)
      .enter()
      .append('text')
      .attr('x', (d) => margin.left + barWidth + 10 + x(d.right) / 2)
      .attr('y', (d) => y(d.key)! + y.bandwidth() / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .style('fill', '#ffff')
      .style('font-size', '14px')
      .text((d) => formatValue(d.right));

    svg
      .selectAll('.leftBar')
      .data(this.metadata.data)
      .enter()
      .append('rect')
      .attr('x', (d) => margin.left + barWidth - x(d.left))
      .attr('y', (d) => y(d.key)!)
      .attr('width', (d) => x(d.left))
      .attr('height', y.bandwidth() - 10)
      .attr('fill', `rgb(${this.metadata.colorLeft})`)
      .on('mouseover', function (event, d) {
        tooltip.style('opacity', 1);
      })
      .on('mousemove', function (event, d) {
        tooltip
          .html(formatValue(d.left))
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 28 + 'px');
      })
      .on('mouseout', function () {
        tooltip.style('opacity', 0);
      })
      .attr('rx', 15)
      .attr('ry', 15)
      .transition()
      .duration(800);

    svg
      .selectAll('.leftLabel')
      .data(this.metadata.data)
      .enter()
      .append('text')
      .attr('x', (d) => margin.left + barWidth - x(d.left) / 2)
      .attr('y', (d) => y(d.key)! + y.bandwidth() / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .style('fill', '#ffff')
      .style('font-size', '14px')
      .text((d) => formatValue(d.left));

    svg
      .append('text')
      .text(this.metadata.labelRight)
      .attr('text-anchor', 'start')
      .attr('y', height + margin.bottom - 15)
      .attr('x', margin.left + barWidth + 10)
      .style('font-size', 15)
      .style('font-weight', 'bold');

    svg
      .append('text')
      .text(this.metadata.labelLeft)
      .attr('text-anchor', 'end')
      .attr('x', margin.left + barWidth)
      .attr('y', height + margin.bottom - 15)
      .style('font-size', 15)
      .style('font-weight', 'bold');
  }
}
