import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, finalize, take } from 'rxjs';
import { AlertService } from 'src/app/core/alerts/alerts.service';
import { ReportsService } from 'src/app/core/reports.service';
import { findReportsByString$ } from 'src/app/core/state/reports/reports.store';
import { Report } from 'src/app/models/reports';
import { RecordsResultModalComponent } from './records-result-modal/records-result-modal.component';

@Component({
  selector: 'giq-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  search: string = '';
  records: Report[] = [];
  subs: Subscription = new Subscription();
  loading: boolean = false;

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private reportService: ReportsService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onSearch(e: any) {
    e.preventDefault();
    if (this.loading) return;
    if (!this.search.trim().length) return;

    this.loading = true;
    this.subs.add(
      this.reportService
        .GetWorkspaceCategories()
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(() => {
          this.subs.add(
            findReportsByString$(this.search)
              .pipe(take(1))
              .pipe(
                finalize(() => {
                  this.loading = false;
                })
              )
              .subscribe((res) => {
                this.records = res.splice(0, 6);

                if (this.records?.length) {
                  this.openResultsModal();
                } else {
                  this.alertService.warning('No records found.');
                }
              })
          );
        })
    );
  }

  openResultsModal() {
    const dialogRef = this.dialog.open(RecordsResultModalComponent, {
      data: {
        searchString: this.search,
        records: this.records,
      },
      position: { top: '4rem' },
    });
    this.search = '';
  }
}
